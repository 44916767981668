import React from "react";
import { submitCommunityFeedback, validateCommunityFeedback, proxyEndpoint } from "../api";
import ReCAPTCHA from "react-google-recaptcha";
import PopUp from "../components/PopUp";
import Refactor from "../components/Refactor";

const axios = require('axios');

class Communityfeedback extends Refactor {
  constructor(props) {
    super(props);
    this.state = {
      formStatus: 'ready',
      notARobot: false,
      initialSubmit: true,
      seen: false,
      feedbackField: "",
      showCaptcha: false
    };
    this.formRef = React.createRef();
    this.thankYouRef = React.createRef();
    this.focusRef = React.createRef();
    this.changeFieldState = this.changeFieldState.bind(this);
    this.togglePop = this.togglePop.bind(this);
    this.handleLink = this.handleLink.bind(this);
  }

  //componentDidMount will seen the user to be on the first element of page (starts there in tabbing order).
  async componentDidMount() {
    if (this.focusRef.current) {
      this.focusRef.current.focus();
    }
  }

  //submitForm gets the data entered into the form, checks that it is valid, and submits the data.
  submitForm() {
    //set initialSubmit to false as we don't want to submit the form until the user wants to.
    //in which case, initialSubmit will be set to true.
    this.setState({initialSubmit: false});
    //check if form is ready to be submitted.
    if (this.state.formStatus === 'ready') {
      //grab data of the form and place in formData.
      const formData = this.getFormData();

      //validate the data of the form.
      const validationResults = validateCommunityFeedback(formData);

      //check is data of the form is valid.
      if (!validationResults.is_valid) {
        //display these validation errors in the form.
        this.setState({ submittedFormData: formData, validationErrors: validationResults.problems });
        return;
      }

      //set formStatus to submitting as we are now submitting the data of the form.
      this.setState({ formStatus: 'submitting' });

      //submit the data of the forms and send user to Thanks page.
      submitCommunityFeedback(formData)
      .then(() => {
        this.setState({ formStatus: 'ready'});
        this.thankYouRef.current.click();
      })
      //catch any errors that occur when attempting to submit the data of the form.
      .catch((e) => {
        console.log("Failed to submit community feedback", e);
        this.setState({ formStatus: 'ready'});
      });
    }
  }

  //getFormData returns the form data as JSON object.
  getFormData() {
    return {
      feedback: this.state.feedbackField,
      notARobot: this.state.notARobot
    }
  }

  //onCaptchaVerify checks recaptcha api that verify was successful and sets notARobot is true.
  onCaptchaVerify(token) {
    axios.post(`${proxyEndpoint}/Captcha`, {responseToken: token})
    .then((res) => {
      if(res.data.success === true) {
        this.setState({notARobot: true});
      }
      else {
        this.setState({notARobot: false});
      }
    })
    .catch((err) => {
       console.log(`REACT ERROR: ${err}`)
    });
  }

  //changeFieldState sets the feedback field in state when the user types in feedback.
  async changeFieldState(content) {
    await this.setState({feedbackField: content, showCaptcha: true});
  }

  //togglePop adjusts whether or not the Pop Up will be seen.
  async togglePop() {
    await this.setState({seen: !this.state.seen});
  }

  //handleLink sets the current values in state to values in localStorage to be accessed by other pages.
  handleLink() {
    localStorage.setItem('isAddSite', false);
    localStorage.setItem('isFeedback', true);
  }

  //render method for setting up the page and displaying the given information.
  //the use of components makes this easier and more readable (PopUp and ReCAPTCHA).
  //automatically redirect user to Thanks page when feedback is submitted.
  /*istanbul ignore next*/
  render() {
    const { showCaptcha } = this.state;
    return (
      <div>
        <div>
          <link rel="preload" href="../outfile.css" as ="style"/>
          <link rel="stylesheet" href="../styles.css" media="print" onLoad={() => "this.media='all'"}/>
        </div>
        <div className="centered-text">
          <br />
          <h2 autoFocus={true} tabIndex="0" ref={this.focusRef} id="focus">Welcome to our ACT Community Feedback Page!</h2>
          <br />
          <h3 tabIndex="0">Have an idea to improve our Accessibility Compliance tool?</h3>
          <br />
          <h3 tabIndex="0">Let us know how!</h3>
        </div>
        <form ref={this.formRef}>
          {/* feedback field for receiving user's feedback. */}
          <div className="centered-item">
            <div className="search-col" id="feedbackCol">
              <label htmlFor='feedback' tabIndex="0">Feedback:</label>
              <textarea
                id='feedback'
                name='feedback'
                rows={5}
                tabIndex="0"
                onChange={(e) => {
                  this.changeFieldState(e.target.value)
                }}>
              </textarea>
            </div>
            {/* PopUp shows the user a pop up when they did not fill out the feedback field. */}
            {
              (this.state.feedbackField === "" && !this.state.initialSubmit)
              ?  <PopUp
                  toggle={this.togglePop}
                  status={this.state.seen}
                  message={"Please enter your feedback."}
                 />
              : null
            }
          </div>
          <div className="buffer" />
          <div className="centered-item">
            {/* ReCAPTCHA makes the user prove that they are not a robot in order to submit feedback. */}
            { showCaptcha && <ReCAPTCHA
            sitekey="6LcQISEcAAAAANuJmTq9v_OuhJb1hXrJbr2ac9gB"
            onChange={this.onCaptchaVerify.bind(this)}
            id="ReCaptcha"
            tabIndex="0"
            /> }
          </div>
          {/* check if captcha has been entered and passed, otherwise, display pop up asking user to complete captcha. */}
          {(this.notARobotCheck(this.state.notARobot,this.state.initialSubmit))}
          {/* here is the Feedback submission button */}
          {(this.submissionButton(this.handleLink,this.submitForm,this.thankYouRef,this.state.formStatus))}
        </form>
        <br />
      </div>
    );
  }
}

export default Communityfeedback;
