import React from "react"
import {Link} from "react-router-dom";
import '../../src/styles.css';
import HeaderView from "./HeaderView"

//Header creates a header on each webpage on the website.
//holds link to Home page of 508 Testing Tool, Sitemap, and Version information.
//the use of components makes this easier and more readable (HeaderView).
class Header extends React.Component {
    render() {
        const media = window.matchMedia( "(max-width: 700px)" );
        // check if on mobile; if not, display site map, version, and tutorial in header; if so, do not display any.
        var headerAdditions = <div className="heading-order">
                                    <div className="footer-item">
                                        {/* link to Sitemap in left side of header. */}
                                        <div className="header-sitemap">
                                                <Link to="/Site Map">
                                                    <p className="heading-items" alt="Site Map link">Site Map</p>
                                                </Link>
                                        </div>
                                        <div>
                                            {/* link to Version information in right side of header. */}
                                            <Link to="/Release Notes">
                                                <p className="heading-items" alt="Version link">Version '1.0.0'</p></Link>
                                            <br></br>
                                            <Link to="/User Tutorial">
                                                <p className="green-header" alt="User Tutorial Page Link">User Tutorial</p></Link>
                                        </div>
                                    </div>
                                </div>
        if (media.matches) {
            headerAdditions = null
        }
        return (
            <header>
                <meta name="viewport" content="width=device-width, initial-scale=1"></meta>
                {/* link to Home page and bread crumb trail in center of header. */}
                <div className="header-title">
                    <Link to="/Home">
                        <h1 className="title-header" alt="Home Page Link" style={{paddingBottom: "20px"}}>iSenpai's Accessibility Compliance Tool</h1>
                    </Link>
                    <HeaderView />
                </div>
                {/* place for displaying info in header when NOT on mobile */}
                {headerAdditions}
            </header>
        );
    }
}

export default Header;
