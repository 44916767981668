import React from "react";
import CommentBox from "../components/CommentBox";
import { submitReasonsFeedback, validateAddsite, proxyEndpoint, starsfetch } from "../api";
import ReCAPTCHA from "react-google-recaptcha";
import "../styles.css";
import "../star-rating.css";
import PopUp from "../components/PopUp";
import ReviewChart from "./ReviewChart";
import Refactor from "./Refactor";

const axios = require('axios');

class ReasonsFeedback extends Refactor {
  constructor(props) {
    super(props);
    this.state = {
      formStatus: 'ready',
      notARobot: false,
      initialSubmit: true,
      feedbackSubmitted: false,
      ratingSubmitted: false,
      ratingField: "",
      stars: 0,
      feedbackField: "",
      emailField: "",
      emailIsValid: false,
      seen: false,
      starsFeedback: [],
      date: "",
      rating: 0,
      feedback: ""
    };
    this.formRef = React.createRef();
    this.thankYouRef = React.createRef();
    this.fieldIsValid = this.fieldIsValid.bind(this);
    this.setFieldState = this.setFieldState.bind(this);
    this.emailValidCheck = this.emailValidCheck.bind(this);
    this.setFieldTrue = this.setFieldTrue.bind(this);
    this.setFieldFalse = this.setFieldFalse.bind(this);
    this.togglePop = this.togglePop.bind(this);
    this.handleLink = this.handleLink.bind(this);
    this.setRating = this.setRating.bind(this);
    this.submissionPopUp = this.submissionPopUp.bind(this);
    this.UserReviewLink = this.UserReviewLink.bind(this);
    this.listStars = this.listStars.bind(this);
    this.numStarValue = this.numStarValue.bind(this);
    this.numRatings = this.numRatings.bind(this);
    this.averageStars = this.averageStars.bind(this);
    this.presentCommentBox = this.presentCommentBox.bind(this);
    this.displayButton = this.displayButton.bind(this);
    this.reviewRef = React.createRef();
  }

  //componentDidMount will automatically run when page loads (page will load this component).
  //will fetch the results of users' reviews for the given website.
  //calls listStars to display information to the user.
  /*istanbul ignore next*/
  async componentDidMount() {
    let val = [];
    val = await starsfetch(this.props.url);
    if (val.length > 0) {
      var result = val.map(hit => hit._source);
      const resultKeys = val.map(hit => (hit._id))
      for (let i = 0; i < result.length; i++) {
        result[i].key = resultKeys[i];
      }
      this.listStars(result);
    }
  }

  //componentDidUpdate will check if the user clicked the "leave a review" button in which case we will send the focus
  //down to the 'Please rate'. Scroll is controlled by leaveReview() in WCAG.
  //this.props.review will be true if leave a review button was clicked.
  //After the focus is shifted to 'Please rate' stopRefocus() will reset this.props.review to false so that the focus
  //will not be stuck on 'Please rate'
  /*istanbul ignore next*/
  componentDidUpdate() {
    if (this.reviewRef.current && this.props.review) {
      //prevent scroll allows WCAG to control the scrolling of the window
      this.reviewRef.current.focus({preventScroll: true});
      //change review to false to prevent continuous refocus
      this.props.stopRefocus();
    }
  }

  //submitForm gets the data entered into the form, checks that it is valid, and submits the data.
  /*istanbul ignore next*/
  submitForm() {
    //set initialSubmit to false as we don't want to submit the form until the user wants to.
    //in which case, initialSubmit will be set to true.
    this.setState({ initialSubmit: false });
    //check if form is ready to be submitted.
    if (this.state.formStatus === 'ready') {
      //check if email is either blank or filled out and valid.
      if ((this.state.emailField === '') || (this.state.emailField !== '' && this.state.emailIsValid)) {
        //grab data of the form and place in formData.
        const formData = this.getFormData();

        //validate the data of the form.
        const validationResults = validateAddsite(formData);

        //check if rating is > 0
        if (formData.rating === 0) {
          return
        }

        //check is data of the form is valid.
        if (!validationResults.is_valid) {
          //display these validation errors in the form.
          this.setState({ submittedFormData: formData, validationErrors: validationResults.problems });
          return;
        }

        //set formStatus to submitting as we are now submitting the data of the form.
        this.setState({ formStatus: 'submitting' });

        //submit the data of the forms and display a Thanks Pop Up.
        submitReasonsFeedback(formData)
          .then(() => {
            this.setState({ formStatus: 'ready'});
            this.thankYouRef.current.click();
          })
          //catch any errors that occur when attempting to submit the data of the form.
          .catch((e) => {
            console.log("Failed to submit feedback", e);
            this.setState({ formStatus: 'ready'});
          });
      }
    }
  }

  //setRating receives a number given by the user and sets the "stars" field in state to that number.
  setRating(number) {
    this.setState({stars: number});
  }

  //togglePop adjusts whether or not the Pop Up will be seen.
  async togglePop() {
    await this.setState({seen: !this.state.seen});
  }

  //getFormData returns the form data as JSON object.
  getFormData() {
    return {
      feedback: this.state.feedbackField,
      rating: this.state.stars,
      email: this.state.emailField,
      url: this.props.url,
      notARobot: this.state.notARobot
    }
  }

  //onCaptchaVerify checks recaptcha api that verify was successful and sets notARobot is true.
  onCaptchaVerify(token) {
    axios.post(`${proxyEndpoint}/Captcha`, {responseToken: token})
    .then((res) => {
      if(res.data.success === true) {
        this.setState({notARobot: true});
        console.log(`Captcha was successful`);
      }
      else {
        this.setState({notARobot: false});
      }
      //console.log(`I'm not a robot: ${this.state.notARobot}`)
    })
    .catch((err) => {
       //console.log(`REACT ERROR: ${err}`)
    });
  }

  //setFieldState sets either field in state when the user types in either the feedback, rating, or their email.
  async setFieldState(field, content) {
    //if feedback field is being updated.
    if(field === "feedback") {
      await this.setState({feedbackField: content});
    }
    //if rating field is being updated.
    if(field === "rating") {
      await this.setState({ratingField: content});
    }
    //if email field is being updated.
    if(field === "email") {
      await this.setState({emailField: content});
    }
  }

  //fieldIsValid checks if the given field's value is valid or not (i.e. not formatted correctly).
  async fieldIsValid(field) {
    let value;
    //if email field value is being checked if valid.
    if(field === 'email') {
      value = this.state.emailField;
    }

    //make API call to proxy that checks against regex if the field value is valid.
    this.validateField(field, value, axios);
  }

  //setFieldTrue sets the validity of the given field value to true (i.e. value is valid).
  setFieldTrue(field) {
    //if email field value is valid.
    if(field === 'email') {
      this.setState({emailIsValid: true});
    }
  }

  //setFieldTrue sets the validity of the given field value to false (i.e. value is not valid).
  setFieldFalse(field) {
    //if email field value is not valid.
    if(field === 'email') {
      this.setState({emailIsValid: false});
    }
  }

  //emailValidCheck checks if the email field has not been left blank (user needs to input a email in order to submit).
  //also checks if form can be submitted and if email field value is valid.
  //if any of these fail, present the user with a Pop Up, asking them to input a email that is in valid format.
  //else, allow user to submit form data.
  emailValidCheck() {
    if (this.state.emailField !== "" && !this.state.initialSubmit && !this.state.emailIsValid) {
      return <PopUp
                toggle={this.togglePop}
                status={this.state.seen}
                message={"Email must be formatted correctly. Ex: user@isenpai.com"}
              />
    }
    else {
      return (null);
    }
  }

  //submissionPopUp checks if user gave their star rating or not.
  //If not, ask user to enter their star rating with a Pop Up. If so, thank user for their feedback with a Pop Up.
  submissionPopUp() {
    if (this.state.notARobot) {
      //if user did not enter their star rating for given website.
      if (this.state.stars === 0 && !this.state.initialSubmit) {
        return <PopUp
                  toggle={this.togglePop}
                  status={this.state.seen}
                  message={"Please give your star rating."}
                />
      }
      //if user did enter their star rating for given website.
      if (this.state.stars !== 0 && !this.state.initialSubmit) {
        return <PopUp
                  toggle={this.togglePop}
                  status={this.state.seen}
                  message={"Thank you for your feedback."}
                />
      }
    }
    return null;
  }

  //handleLink sets the current values in state to values in localStorage to be accessed by other pages.
  handleLink() {
    localStorage.setItem('isAddSite', true);
    localStorage.setItem('isFeedback', false);
  }

  //averageStars finds the star ratings given by all users for the given website and returns the average.
  averageStars() {
    const ratingArr = this.state.starsFeedback;
    let sum=0;
    if (ratingArr.length >=1) {
      for (const star of ratingArr) {
        sum += star.rating;
      }
    }
    return sum/ratingArr.length;
  }

  //numRatings finds the star ratings given by all users for the given website and returns the total number of star ratings.
  numRatings() {
    const ratingArr = this.state.starsFeedback;
    return ratingArr.length;
  }

  //numStarValue finds the star ratings given by all users for the given website and
  //returns the total number of star ratings that match the given star rating.
  numStarValue(value) {
    const ratingArr = this.state.starsFeedback;
    let sum = 0;
    if (ratingArr.length >= 1) {
      for (const star of ratingArr) {
        if (star.rating === value) {
          sum ++;
        }
      }
    }
    return sum;
  }

  //listStars sets the needed fields for user review in state.
  listStars(result) {
    const review = result[result.length - 1];
    const date = review.date_of_submission;
    const rating = review.rating;
    const feedback = review.feedback;

    this.setState({
      starsFeedback: result,
      date: date,
      rating: rating,
      feedback: feedback
    })
  }

  //UserReviewLink sets the user's star rating review in localStorage.
  UserReviewLink() {
    localStorage.setItem('user_reviews', JSON.stringify(this.state.starsFeedback));
  }

  //presentCommentBox checks if there are star ratings for given website and displays them if so.
  presentCommentBox() {
    //check if there are star ratings for given website.
    if (this.state.starsFeedback.length !== 0) {
      return <CommentBox
                feedback={this.state.feedback}
                rating={this.state.rating}
                date={this.state.date}
              />
              }
    //then display nothing.
    return null;
  }

  //render method for setting up the page and displaying the given information.
  //the use of components makes this easier and more readable (ReviewChart and ReCAPTCHA).
  //show Pop Up when form is submitted.
  render() {
    //find percentage for each star rating.
    /*istanbul ignore next*/
    const x = this.props.location && this.props.location.state;
    const fiveStarReviewPercent = this.numStarValue(5) / this.numRatings() * 100;
    const fourStarReviewPercent = this.numStarValue(4) / this.numRatings() * 100;
    const threeStarReviewPercent = this.numStarValue(3) / this.numRatings() * 100;
    const twoStarReviewPercent = this.numStarValue(2) / this.numRatings() * 100;
    const oneStarReviewPercent = this.numStarValue(1) / this.numRatings() * 100;
    const company = localStorage.getItem('company');
    //either show most recent comment or that there are no user reviews.
    const mostRecent = this.numRatings() !== 0 ? `Most recent comment for ${company}:` : `Currently no user reviews for ${company}`;

    /* istanbul ignore next*/
    return (
      <div className= "main-div">
        <div className="centered-text">
          <br />
          <h2>What do you think about {this.props.company} website?</h2>
        </div>
        <div className="flex-row" style={{flexWrap: "wrap"}}>
        <div className="footer-item" >
        <form ref={this.formRef}>
          <div className="search-row">
            {/* have user choose a star rating to give website. */}
            <div className="search-col" style={{ alignItems: "center"}}>
                <fieldset className="rating"><legend ref={this.reviewRef} tabIndex="-1">Please rate:</legend>
                  <span> <input id="star5" name="rating" type="radio" value="5" onClick ={(e) => this.setRating(5)} />
                  <label htmlFor="star5" title="Amazing">5 stars</label>
                  <span> <input id="star4" name="rating" type="radio" value="4" onClick ={(e) => this.setRating(4)}/>
                  <label htmlFor="star4" title="Good">4 stars</label>
                  <span> <input id="star3" name="rating" type="radio" value="3" onClick ={(e) => this.setRating(3)}/>
                  <label htmlFor="star3" title="Okay">3 stars</label>
                  <span> <input id="star2" name="rating" type="radio" value="2" onClick ={(e) => this.setRating(2)}/>
                  <label htmlFor="star2" title="Bad">2 stars</label>
                  <span> <input id="star1" name="rating" type="radio" value="1" onClick ={(e) => this.setRating(1)}/>
                  <label htmlFor="star1" title="Terrible">1 star</label>
                  </span> </span> </span> </span> </span></fieldset>
            </div>
            {/* email field if user would like to receive updates on our scanning of their entered url. */}
            <div className="search-col">
              <label htmlFor='email'>Email Address - Optional:</label>
              <input
              id='email'
              name='email'
              type="text"
              placeholder='Example: ACTool@isenpai.com'
              size={54}
              value={(x) ? this.props.location.state.email : undefined}
              submittedvalue={this.getSubmittedFormData("email",this.state.submittedFormData)}
              onChange={async (e) => {
                await this.setFieldState('email', e.target.value);
                this.fieldIsValid('email');
              }}
              />
            </div>
            {/* comments field if user would like to leave a comment along with their star rating for given website. */}
            <div className="search-col" id="feedbackCol">
              <label htmlFor='feedback'>Comments - Optional:</label>
              <input
                autoFocus={false}
                id='feedback'
                name='feedback'
                type="text"
                rows={5}
                value={(x) ? this.props.location.state.feedback : undefined}
                submittedvalue={this.getSubmittedFormData("feedback",this.state.submittedFormData)}
                onChange={async (e) => {
                  await this.setFieldState('feedback', e.target.value);
                }}
              />
            </div>
            {this.emailValidCheck()}
            {this.submissionPopUp()}
          </div>
          <br />
          {/* ReCAPTCHA makes the user prove that they are not a robot in order to submit feedback. */}
          <div className="centered-item">
            <ReCAPTCHA
              sitekey={'6LcQISEcAAAAANuJmTq9v_OuhJb1hXrJbr2ac9gB'}
              onChange={this.onCaptchaVerify.bind(this)}
            />
          </div>
          {(this.notARobotCheck(this.state.notARobot,this.state.initialSubmit))}
          <div className="centered-item">
            {this.displayButton()}
          </div>
        </form>
        </div>
        {/* call ReviewChart to display the current star ratings results to the page. */}
        <ReviewChart
          numRatings={this.numRatings}
          averageStars={this.averageStars}
          fiveStarReviewPercent={fiveStarReviewPercent}
          fourStarReviewPercent={fourStarReviewPercent}
          threeStarReviewPercent={threeStarReviewPercent}
          twoStarReviewPercent={twoStarReviewPercent}
          oneStarReviewPercent={oneStarReviewPercent}
          numStarValue={this.numStarValue}
          mostRecent={mostRecent}
          presentCommentBox={this.presentCommentBox}
          UserReviewLink={this.UserReviewLink}
          company={company}
        />
        </div>
        <div className="buffer-div"></div>
        <div className="buffer-div"></div>
      </div>
    );
  }
}

export default ReasonsFeedback;
