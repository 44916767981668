import React from "react";
import { submitAddsite, validateAddsite, proxyEndpoint } from "../api";
import ReCAPTCHA from "react-google-recaptcha";
import PopUp from "../components/PopUp";
import Refactor from "../components/Refactor";

const axios = require('axios');

class Addsite extends Refactor {
  constructor(props) {
    super(props);
    this.state = {
      formStatus: 'ready',
      notARobot: false,
      initialSubmit: true,
      urlSubmitted: false,
      urlField: "",
      emailField: "",
      emailIsValid: false,
      urlIsValid: false,
      seen: false,
      showCaptcha: false
    };
    this.formRef = React.createRef();
    this.thankYouRef = React.createRef();
    this.focusRef = React.createRef();
    this.fieldIsValid = this.fieldIsValid.bind(this);
    this.setFieldState = this.setFieldState.bind(this);
    this.urlValidCheck = this.urlValidCheck.bind(this);
    this.emailValidCheck = this.emailValidCheck.bind(this);
    this.setFieldTrue = this.setFieldTrue.bind(this);
    this.setFieldFalse = this.setFieldFalse.bind(this);
    this.togglePop = this.togglePop.bind(this);
    this.handleLink = this.handleLink.bind(this);
    this.checkFieldsEmpty = this.checkFieldsEmpty.bind(this);
    this.displayButton = this.displayButton.bind(this);
  }

  //componentDidMount will seen the user to be on the first element of page (starts there in tabbing order).
  async componentDidMount() {
    if (this.focusRef.current) {
      this.focusRef.current.focus();
    }
  }

  //submitForm gets the data entered into the form, checks that it is valid, and submits the data.
  submitForm() {
    //set initialSubmit to false as we don't want to submit the form until the user wants to.
    //in which case, initialSubmit will be set to true.
    this.setState({ initialSubmit: false });
    //check if form is ready to be submitted.
    if (this.state.formStatus === 'ready' && this.state.urlIsValid) {
      //check if email is either blank or filled out and valid.
      if ((this.state.emailField === '') || (this.state.emailField !== '' && this.state.emailIsValid)) {
        //grab data of the form and place in formData.
        const formData = this.getFormData();

        //validate the data of the form.
        const validationResults = validateAddsite(formData);

        //check is data of the form is valid.
        /*istanbul ignore next*/
        if (!validationResults.is_valid) {
          //display these validation errors in the form.
          this.setState({ submittedFormData: formData, validationErrors: validationResults.problems });
          return;
        }

        //set formStatus to submitting as we are now submitting the data of the form.
        this.setState({ formStatus: 'submitting' });

        //submit the data of the forms and send user to Thanks page.
        submitAddsite(formData)
          .then(() => {
            this.setState({ formStatus: 'ready'});
            this.thankYouRef.current.click();
          })
          //catch any errors that occur when attempting to submit the data of the form.
          .catch((e) => {
            console.log("Failed to submit community feedback", e);
            this.setState({ formStatus: 'ready'});
          });
      }
    }
  }

  //getFormData returns the form data as JSON object.
  getFormData() {
    return {
      search: this.state.urlField,
      email: this.state.emailField || null,
      notARobot: this.state.notARobot
    }
  }

  //onCaptchaVerify checks recaptcha api that verify was successful and sets notARobot is true.
  onCaptchaVerify(token) {
    axios.post(`${proxyEndpoint}/Captcha`, {responseToken: token})
    .then((res) => {
      if(res.data.success === true) {
        this.setState({notARobot: true});
      }
      else {
        this.setState({notARobot: false});
      }
    })
    .catch((err) => {
       console.log(`REACT ERROR: ${err}`)
    });
  }

  //setFieldState sets either field in state when the user types in either the url or their email.
  async setFieldState(field, content) {
    //if url field is being updated.
    if(field === "search") {
      await this.setState({urlField: content});
    }
    //if email field is being updated.
    if(field === "email") {
      await this.setState({emailField: content});
    }
    await this.setState({showCaptcha: true});
  }

  //fieldIsValid checks if the given field's value is valid or not (i.e. not formatted correctly).
  async fieldIsValid(field) {
    let value;
    //if url field value is being checked if valid.
    if(field === 'search') {
      value = this.state.urlField;
    }
    //if email field value is being checked if valid.
    if(field === 'email') {
      value = this.state.emailField;
    }

    //make API call to proxy that checks against regex if the field value is valid.
    this.validateField(field, value, axios);
  }

  //setFieldTrue sets the validity of the given field value to true (i.e. value is valid).
  setFieldTrue(field) {
    //if url field value is valid.
    if(field === 'search') {
      this.setState({urlIsValid: true});
    }
    //if email field value is valid.
    if(field === 'email') {
      this.setState({emailIsValid: true});
    }
  }

  //setFieldTrue sets the validity of the given field value to false (i.e. value is not valid).
  setFieldFalse(field) {
    //if url field value is not valid.
    if(field === 'search') {
      this.setState({urlIsValid: false});
    }
    //if email field value is not valid.
    if(field === 'email') {
      this.setState({emailIsValid: false});
    }
  }

  //checkFieldEmpty checks if the url field has been left blank (user needs to input a url in order to submit).
  //also check if form be submitted.
  //if url field has been left blank, present user with Pop Up, asking them to input a url.
  //else, allow user to submit form data.
  checkFieldsEmpty() {
    if (this.state.urlField === "" && !this.state.initialSubmit) {
      return <PopUp
                toggle={this.togglePop}
                status={this.state.seen}
                message={"Please enter URL of website."}
              />
    }
    return null;
  }

  //urlValidCheck checks if the url field has not been left blank (user needs to input a url in order to submit).
  //also checks if form can be submitted and if url field value is valid.
  //if any of these fail, present the user with a Pop Up, asking them to input a url that is in valid format.
  //else, allow user to submit form data.
  urlValidCheck() {
    if (this.state.urlField !== "" && !this.state.initialSubmit && !this.state.urlIsValid) {
      return <PopUp
                toggle={this.togglePop}
                status={this.state.seen}
                message={"URL must be formatted correctly. Ex: http://isenpai.com or https://isenpai.com or www.isenpai.com"}
              />
    }
    return (null);
  }

  //emailValidCheck checks if the email field has not been left blank (user needs to input a email in order to submit).
  //also checks if form can be submitted and if email field value is valid.
  //if any of these fail, present the user with a Pop Up, asking them to input a email that is in valid format.
  //else, allow user to submit form data.
  emailValidCheck() {
    if (this.state.emailField !== "" && !this.state.initialSubmit && !this.state.emailIsValid) {
      return <PopUp
                toggle={this.togglePop}
                status={this.state.seen}
                message={"Email must be formatted correctly. Ex: user@isenpai.com"}
              />
    }
    return (null);
  }

  //togglePop adjusts whether or not the Pop Up will be seen.
  async togglePop() {
    await this.setState({seen: !this.state.seen});
  }

  //handleLink sets the current values in state to values in localStorage to be accessed by other pages.
  handleLink() {
    localStorage.setItem('isAddSite', true);
    localStorage.setItem('isFeedback', false);
  }

  //render method for setting up the page and displaying the given information.
  //the use of components makes this easier and more readable (PopUp and ReCAPTCHA).
  //automatically redirect user to Thanks page when feedback is submitted.
  render() {
    //logic to determine whether to accept user input or forward data from incoming props.
     /* istanbul ignore next */
    const x = this.props.location && this.props.location.state;
    /* istanbul ignore next */
    const { showCaptcha } = this.state;
    return (
      <div>
        <div>
          <link rel="preload" href="../outfile.css" as ="style"/>
          <link rel="stylesheet" href="../styles.css" media="print" onLoad={() => "this.media='all'"}/>
        </div>
        <div className="centered-text">
          <br />
          <h2 autoFocus={true} tabIndex="0" ref={this.focusRef}>Welcome to our ACT Submit a New Website Page!</h2>
          <br />
          <h3>Couldn't find the company or website you were looking for?</h3>
          <br />
          <h3>Let us know which one!</h3>
        </div>
        <form ref={this.formRef}>
          {/* url field for receiving url that user wishes us to scan. */}
          <div className="centered-item">
            <div className="search-col">
              <label htmlFor='company'>URL:</label>
              <input
                id='search'
                name='search'
                type="text"
                placeholder='Search, example: "www.iSenpai.com"'
                size={50}
                value={(x) ? this.props.location.state.url : undefined}
                submittedvalue={this.getSubmittedFormData("search",this.state.submittedFormData)}
                onChange={async (e) => {
                  await this.setFieldState('search', e.target.value);
                  this.fieldIsValid('search');
                }}
              />
              {/* check if given url is valid, otherwise, display pop up asking user to input valid url. */}
              <div className="centered-item">
                <p className="error-message red">{this.urlValidCheck()}</p>
              </div>
            </div>
            {/* email field if user would like to receive updates on our scanning of their entered url. */}
            <div className="search-col">
              <label htmlFor='email'>Email Address - Optional:</label>
              <input
              id='email'
              name='email'
              type="text"
              placeholder='Example: ACTool@isenpai.com'
              size={50}
              value={(x) ? this.props.location.state.email : undefined}
              submittedvalue={this.getSubmittedFormData("email",this.state.submittedFormData)}
              onChange={async (e) => {
                await this.setFieldState('email', e.target.value);
                this.fieldIsValid('email');
              }}
              />
              {/* check if given email is valid, otherwise, display pop up asking user to input valid email. */}
              {this.emailValidCheck()}
            </div>
            {/* check if fields are empty, otherwise, display pop up asking user to enter given fields. */}
            {this.checkFieldsEmpty()}
          </div>
          <br />
          {/* ReCAPTCHA makes the user prove that they are not a robot in order to submit feedback. */}
          <div className="centered-item">
            { showCaptcha && <ReCAPTCHA
              sitekey={'6LcQISEcAAAAANuJmTq9v_OuhJb1hXrJbr2ac9gB'}
              onChange={this.onCaptchaVerify.bind(this)}
            /> }
          </div>
          {/* check if captcha has been entered and passed, otherwise, display pop up asking user to complete captcha. */}
          {(this.notARobotCheck(this.state.notARobot,this.state.initialSubmit))}
          {/* here is the New Website submission button */}
          {(this.submissionButton(this.handleLink,this.submitForm,this.thankYouRef,this.state.formStatus))}
        </form>
        <br />
      </div>
    );
  }
}
export default Addsite;
