import React from "react";
import "../styles.css";

class TutorialBasic extends React.Component{
  constructor(props) {
    super(props);
    this.state = {};
    this.focusRef = React.createRef();
  }

  //componentDidMount will place the focus of the user (for screen reader purposes) on the first element on the page.
  async componentDidMount() {
    if (this.focusRef.current) {
      this.focusRef.current.focus();
    }
  }

  //render the following content on the page (buttons, videos, and text guides).
  //render will show the tutorial video for the Basic Search along with helpful text describing that page's elements.
  render() {
      return (
        <div className="flex-width-paragraph" style={{paddingTop:"20px"}}>
            <h2 className="centered-VersionText" autoFocus={true} ref={this.focusRef} tabIndex="0" id="focus">Basic Search Tutorial Page</h2>
            <h3 className="bold-text-video" tabIndex="0">Basic Search Video Tutorial</h3>
            <video controls
              src="https://isenpai-act-portal-tutorials.s3.amazonaws.com/basic.mp4"
              width="765"
              height="515">
              Sorry, your browser doesn't support embedded videos.
            </video>
            <p className="bold-text">Basic Search Text Tutorial</p>
            <ul className="list-text">
              <li>To get to the basic search field, the user must go to the 508 portal homepage.</li>
              <li>The user can then enter in 3 types of searches:</li>
                <ul>
                  <li>Entering nothing at all and clicking the search button returns a default table of results</li>
                  <li>Entering a generic term like food returns results that relate to the word food</li>
                  <li>Entering in a specific term such as &pizza results in the specific term as the top entry in the table</li>
                </ul>
            </ul>
        </div>
      )
  }
}

export default TutorialBasic;
