import React from "react"
import {Link} from "react-router-dom";
import '../styles.css';

class Sitemap extends React.Component {
    constructor(props) {
        super(props);
        this.focusRef = React.createRef();
    }

    //componentDidMount will automatically run when page loads.
    componentDidMount() {
        //make sure page focuses on the correct element as soon as the page fully loads.
        if (this.focusRef.current) {
            this.focusRef.current.focus();
        }
    }

    //render method for setting up the page and displaying the given information.
    //links the user to every page on the website (one level down) all from one page.
    //this is very useful for screen-readers and the blind community.
    render() {
        //auto focus the user to specific place in page.
        const shouldAutoFocus = window.innerWidth<600?false:true;

        return (
            <nav>
                <ul className = "nav-list">
                    <li className="sitemap-li"><Link className="link" to="/Home" alt="Home Page Link" autoFocus={shouldAutoFocus} ref={this.focusRef}><h1>Home</h1></Link></li>
                    <li className="sitemap-li"><Link className="link" to="/User Tutorial" alt="User Tutorial Page Link"><h1>User Tutorial</h1></Link></li>
                    <li className="sitemap-li"><Link className="link" to="/Advanced Search" alt="Advanced Search Link"><h1>Advanced Search</h1></Link></li>
                    <li className="sitemap-li"><Link className="link" to="/Submit Feedback" alt="Submit Feedback Link"><h1>Submit Feedback</h1></Link></li>
                    <li className="sitemap-li"><Link className="link" to="/Submit New Website" alt="Submit New Website Link"><h1>Submit New Website</h1></Link></li>
                    <li className="sitemap-li"><Link className="link" to="/Full Table of Results" alt="Access Full Table of Results Link"><h1>
                        Access Full Table of Results</h1></Link></li>
                    <li className="sitemap-li"><Link className="link" to="/About Web Accessibility" alt="Learn About Web Accessibility Link"><h1>
                        Learn About Web Accessibility</h1></Link></li>
                    <li className="sitemap-li"><Link className="link" to="/Browse Our References" alt="Browse Our References Link"><h1>Browse Our References</h1></Link></li>
                    <li className="sitemap-li"><Link className="link" to="/Check Available Resources" alt="Check Available Resources Link"><h1>
                        Check Available Resources</h1></Link></li>
                </ul>
            </nav>
        );
    }
}

export default Sitemap;
