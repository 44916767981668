import React from "react";
import { Link } from "react-router-dom";
import "../../src/styles.css";
import "../../node_modules/select2/dist/js/select2.js";
import "../../src/select2.css"
import $ from "jquery";
import categories from "../categories";

class Advancedsearch extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: '',
      min_grade: 'F',
      date: 'Any',
      sort_by: 'relevance',
      zip: "",
      coordinates: ""
    };
    this.handleLink = this.handleLink.bind(this);
    this.toggleLocation = this.toggleLocation.bind(this);
    this.geoSuccess = this.geoSuccess.bind(this);
  }

  //componentDidMount will automatically run when page loads.
  //sets up category information for jquery's select2.
  componentDidMount() {
    const options = '.js-example-basic-single';
    localStorage.setItem('category', "");
    $(document).ready(function() {
      $(options).select2({
        placeholder: 'Select a Category Filter (optional)',
        data: categories,
        width: "100%",
        allowClear: true
      });
      /*istanbul ignore next*/
      $(options).on('select2:select', function(e) {
        var data = e.params.data.text;
        localStorage.setItem('category', data);
      });
      /*istanbul ignore next*/
      $(options).on('select2:unselect', function(e) {
        localStorage.setItem('category', "");
      });
    });
  }

  //changeHandler changes state of search "name" when user inputs a basic search term.
  changeHandler = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  }

  //gradeHandler changes state of search "min_grade" when user changes the minimum grade filter.
  gradeHandler = (event) => {
    this.setState({ min_grade: event.target.value });
  }

  //dateHandler changes state of search "date" when user changes the last updated filter.
  dateHandler = (event) => {
    this.setState({ date: event.target.value });
  }

  //sortHandler changes state of search "sort_by" when user changes how they want the results to be sorted by first.
  sortHandler = (event) => {
    this.setState({ sort_by: event.target.value });
  }

  /* istanbul ignore next*/
  //handleEnterKey click submitButton when the user hits the "enter" key, sending the user to the results page.
  handleEnterKey = (submitButton, e) => {
    e.preventDefault();
    submitButton.click()
  }

  //toggleLocation uses gps location systems to get the current location of the user.
  //this is user when user wants to see results found at their current location - clicks "Use Current Location" button.
  toggleLocation() {
    navigator.geolocation.getCurrentPosition(this.geoSuccess,console.log);
  }

  //geoSuccess receives position of user and translates that into coordinates of latitude and longitude.
  geoSuccess(position) {
    const startPos = position;
    const coordinates = `${startPos.coords.latitude},${startPos.coords.longitude}`
    this.setState({
      coordinates: coordinates
    });
  }

  //handleLink sets the current values in state to values in localStorage to be accessed by other pages.
  handleLink() {
    localStorage.setItem('isAdvancedSearch', true);
    localStorage.setItem('isFullTable', false);
    localStorage.setItem('isTutorialPage', false);
    localStorage.setItem('searchTerm', this.state.name);
    localStorage.setItem('minGrade', this.state.min_grade);
    localStorage.setItem('lastUpdated', this.state.date);
    localStorage.setItem('sortBy', this.state.sort_by);
    localStorage.setItem('zip', this.state.zip);
    localStorage.setItem('coordinates', this.state.coordinates);
  }

  //render method for setting up the page and displaying the given information.
  //displays a search bar so the user can make a search using a single search term.
  //also provides the user with multiple filters to enhance the search results.
  //sends user to Results page when user wishs to see the results of their search.
  /*istanbul ignore next*/
  render() {
    return (
      <div>
        <div className="centered-text">
          <h1>ACT Advanced Search</h1>
        </div>
        {/*Pass data to Results.js to be transformed and sent to the API*/}
        <form onSubmit={(e) => { this.handleEnterKey(document.querySelector('#submitButton'), e) }}>
          <div className="limited-flex-row" style={{ justifyContent:"space-evenly" }}>
            <div className="search-row-3">
              <div className="search-col">
                {/* search bar for single search term. */}
                <label htmlFor="name">Search Term:</label>
                <span alt="Search Term"><input
                  alt="Search Term"
                  id="name"
                  name="name"
                  size={50}
                  className="search-item left"
                  type="text"
                  onChange={this.changeHandler}
                  placeholder='Search, example: "Pizza Hut" or "Domino&apos;s"'
                /></span>
                {/* category filter (select2) for displaying only results of that category. */}
                <label htmlFor="js-example-basic-single">Category Filter (optional): </label>
                <select className="js-example-basic-single" value={this.state.category} onChange={this.catHandler}
                        alt="Category Filter Select optional" aria-labelledby = "Optional Category Filter Select">
                  <option></option>
                    ...
                </select>
                {/* zip code bar for only showing results found in that zip code. */}
                <label htmlFor="zipcode" id="float-left">Zip Code (optional):</label>
                <div className="flexed-items" alt="Zip Code optional">
                  <span className="zip-width" alt="Enter Zip Code"><input
                    alt="Enter Zip Code"
                    id="zip"
                    name="zip"
                    size={10}
                    className="search-item left"
                    type="text"
                    onChange={this.changeHandler}
                    placeholder='Zip Code (optional)'
                  /></span>
                  {/* use current location button for only showing results found in the user's current location. */}
                  <span><p className = "or-width">OR</p></span>
                  <span>
                    <button
                    id = "toggle-button"
                    onClick = {this.toggleLocation}
                    type="button"
                    alt="Toggle Allow Location Data"
                    >Use Current Location</button>
                  </span>
                </div>
              </div>
            </div>
            <div className="search-row">
              <div className="search-col">
                {/* minimum grade dropdown for showing results with grades equal to or greater than the selected grade. */}
                <label htmlFor="grade">Minimum WCAG Grade: </label>
                <select name="min_grade" id="grade" className="left" value={this.state.min_grade} onChange={this.gradeHandler} alt="Minimum Grade Dropdown">
                  <option value="A">A</option>
                  <option value="B">B</option>
                  <option value="C">C</option>
                  <option value="D">D</option>
                  <option value="F">F</option>
                </select>
                {/* date of last update dropdown for showing results that have been tested
                    on or more recently than the selected date.
                */}
                <label htmlFor="date">Date of Last Update: </label>
                <select name="date" id="date" className="left" value={this.state.date} onChange={this.dateHandler} alt="Date Last Tested Dropdown">
                  <option value="Any">Any</option>
                  <option value={7}>Up to 7 days</option>
                  <option value={30}>Up to 30 days</option>
                  <option value={60}>Up to 60 days</option>
                  <option value={90}>Up to 90 days</option>
                  <option value={180}>Up to 180 days</option>
                </select>
                {/* sort by dropdown for showing results sorted by the selected field when results first load. */}
                <label htmlFor="sort_by">Sort By: </label>
                <select name="sort_by" id="sort_by" className="left" value={this.state.sort_by} onChange={this.sortHandler} alt="Sort By Dropdown">
                  <option value="relevance">Relevance</option>
                  <option value="website">Website</option>
                  <option value="category">Category</option>
                  <option value="url">URL</option>
                  <option value="accessibility grade">Accessibility Grade</option>
                  <option value="pwa grade">PWA Grade</option>
                  <option value="date tested">Date Tested</option>
                </select>
              </div>
            </div>
          </div>
          {/* send user to Results page after user chooses to search and see results. */}
          <div className="centered-item">
            <Link onClick={() => this.handleLink()}
              id="submitButton" to={{
              pathname: "/Results",
              prevpage: "Advanced Search",
              state: {
                searchTerm: this.state.name,
                category: this.state.category,
                minGrade: this.state.min_grade,
                lastUpdated: this.state.date,
                sortBy: this.state.sort_by,
                /*Insert Zip Code Info or Location Data*/
                isAdvancedSearch: true
              }
            }}>
              <button type="submit" alt="Search">
                Search
              </button>
            </Link>
          </div>
          <br />
        </form>
        <br />
      </div>
    );
  }
}

export default Advancedsearch;
