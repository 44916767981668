import React from "react";
import "../styles.css";

class TutorialWebsite extends React.Component{
  constructor(props) {
    super(props);
    this.state = {};
    this.focusRef = React.createRef();
  }

  //componentDidMount will place the focus of the user (for screen reader purposes) on the first element on the page.
  async componentDidMount() {
    if (this.focusRef.current) {
      this.focusRef.current.focus();
    }
  }

  //render the following content on the page (buttons, videos, and text guides).
  //render will show the tutorial video for the Submit New Website along with helpful text describing that page's elements.
  render() {
      return (
        <div className="flex-width-paragraph" style={{paddingTop:"20px"}}>
            <h2 className="centered-VersionText" autoFocus={true} ref={this.focusRef} tabIndex="0" id="focus">Submit New Website Tutorial Page</h2>
            <h3 className="bold-text-video" tabIndex="0">Submit New Website Video Tutorial</h3>
            <video controls
              src="https://isenpai-act-portal-tutorials.s3.amazonaws.com/website.mp4"
              width="765"
              height="515">
              Sorry, your browser doesn't support embedded videos.
            </video>
            <p className="bold-text">Submit New Website Text Tutorial</p>
            <ul className="list-text">
              <li>To get to the submit new website page, the user must be on the 508 portal homepage.</li>
              <li>Then, under the basic and advanced search elements, clicking on the appropriate image redirects them.</li>
              <li>There are 3 fields to fill out on the page:</li>
                <ul>
                  <li>Website URL (with a https://, http://, or www. prefix)</li>
                  <li>Email address (optional)</li>
                  <li>Captcha (used to verify that the submission is not made by a bot)</li>
                </ul>
              <li>A pop-up will appear if the user uses incorrect URL formatting.</li>
              <li>After submitting, the user gets redirected to a thank you web page.</li>
            </ul>
        </div>
      )
  }
}

export default TutorialWebsite;
