import React from "react";
import "../styles.css";
import Refactor from "./Refactor";

class FullTableResult extends Refactor {
  constructor(props) {
    super(props);
    this.handleLink = this.handleLink.bind(this);
    this.printCategory = this.printCategory.bind(this);
    this.setRowStorage = this.setRowStorage.bind(this);
    this.tableHeading = this.tableHeading.bind(this);
  }

  //handleLink sets the current values in state to values in localStorage to be accessed by other pages.
  /*istanbul ignore next*/
  handleLink(row) {
    localStorage.setItem('isAdvancedSearch', false);
    localStorage.setItem('isFullTable', true);
    localStorage.setItem('isTutorialPage', false);
    this.setRowStorage(row);
  }

  //render method for setting up the page and displaying the given information.
  //displays a table of all results in our database.
  /*istanbul ignore next*/
  render() {
    return (
      <table>
        {/* set up column headers for each field. */}
        {(this.tableHeading())}
        {/* set up body entries for each field. */}
        <tbody>
          {this.props.resultItem.map((row, index) => (
            this.tableData("Full Table of Results", row, index)
          ))}
        </tbody>
      </table>
    );
  }
}

export default FullTableResult;
