import React from "react";
import "../styles.css";

class TutorialPWA extends React.Component{
  constructor(props) {
    super(props);
    this.state = {};
    this.focusRef = React.createRef();
  }

  //componentDidMount will place the focus of the user (for screen reader purposes) on the first element on the page.
  async componentDidMount() {
    if (this.focusRef.current) {
      this.focusRef.current.focus();
    }
  }

  //render the following content on the page (buttons, videos, and text guides).
  //render will show the tutorial video for the PWA Reasons along with helpful text describing that page's elements.
  render() {
      return (
        <div className="flex-width-paragraph" style={{paddingTop:"20px"}}>
            <h2 className="centered-VersionText" autoFocus={true} ref={this.focusRef} tabIndex="0" id="focus">PWA Reasons Tutorial Page</h2>
            <h3 className="bold-text-video" tabIndex="0">PWA Reasons Video Tutorial</h3>
            <video controls
              src="https://isenpai-act-portal-tutorials.s3.amazonaws.com/PWA.mp4"
              width="765"
              height="515">
              Sorry, your browser doesn't support embedded videos.
            </video>
            <p className="bold-text">PWA Reasons Text Tutorial</p>
            <ul className="list-text">
              <li>PWA stands for Progressive Web App</li>
              <li>The user must navigate to a results page which can be done by clicking the search buttong on the homepage.</li>
              <li>After being redirected, there will be information listed about that specific website on a new page:</li>
                <ul>
                  <li>Date of last test</li>
                  <li>PWA grade that the website received</li>
                  <li>A table displaying the elements missing that deduct points</li>
                </ul>
            </ul>
        </div>
      )
  }
}

export default TutorialPWA;
