import React from "react";
import { Link } from "react-router-dom";
import "../styles.css";
class Home extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            name: "",
            keywords: "",
            min_grade: "",
            date: "",
        };
        this.focusRef = React.createRef();
        this.handleLink = this.handleLink.bind(this);
    }

    //componentDidMount will seen the user to be on the first element of page (starts there in tabbing order).
    async componentDidMount() {
        if (this.focusRef.current) {
            this.focusRef.current.focus();
        }
    }

    //changeHandler changes state of search "name" when user inputs a basic search term.
    changeHandler = (event) => {
        this.setState({ [event.target.name]: event.target.value });
    }

    //handleEnterKey click submitButton when the user hits the "enter" key, sending the user to the results page.
    //to be tested with selinium.
    /* istanbul ignore next */
    handleEnterKey = (submitButton, e) => {
        e.preventDefault();
        submitButton.click()
    }

    //handleLink sets the current values in state to values in localStorage to be accessed by other pages.
    handleLink() {
        localStorage.setItem('isAdvancedSearch', false);
        localStorage.setItem('isFullTable', false);
        localStorage.setItem('isTutorialPage', false);
        localStorage.setItem('searchTerm', this.state.name);
        localStorage.setItem('keywords', this.state.keywords);
        localStorage.setItem('minGrade', this.state.min_grade);
        localStorage.setItem('lastUpdated', this.state.date);
    }

    //render method for setting up the page and displaying the given information.
    //displays a search bar so the user can make a search based on a single search term.
    //sends user to Results page when user wishs to see the results of their search.
    //displays links to every page in the website (Home page meant to be main station for linking other pages).
    render() {
        //based on screen width (i.e. mobile vs desktop), determine when to autofocus.
        const shouldAutoFocus = window.innerWidth<600?false:true;
        /* istanbul ignore next */
        return (
            <div>
                {/* search bar for single search term. */}
                <div className="centered-text" style={{padding: "10px"}}>
                    <h2 autoFocus={shouldAutoFocus} tabIndex="0" ref={this.focusRef}>Search for a website:</h2>
                </div>
                <table className="no-border" style={{borderCollapse: "collapse", border: "none"}}>
                    <tbody>
                        <tr style={{ border: "none"}}>
                            <td style={{ border: "none"}}>
                                <form className="search-row" onSubmit={(e) => { this.handleEnterKey(document.querySelector('#submitButton'), e) }}>
                                    <div className="search-col">
                                        <label htmlFor="name" tabIndex="0">Basic Search:</label>
                                        <span><input
                                            id="name"
                                            name="name"
                                            tabIndex="0"
                                            size={75}
                                            className=""
                                            type="text"
                                            autoFocus={shouldAutoFocus}
                                            autoComplete="on"
                                            onChange={this.changeHandler}
                                            placeholder='Search, example: "Pizza Hut" or "Domino&apos;s"'
                                        /></span>
                                    </div>
                                </form>
                            </td>
                            {/* send user to Results page after user chooses to search and see results. */}
                            <td style={{ paddingTop: "25px", border: "none", width: "120px", maxWidth: "120px" }}>
                                <Link
                                    onClick={() => this.handleLink()}
                                    id="submitButton"
                                    tabIndex="0"
                                    to={{
                                        pathname: "/Results", prevpage: " ",
                                        state: { searchTerm: this.state.name, isAdvancedSearch: false },
                                    }}
                                >
                                    {/* tabIndex="-1" here to avoid 2 tabs through submit button */}
                                    <button type="submit" alt="Search"  tabIndex="-1">
                                        Search
                                    </button>
                                </Link>
                            </td>
                        </tr>
                        <tr style={{ border: "none"}}>
                            <td style={{ textAlign: "left", border: "none"}}>
                                <div>
                                    <Link to="/Advanced Search" className="link padding" alt="Advanced Search Link">
                                        Advanced Search
                                    </Link>
                                    <br></br>
                                    <br></br>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
                {/* links to other pages across the site. */}
                <div className="buffer" />
                <div className="home-flex-row">
                    <Link to={{pathname:"/About Web Accessibility",prevpage:" "}} className="row-link">
                        <h2>Learn About Web Accessibility</h2>
                        <img src="images/about.webp" width="125" height="100"className="table-img" alt=""/>
                    </Link>
                    <Link to={{pathname:"/Submit New Website",prevpage:" "}} className="row-link">
                        <h2>Submit New Website</h2>
                        <img src="images/submitnewcompany.webp" width="125" height="100" className="table-img" alt=""/>
                    </Link>
                    <Link to={{pathname:"/Submit Feedback",prevpage:" "}} className="row-link">
                        <h2>Submit Feedback</h2>
                        <img src="images/community.webp" width="125" height="100" className="table-img" alt=""/>
                    </Link>

                </div>
                <div className="buffer" />

                <div className="home-flex-row">
                    <Link to={{pathname:"/Full Table of Results",prevpage:" "}} className="row-link">
                        <h2>Access Full Table of Results</h2>
                        <img src="images/fulltable.webp" width="125" height="100" className="table-img" alt=""/>
                    </Link>
                    <Link to={{pathname:"/Check Available Resources",prevpage:" "}} className="row-link">
                        <h2>Check Available Resources</h2>
                        <img src="images/services.webp" width="125" height="100" className="table-img" alt=""/>
                    </Link>
                    <Link to={{pathname:"/Browse Our References",prevpage:" "}} className="row-link">
                        <h2>Browse Our References</h2>
                        <img src="images/references.webp" width="125" height="100" className="table-img" alt=""/>
                    </Link>
                </div>
                <div className="buffer" />
            </div>
        );
    }
}

export default Home;
