var categories = [{
    id: 1,
    text: "Healthcare"
},
{
    id: 4,
    text: "Lifestyle"
},
{
    id: 6,
    text: "Government"
},
{
    id: 8,
    text: "Hobbies & Interests"
},
{
    id: 9,
    text: "Automotive"
},
{
    id: 10,
    text: "Industry"
},
{
    id: 11,
    text: "Technology"
},
{
    id: 12,
    text: "Science"
},
{
    id: 13,
    text: "Home & Garden"
},
{
    id: 14,
    text: "Career"
},
{
    id: 15,
    text: "Education"
},
{
    id: 17,
    text: "Social Media"
},
{
    id: 18,
    text: "Redirect"
},
{
    id: 19,
    text: "News"
},
{
    id: 20,
    text: "Games & Entertainment"
},
{
    id: 21,
    text: "Family & Parenting"
},
{
    id: 22,
    text: "Fashion"
},
{
    id: 23,
    text: "Finance"
},
{
    id: 24,
    text: "Food"
},
{
    id: 25,
    text: "Religion & Culture"
},
{
    id: 26,
    text: "Parks"
},
{
    id: 28,
    text: "Command and Control Centers"
},
{
    id: 29,
    text: "No Content Found"
},
{
    id: 30,
    text: "Parked & For Sale Domains"
},
{
    id: 31,
    text: "Private IP Address"
},
{
    id: 32,
    text: "Unreachable"
},
{
    id: 33,
    text: "Miscellaneous"
},
{
    id: 34,
    text: "Pets & Animals"
},
{
    id: 35,
    text: "Charity & Advocacy"
},
{
    id: 36,
    text: "Shopping"
},
{
    id: 37,
    text: "Sports & Outdoors"
},
{
    id: 38,
    text: "Graphics Software"
},
{
    id: 39,
    text: "Travel"
},
];

export default categories;
