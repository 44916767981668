import React from "react";
import '../styles.css';
import { Events, animateScroll as scroll } from 'react-scroll';

class TopScroll extends React.Component {
    constructor() {
        super()
        this.state = {
            thePosition: false
        }
        this.componentDidMount = this.componentDidMount.bind(this);
        this.scrollToTop = this.scrollToTop.bind(this);
        this.renderGoTopIcon = this.renderGoTopIcon.bind(this);
        this.view = this.view.bind(this);
    }

    //componentDidMount will automatically run when page loads (page will load this component).
    //places an event listener and position to scroll button.
    //makes sure that page is scrolled to top to start.
    componentDidMount() {
        window.addEventListener('scroll', this.view);
        window.scrollTo(0,0);
        Events.scrollEvent.register('begin', function () {
            // This is intentional
        });
        Events.scrollEvent.register('end', function () {
            // This is intentional
        });
    }

    //componentDidMount will automatically run user leaves page.
    //removes an event listener and position to scroll button.
    componentWillUnmount(){
        window.removeEventListener('scroll', this.view);
        Events.scrollEvent.remove('begin');
        Events.scrollEvent.remove('end');
    }

    //view changes position state based on where scroll is within page.
    //if user at top of page, scroll button will not show.
    view() {
        if (window.scrollY > 170) {
            this.setState({ thePosition: true })
        } else {
            this.setState({ thePosition: false })
        }
    }

    //scrollToTop sends the user to the top of the page.
    scrollToTop = () => {
        scroll.scrollToTop();
    }

    //renderGoTopIcon displays the "Back to Top" text on the scroll button.
    renderGoTopIcon() {
        //check if scroll button in position to be seen and display scroll button if so.
        if (this.state.thePosition) {
            return (
                <div className="go-to-top" onClick={this.scrollToTop} alt="Back to Top">
                    Back to Top
                </div>
            )
        }
        //else do not display scroll button.
        else {
            return(null);
        }
    }

    //render method for setting up the component.
    //displays scroll button on the screen when needed.
    render() {
        return (
            <React.Fragment>
                {this.renderGoTopIcon()}
            </React.Fragment>
        )
    }
}

export default TopScroll;
