import React from "react";
import '../styles.css';
import { useLocation, useHistory, Link } from 'react-router-dom';
import BreadCrumbTrail from "./BreadCrumbTrail";

//HeaderView prints current location (page) to the screen inside the Header.
//user can see which page they are on as well as all the pages they traveled to in order to get to the current page.
//this does reset if the user travels to a "previous" page.
function HeaderView() {
    //set constants to hold values of current location.
    const location = useLocation().pathname;
    const prevpage = useLocation().prevpage;
    const prevprevpage = useLocation().prevprevpage;
    const locationColon = location.indexOf(":");
    const endPos = locationColon===-1?location.length:locationColon - 1;
    const newlocation = location.substring(1, endPos);
    //set constant to hold value of user's history on site.
    const history = useHistory();
    //pull values from localStorage for use in displaying which information to user.
    const FullTableChecker = localStorage.getItem('isFullTable');
    const AdvancedSearchChecker = localStorage.getItem('isAdvancedSearch');
    const TutorialPageChecker = localStorage.getItem('isTutorialPage');
    const isAddSite = localStorage.getItem('isAddSite');

    const media = window.matchMedia( "(max-width: 700px)" );
    var printLocation = newlocation;
    var printOrder = null;
    // check if on mobile, if so, shorten Bread Crumb Trail titles.
    if (media.matches) {
        if (newlocation === "Full Table of Results") {
            printLocation = "Full Table"
        }
        else if (newlocation === "About Web Accessibility") {
            printLocation = "About"
        }
        else if (newlocation === "Submit New Website") {
            printLocation = "Submit Website"
        }
        else if (newlocation === "Browse Our References") {
            printLocation = "References"
        }
        else if (newlocation === "Check Available Resources") {
            printLocation = "Resources"
        }
        // change User Tutorial styling if on mobile as well.
        printOrder = <div className="heading-order">
                        <Link to="/User Tutorial">
                            <p className="heading-items" alt="User Tutorial Page Link" style={{margin: "20px", paddingTop: "15px"}}>User Tutorial</p></Link>
                    </div>
    }

    //the use of components makes this easier and more readable (BreadCrumbTrail).
    return (
        <div>
            {/* adjusted User Tutorial styling here if on mobile. */}
            {printOrder}
            <BreadCrumbTrail
                location={newlocation}
                printLocation={printLocation}
                prevpage={prevpage}
                prevprevpage = {prevprevpage}
                history={history}
                FullTableChecker={FullTableChecker}
                AdvancedSearchChecker={AdvancedSearchChecker}
                TutorialPageChecker={TutorialPageChecker}
                isAddSite={isAddSite}
            />
        </div>
    )
}

export default HeaderView;
