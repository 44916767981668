import React from "react";
import "../styles.css";

class References extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
        this.focusRef = React.createRef();
        this.printLanguagesFrameworks = this.printLanguagesFrameworks.bind(this);
        this.printNodePackages = this.printNodePackages.bind(this);
    }

    //componentDidMount will place the focus of the user (for screen reader purposes) on the first element on the page.
    async componentDidMount() {
      if (this.focusRef.current) {
        this.focusRef.current.focus();
      }
    }

    printLanguagesFrameworks() {
        return <tbody>
                    <tr>
                        <th className="tech-table-row-header" tabIndex="0" style={{fontSize:"20px",padding:"5px"}}>Languages, Frameworks, etc.</th>
                    </tr>
                    <tr>
                        <td className="tech-table-row">
                            <a href="https://www.ansible.com/" className="link" alt="Ansible's company website link"
                            target="_blank" rel="noopener noreferrer">Ansible</a>
                        </td>
                    </tr>
                    <tr>
                        <td className="tech-table-row">
                            <a href="https://developers.google.com/web/tools/lighthouse" className="link" alt="Google Lighthouse's website link"
                            target="_blank" rel="noopener noreferrer">Chrome Lighthouse Audits</a>
                        </td>
                    </tr>
                    <tr>
                        <td className="tech-table-row">
                            <a href="https://www.elastic.co/" className="link" alt="Elasticsearch company's website link"
                            target="_blank" rel="noopener noreferrer">Elasticsearch</a>
                        </td>
                    </tr>
                    <tr>
                        <td className="tech-table-row">
                            <a href="https://www.selenium.dev//" className="link" alt="Selenium company's website link"
                            target="_blank" rel="noopener noreferrer">Selenium</a>
                        </td>
                    </tr>
                    <tr>
                        <td className="tech-table-row">
                            <a href="https://www.python.org/" className="link" alt="Python company's website link"
                            target="_blank" rel="noopener noreferrer">Python 3</a>
                        </td>
                    </tr>
                    <tr>
                        <td className="tech-table-row">
                            <a href="https://reactrouter.com/" className="link" alt="React Router company's website link"
                            target="_blank" rel="noopener noreferrer">React-Router</a>
                        </td>
                    </tr>
                    <tr>
                        <td className="tech-table-row">
                            <a href="https://reactjs.org/" className="link" alt="React js company's website link"
                            target="_blank" rel="noopener noreferrer">React.js</a>
                        </td>
                    </tr>
                    <tr>
                        <td className="tech-table-row">
                            <a href="https://foursquare.com/products/places/" className="link" alt="Foursquare's company website link"
                            target="_blank" rel="noopener noreferrer">Foursquare</a>
                        </td>
                    </tr>
                    <tr>
                        <td className="tech-table-row">
                            <a href="https://zvelo.com/resources/software-developer-program/" className="link" alt="Zvelo's company website link"
                            target="_blank" rel="noopener noreferrer">Zvelo</a>
                        </td>
                    </tr>
                </tbody>
    }

    printNodePackages() {
        return <tbody>
                    <tr>
                        <th className="tech-table-row-header" tabIndex="0" style={{fontSize:"20px",padding:"5px"}}>Node.js Packages</th>
                    </tr>
                    <tr>
                        <td className="tech-table-row">
                            <a href="https://www.npmjs.com/package/axios" className="link" alt="axios Package link"
                            target="_blank" rel="noopener noreferrer">axios</a>
                        </td>
                    </tr>
                    <tr>
                        <td className="tech-table-row">
                            <a href="https://www.npmjs.com/package/axios-mock-adapter" className="link" alt="axios-mock-adapter Package link"
                            target="_blank" rel="noopener noreferrer">axios-mock-adapter</a>
                        </td>
                    </tr>
                    <tr>
                        <td className="tech-table-row">
                            <a href="https://www.npmjs.com/package/body-parser" className="link" alt="body-parser Package link"
                            target="_blank" rel="noopener noreferrer">body-parser</a>
                        </td>
                    </tr>
                    <tr>
                        <td className="tech-table-row">
                            <a href="https://www.npmjs.com/package/@elastic/elasticsearch" className="link" alt="Elasticsearch Node.js client Package link"
                            target="_blank" rel="noopener noreferrer">elasticsearch</a>
                        </td>
                    </tr>
                    <tr>
                        <td className="tech-table-row">
                            <a href="https://enzymejs.github.io/enzyme/" className="link" alt="enzyme Package link"
                            target="_blank" rel="noopener noreferrer">enzyme</a>
                        </td>
                    </tr>
                    <tr>
                        <td className="tech-table-row">
                            <a href="https://expressjs.com/" className="link" alt="express Package link"
                            target="_blank" rel="noopener noreferrer">express</a>
                        </td>
                    </tr>
                    <tr>
                        <td className="tech-table-row">
                            <a href="https://www.npmjs.com/package/is-reachable" className="link" alt="is-reachable Package link"
                            target="_blank" rel="noopener noreferrer">is-reachable</a>
                        </td>
                    </tr>
                    <tr>
                        <td className="tech-table-row">
                            <a href="https://jestjs.io/" className="link" alt="jest Package link"
                            target="_blank" rel="noopener noreferrer">jest</a>
                        </td>
                    </tr>
                    <tr>
                        <td className="tech-table-row">
                            <a href="https://testing-library.com/docs/ecosystem-jest-dom/" className="link" alt="jest-dom Package link"
                            target="_blank" rel="noopener noreferrer">jest-dom</a>
                        </td>
                    </tr>
                    <tr>
                        <td className="tech-table-row">
                            <a href="https://www.npmjs.com/package/jest-enzyme" className="link" alt="jest-enzyme Package link"
                            target="_blank" rel="noopener noreferrer">jest-enzyme</a>
                        </td>
                    </tr>
                    <tr>
                        <td className="tech-table-row">
                            <a href="https://jquery.com/" className="link" alt="jquery Package link"
                            target="_blank" rel="noopener noreferrer">jquery</a>
                        </td>
                    </tr>
                    <tr>
                        <td className="tech-table-row">
                            <a href="https://nodemailer.com/about/" className="link" alt="Nodemailer Package link"
                            target="_blank" rel="noopener noreferrer">nodemailer</a>
                        </td>
                    </tr>
                    <tr>
                        <td className="tech-table-row">
                            <a href="https://nodemon.io/" className="link" alt="nodemon Package link"
                            target="_blank" rel="noopener noreferrer">nodemon</a>
                        </td>
                    </tr>
                    <tr>
                        <td className="tech-table-row">
                            <a href="https://www.npmjs.com/package/npm-run-all" className="link" alt="npm-run-all Package link"
                            target="_blank" rel="noopener noreferrer">npm-run-all</a>
                        </td>
                    </tr>
                    <tr>
                        <td className="tech-table-row">
                            <a href="https://reactjs.org/docs/react-dom.html" className="link" alt="react-dom Package link"
                            target="_blank" rel="noopener noreferrer">react-dom</a>
                        </td>
                    </tr>
                    <tr>
                        <td className="tech-table-row">
                            <a href="https://www.npmjs.com/package/react-google-recaptcha" className="link" alt="react-google-recaptcha Package link"
                            target="_blank" rel="noopener noreferrer">react-google-recaptcha</a>
                        </td>
                    </tr>
                    <tr>
                        <td className="tech-table-row">
                            <a href="https://www.npmjs.com/package/react-router-dom" className="link" alt="react-router-dom Package link"
                            target="_blank" rel="noopener noreferrer">react-router-dom</a>
                        </td>
                    </tr>
                    <tr>
                        <td className="tech-table-row">
                            <a href="https://www.npmjs.com/package/react-scripts" className="link" alt="react-scripts Package link"
                            target="_blank" rel="noopener noreferrer">react-scripts</a>
                        </td>
                    </tr>
                    <tr>
                        <td className="tech-table-row">
                            <a href="https://reactjs.org/docs/test-renderer.html" className="link" alt="React Test Renderer link"
                            target="_blank" rel="noopener noreferrer">react-test-renderer</a>
                        </td>
                    </tr>
                    <tr>
                        <td className="tech-table-row">
                            <a href="https://select2.org/" className="link" alt="select2 link"
                            target="_blank" rel="noopener noreferrer">select2</a>
                        </td>
                    </tr>
                    <tr>
                        <td className="tech-table-row">
                            <a href="https://www.typescriptlang.org/" className="link" alt="TypeScript link"
                            target="_blank" rel="noopener noreferrer">typescript</a>
                        </td>
                    </tr>
                </tbody>
    }

    //render displays a list of all packages, technologies, and resources that were utilized by iSendpai to make this site.
    //references include links to the other websites.
    render() {
        return (
            <div>
                <div className="flex-width-paragraph" style={{ paddingTop: "20px" }}>
                    <h2 className="pre-tech-header" autoFocus={true} ref={this.focusRef} tabIndex="0">
                        Here are the technologies and resources that we have utilized to create our site:
                    </h2>
                    <table className="tech-table">
                        {this.printLanguagesFrameworks()}
                    </table>
                    <br />
                    <table className="tech-table">
                        {this.printNodePackages()}
                    </table>
                </div>
                <br />
            </div>
        );
    }
}
export default References;
