import React from "react";
import "../styles.css";
import Refactor from "../components/Refactor";

class About extends Refactor {
  constructor(props) {
    super(props);
    this.state = {};
    this.focusRef = React.createRef();
  }

  async componentDidMount() {
    if (this.focusRef.current) {
      this.focusRef.current.focus();
    }
  }
  //render method for setting up the page and displaying the given information.
  //displays infomation about 508 Compliance, PWA, WCAG, and how it affects iSenpai.
  //including links to send the user to the correct websites if they would like to learn more.
  render() {
    return (
      <div>
        <div className="flex-width-paragraph" style={{ paddingTop: "20px" }}>
          {/* various paragraphs on the different aspects of 508 Compliance. */}
          <h2 autoFocus={true} ref={this.focusRef} tabIndex="0">Learn About Web Accessibility Page!</h2>
          <h3>What is 508 Compliance?</h3>
          <p>
            508 Compliance is a section in the Rehabilitation Act of 1973. It
            was established to provide protection and services for individuals
            with disabilities. Section 508 specifically covers issues related
            to computer technology, especially the internet. All website
            content is to be accessible to people with disabilities. The current
            standard for web accessibility is the WCAG 2.1, maintained by the World
            Wide Web Consortium (W3C).
            </p>
          <br />
          <h3>What does it mean to be 508 Compliant as a website?</h3>
          <p>
            A website is 508 Compliant when it is accessible by all
            users. Accessibility is determined by how well a website meets
            certain standards, mainly what allows the disabled community to
            access the website and be able to navigate it easily and proficiently.
            How well the website works with Captions, Transcripts, Keyboard
            Navigation, Screen Reading, ALT Text for Images and Videos, Color
            and Contrast, and No Timeouts, determines how accessible it is.
            All these features make up 508 Compliance and Accessibility.
          </p>
          <br />
          <h3>Why is being 508 Compliant and Accessible important?</h3>
          <p >
            The internet has grown rapidly and is the center piece for
            resources and opportunities. Sixty percent of the world accesses
            the internet everyday. Imagine being someone who couldn't access
            what everyone else around you can? This is where 508 Compliance
            steps in. It compels website owners and businesses to “develop,
            procure, maintain, and use electronic and information technology”
            so that those with disabilities are able to access the same
            internet that those who don't have disabilities can access.
          </p>
          <br />
          <h3>Why is 508 Compliance important to iSenpai?</h3>
          <p>
            Accessibility and universal participation are driving factors behind
            the commitments and projects at iSenpai. We came into existence with
            the goal of eradicating childhood blindness. In the meantime,
            our employees and leaders strive to ensure that physical disability
            does not impact a person’s chance to participate in the technology industry.
            To learn more about why 508 Compliance is important to iSenpai,  <a href="https://www.isenpai.com/about/"
            className="link" alt="iSenpai's company website link" target="_blank"
            rel="noopener noreferrer">visit
            our official website here</a>.
            </p>
          <br />
          <h3>What is the WCAG and Success Criteria?</h3>
          <p>
          <a className="link" href="https://www.w3.org/TR/WCAG21/" alt="Web Content Accessibility Guidelines link"
                target="_blank" rel="noopener noreferrer">Web Content Accessibility
            Guidelines (WCAG)</a> are the best means of making your website accessible to all of your users.
            The WCAG guidelines are a series of documents compiled by the <a className="link"
            href="https://www.w3.org/" alt="World Wide Web Consortium link" target="_blank"
              rel="noopener noreferrer">World Wide Web Consortium (W3C)</a> as a part of the
            W3C's <a href="https://www.w3.org/WAI/" className="link" alt="Web Accessibility Initiative link"
              target="_blank" rel="noopener noreferrer">Web Accessibility
            Initiative (WAI)</a>. The WAI was developed with the intention to make the internet more
            perceivable, operable, understandable, and robust. WCAG consists of various tests, each defined as
            a Success Criteria, that can be conducted on a website. There are many levels of Success Criteria
            and every Success Criteria has a specific functionality. Each Success Criteria addresses a specific
            part of accessibility, whether it affects vision (blind/color-blind), cognitive, motor (physical),
            or hearing disabilities.
          </p>
          <br />
          <h3>More about the Web Content Accessibility Guidelines</h3>
          <p>
            To conform to the WCAG, all content on a website must satisfy the Success Criteria.
            The WCAG has three levels of Success Criteria to accommodate different situations and needs.
            The three levels that tell us the level of accessibility a criteria provides are: A (lowest),
            AA (mid range), and AAA (highest). Level A is the most important as it sets a minimum level
            of accessibility websites should have.
            <br /><br />
            Example: Success Criteria, 1.1.1 Non-text Content (Level A) deals with non-text content
            (such as buttons, icons, images, links, etc.). This criteria makes sure that all the
            non-text content on the website has text alternatives (also known as “alt text”).
            This is mainly for the blind community who cannot see an image (for example),
            but with alt text, the image can be screen read to let the user know that the
            image is there and what the image is displaying.
          </p>
          <br />
          <h3>What is PWA and how are its standards met?</h3>
          <p>
            Progressive Web App (PWA) features have been created for websites to promote and build an
            application-like experience for all users. It combines the features offered by websites
            as well as the benefits of a mobile application. In order to meet PWA standards, a website needs to
            be able to load in offline or flaky network conditions, be relatively fast, be served from a secure origin,
            and use certain accessibility best practices.
            </p>
          <br />
          <h3>How 508 Compliance, Accessibility, and PWA are Tested:</h3>
          <p>
            We are using Google Lighthouse to perform audits for 508 Compliance, Accessibility, and PWA standards.
            Google Lighthouse scans the first page of the website, the landing page.
            The Lighthouse score is a weighted average of all the audits
            (each audit tests a specific WCAG Criteria or PWA standard). Each audit is either pass or fail,
            no partial points are given. Since each audit has its own weight
            (based on the corresponding WCAG Level or PWA standard), the more heavily weighted audits have a
            bigger impact on that website's score.
          </p>
          <br />
          {/* table to show how we assign grades to websites from the Lighthouse score. */}
          <p className="centered-text">Websites are assigned a grade (from the Lighthouse accessibility score)
              based on the table below:</p>
          <table className="mobile-table">
            {(this.gradeTable())}
          </table>
          <br />
          {/* links for the user to travel to if they want to learn more. */}
          <h3 className="pre-tech-header">Learn More:</h3>
          <table className="about-table">
            <tbody>
              <tr>
                <th className="about-table-row-header" tabIndex="0">Extra readings on 508 compliance and WCAG2.1</th>
              </tr>
              <tr>
                <td className="about-table-row">
                  <a href="https://www.section508.gov/" className="link" alt="Section 508 link"
                  target ="_blank" rel="noopener noreferrer">Section508.gov</a>
                </td>
              </tr>
              <tr>
                <td className="about-table-row">
                  <a href="https://www.w3.org/WAI/" className="link" alt="W3C Web Accessibility Initiative Link"
                  target="_blank" rel="noopener noreferrer">W3C Web Accessibility Initiative</a>
                </td>
              </tr>
              <tr>
                <td className="about-table-row">
                  <a href="https://www.w3.org/TR/WCAG21/" className="link" alt="Web Content Accessibility Guidelines link"
                  target="_blank" rel="noopener noreferrer">WCAG 2.1</a>
                </td>
              </tr>
              <tr>
                <td className="about-table-row">
                  <a href="https://www.access-board.gov/ict/#about-the-ict-accessibility-standards"
                  className="link" alt="U.S. Access Board: Section 508 Standards link"
                  target="_blank" rel="noopener noreferrer">US Access Board - About the Section 508 Standards</a>
                </td>
              </tr>
              <tr>
                <td className="about-table-row">
                  <a href="https://www.access-board.gov/law/ra.html#section-508-federal-electronic-and-information-technology"
                  className="link" alt="U.S. Access Board: Rehabilitation Act of 1973 link"
                  target="_blank" rel="noopener noreferrer">US Access Board - Rehabilitation Act of 1973</a>
                </td>
              </tr>
              </tbody>
          </table>
          <br />
          <br />
        </div>
        <br />
      </div>
    );
  }
}

export default About;
