import React, { Component } from "react";
import { withRouter } from "react-router-dom";

/*istanbul ignore next */
class ScrollToTop extends Component {

	//componentDidUpdate runs when page loads or when it updates.
	//takes user to the top of the page when page first loads or updates.
	componentDidUpdate(prevProps) {
		if (this.props.location !== prevProps.location) {
			window.scrollTo(0, 0);
		}
	}

	//render method for setting up the page and displaying the given information.
  	//the use of components makes this easier and more readable (React.Fragment).
	render() {
		return <React.Fragment />
	}
}

export default withRouter(ScrollToTop);
