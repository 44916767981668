import React from "react";
import "../styles.css";
import Refactor from "./Refactor";

class SearchResultTable extends Refactor {
  constructor(props) {
    super(props);
    this.handleLink = this.handleLink.bind(this);
    this.printCategory = this.printCategory.bind(this);
    this.setRowStorage = this.setRowStorage.bind(this);
    this.tableHeading = this.tableHeading.bind(this);
  }

  //handleLink sets the current values in state to values in localStorage to be accessed by other pages.
  /*istanbul ignore next*/
  handleLink(row) {
    localStorage.setItem('isAdvancedSearch', this.props.isAdvancedSearch);
    localStorage.setItem('isFullTable', false);
    localStorage.setItem('isTutorialPage', false);
    this.setRowStorage(row);
  }

  //render method for setting up the page and displaying the given information.
  //displays a table of the search results found from the user's search.
  /* istanbul ignore next */
  render() {
    return (
      <table>
        {/* set up column headers for each field. */}
        {(this.tableHeading())}
        {/* set up body entries for each field. */}
        <tbody>
          {this.props.paint.map((row, index) => (
            this.tableData("Results", row, index)
          ))}
        </tbody>
      </table>
    );
  }
}

export default SearchResultTable;
