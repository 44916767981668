import React from "react";
import PopUp from "./PopUp";
import { Link } from "react-router-dom";

class Refactor extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    //getSubmittedFormData finds out if data has been submitted and returns whether or not there is submitted data.
    /*istanbul ignore next */
    getSubmittedFormData(fieldName,submittedFormData) {
        if (!submittedFormData) {
            return null;
        }
        return submittedFormData[fieldName];
    }

    //notARobotCheck will check if ReCaptha passed or not. If not, display Pop Up asking user to complete captcha.
    notARobotCheck(notARobot,initialSubmit) {
        if (!notARobot && !initialSubmit) {
            return <PopUp
                        toggle={this.togglePop}
                        status={this.state.seen}
                        message={"Please complete captcha."}
                    />
        }
        return null;
    }

    //displayButton will display the submission button for Addsite and CommunityFeedback.
    displayButton() {
        return <button
                  className="button"
                  type="button"
                  onClick={this.submitForm.bind(this)}
                  alt="Submit Website">
                  <div style={{ display: "flex", justifyContent: "space-around", alignItems: "center" }}>
                    <div style={{ flexBasis: 3, flexGrow: 4 }}>
                      {(this.state.formStatus === 'submitting') ? "Submitting" : "Submit"}
                    </div>
                  </div>
                </button>
    }

    //compareTitle will sort the results by title.
    compareTitle() {
        this.state.results.sort((a, b) => {
            //bring both values to lower case to compare.
            var x = a.title.toLowerCase();
            var y = b.title.toLowerCase();
            //check if first char is not in alphabet (ex. "a" will be placed before "[")
            if ((x[0] >= 'a' && x[0] <= 'z') && (y[0] <= 'a' || y[0] >= 'z')) {
                return -1;
            }
            else if ((y[0] >= 'a' && y[0] <= 'z') && (x[0] <= 'a' || x[0] >= 'z')) {
                return 1;
            }
            //check which is "greater" (ex. "b" > "a" meaning (in ascending sort) that "a" will be placed before "b").
            else {
                if (x < y) {
                    return -1;
                }
                else if (x > y) {
                    return 1;
                }
            }
            return 0;
        });
    }

    //sortByTitle sorts the results by the website name using the "title" key in state.
    sortByTitle() {
        this.compareTitle();
        //set results in state to new sorted value.
        this.setState({
            results: this.state.results,
            sort_by: "title",
        });
    }

    //sortHandler updates when the user changes the "Sort by" dropdown value.
    sortHandler = (event) => {
        //reset direction to ascend.
        this.setState( {sort_direction: "ascend"});
        //if the user wants to view the results by relevance first.
        /*istanbul ignore next*/
        if (event.target.value === "relevance") {
            this.sortByRelevance();
        }
        //if the user wants to view the results by best accessibility grade first.
        else if (event.target.value === this.state.accessibility_grade) {
            this.sortByAccessibilityGrade();
        }
        //if the user wants to view the results by best pwa grade first.
        else if (event.target.value === "PWA grade") {
            this.sortByPWAGrade();
        }
        //if the user wants to view the results by url alphabetically.
        else if (event.target.value === "URL") {
            this.sortByURL();
        }
        //if the user wants to view the results by most recent test first.
        else if (event.target.value === this.state.date_tested) {
            this.sortByDate();
        }
        //if the user wants to view the results by category alphabetically.
        else if (event.target.value === "category") {
            this.sortByCategory();
        }
        //if the user wants to view the results by website name alphabetically.
        else {
            this.sortByWebsite();
        }
    }

    //sortDirectionHandler updates when the user changes the "Sort by Direction" dropdown value.
    sortDirectionHandler = (event) => {
        //check if change in sorting direction.
        /*istanbul ignore next*/
        if (event.target.value !== this.state.sort_direction) {
            //change sorting direction to given value of dropdown.
            this.setState( {sort_direction: event.target.value});
            //show results sorted by relevance in new sorting direction chosen by user.
            if (this.state.sort_by === "relevance") {
                this.sortByRelevance();
            }
            //show results sorted by accessibility grade in new sorting direction chosen by user.
            else if (this.state.sort_by === this.state.accessibility_grade) {
                this.sortByAccessibilityGrade();
            }
            //show results sorted by pwa grade in new sorting direction chosen by user.
            else if (this.state.sort_by === "PWA grade") {
                this.sortByPWAGrade();
            }
            //show results sorted by url in new sorting direction chosen by user.
            else if (this.state.sort_by === "URL") {
                this.sortByURL();
            }
            //show results sorted by date tested in new sorting direction chosen by user.
            else if (this.state.sort_by === this.state.date_tested) {
                this.sortByDate();
            }
            //show results sorted by category in new sorting direction chosen by user.
            else if (this.state.sort_by === "category") {
                this.sortByCategory();
            }
            //show results sorted by website name in new sorting direction chosen by user.
            else {
                this.sortByWebsite();
            }
        }
    }

    //gradeTable will display the table of grades A-F and which number grade corresponds to which letter grade.
    gradeTable() {
        return <tbody>
                    <tr>
                        <th className="twenty" style={{ border: "none" }}>A</th>
                        <th className="twenty" style={{ border: "none" }}>B</th>
                        <th className="twenty" style={{ border: "none" }}>C</th>
                        <th className="twenty" style={{ border: "none" }}>D</th>
                        <th className="twenty" style={{ border: "none" }}>F</th>
                    </tr>
                    <tr>
                        <td className="centered-text" style={{ border: "none" }}>90 to 100</td>
                        <td className="centered-text" style={{ border: "none" }}>80 to 89</td>
                        <td className="centered-text" style={{ border: "none" }}>70 to 79</td>
                        <td className="centered-text" style={{ border: "none" }}>60 to 69</td>
                        <td className="centered-text" style={{ border: "none" }}>0 to 59</td>
                    </tr>
                </tbody>
    }

    //printCategory checks if category is undefined, in which case that website does not have a category.
    //print "N/A" as that website's category then.
    printCategory(category) {
        //check if category is undefined.
        var undefinedCategory = (category === undefined)||(category === "text")||(category.length === 0)||(category === "t")
        if (!undefinedCategory) {
            undefinedCategory = undefinedCategory || (category === Array.isArray() && !category.length)
        }
        //if category is undefined, show category result as "N/A".
        if (undefinedCategory) {
            return "N/A";
        }
        //then website has category.
        else {
            return category[0];
        }
    }

    checkDefinedCategory(category) {
        //check if A's category is defined. definedA = true, in that case.
        var definedA = category !== undefined && category !== "text" && category !== "t";
        if (definedA) {
            definedA = definedA && !(category === Array.isArray() && !category.length);
        }
        return definedA;
    }

    categorySortAscend(a,b) {
        /*localeCompare is a method that sorts by alphabetical order
        'Fashion'.localeCompare('Shopping') will yield a neg value...
        'Shopping'.localeCompare('Fashion') will yield a pos value...*/
        var categoryA = a.category[0];
        var categoryB = b.category[0];
        var definedA = this.checkDefinedCategory(categoryA);
        var definedB = this.checkDefinedCategory(categoryB);
        var undefinedA = !definedA;
        var undefinedB = !definedB;
        //if A is undefined and B is defined, then B should show first (always send undefined to bottom of results).
        if (undefinedA && definedB) {
            return 1;
        }
        //if B is undefined and A is defined, then A should show first (always send undefined to bottom of results).
        else if (definedA && undefinedB) {
            return -1;
        }
        //if both A and B are undefined, keep them as is.
        else if (undefinedA && undefinedB) {
            return 0;
        }
        //if both A and B are defined, compare category alphabetically (ascending) like normal.
        else {
            return categoryA.localeCompare(categoryB);
        }
    }

    categorySortDescend(a,b) {
        /*localeCompare is a method that sorts by alphabetical order
        'Fashion'.localeCompare('Shopping') will yield a neg value...
        'Shopping'.localeCompare('Fashion') will yield a pos value...*/
        var categoryA = a.category[0];
        var categoryB = b.category[0];
        var definedA = this.checkDefinedCategory(categoryA);
        var definedB = this.checkDefinedCategory(categoryB);
        var undefinedA = !definedA;
        var undefinedB = !definedB;
        //if A is undefined and B is defined, then B should show first (always send undefined to bottom of results).
        if (undefinedA && definedB) {
            return 1;
        }
        //if B is undefined and A is defined, then A should show first (always send undefined to bottom of results).
        else if (definedA && undefinedB) {
            return -1;
        }
        //if both A and B are undefined, keep them as is.
        else if (undefinedA && undefinedB) {
            return 0;
        }
        //if both A and B are defined, compare category alphabetically (descending) like normal.
        else {
            return categoryB.localeCompare(categoryA);
        }
    }

    //setRowStorage will save the information of the given website into local storage for use on other pages in the website.
    setRowStorage(row) {
        localStorage.setItem('company', row.company);
        localStorage.setItem('category', row.category);
        localStorage.setItem('url', row.url);
        localStorage.setItem('grade_letter', row.grade_letter);
        localStorage.setItem('grade_number', row.grade_number);
        localStorage.setItem('results_of_grade', JSON.stringify(row.results_of_grade));
        localStorage.setItem('PWA_score', row.PWA_score);
        localStorage.setItem('date_of_test', row.date_of_test);
        localStorage.setItem('PWA_results', JSON.stringify(row.results_of_PWA_grade));
    }

    //tableHeading will display each column heading in the table for any of the results pages.
    tableHeading() {
        return <thead>
                    <tr>
                        <th className="twenty-five" scope="col" alt="Websites Column">
                            Website Name
                        </th>
                        <th className="fifteen" scope="col" alt="Category">
                            Category
                        </th>
                        <th scope="col" alt="URL Column">
                            URL
                        </th>
                        <th className="ten" scope="col" alt="Grade Column">
                            Accessibility Grade
                        </th>
                        <th className="ten" scope="col" alt="PWA Score Column">
                            PWA Grade
                        </th>
                        <th className="twenty" scope="col" alt="Date Tested Column">
                            Date Tested
                        </th>
                    </tr>
                </thead>

    }

    //tableData will display all information pertaining to the entry given in any of the results tables.
    tableData(previousPage, row, index) {
        return <tr key={index}>
              <th className="th-row" scope="row" tabIndex="0">{row.company}</th>
              <td className="centered-text" tabIndex="0">{this.printCategory(row.category)}</td>
              <td className="centered-text" tabIndex="0">
                <a href={`https://${row.url}`} className="link" target="_blank" rel="noopener noreferrer">{row.url}</a>
              </td>
              <td className="centered-text" style={{textDecoration: "underline"}} tabIndex="0">
                {/* sends user to WCAG Reason page if they wish to see the reason behind this grade. */}
                <Link onClick={() => this.handleLink(row)}
                  rel="noopener noreferrer"
                  to={{
                    pathname: `/WCAG Reason/:id=${row.id}`,
                    prevpage: previousPage,
                  }}>{row.grade_letter}
                </Link></td>
              <td className="centered-text" style={{textDecoration: "underline"}} tabIndex="0">
                {/* sends user to PWA Reason page if they wish to see the reason behind this grade. */}
                <Link onClick={() => this.handleLink(row)}
                  rel="noopener noreferrer"
                  to={{
                    pathname: `/PWA Reason/:id=${row.id}`,
                    prevpage: previousPage,
                  }}>{this.props.scoreToLetter(row.PWA_score*100)}
                </Link></td>
              <td className="centered-text" tabIndex="0">{row.date_of_test}</td>
            </tr>
    }

    //validateField will determine whether the given value is valid for that field.
    validateField(field, value, axios) {
        if(value) {
            axios.post('https://508-portal-es.isenpailabs.com/ValidateField', {
              value: value,
              field: field
            })
            .then((res) => {
              //if field value is valid.
              if(res.data === true) {
                this.setFieldTrue(field);
              }
              //else field value is not valid.
              else {
                this.setFieldFalse(field);
              }
            })
            //catch any errors that occur.
            .catch((err) => {
              console.log(err);
            })
          }
    }

    //submissionButton contains the button for Addsite and CommunityFeedback as well as any needed information from the
    //submission. submissionButtom will send user to Thanks page.
    submissionButton(handleLink,submitForm,thankYouRef,formStatus) {
        return <div className="centered-item">
                    <button
                        className="button"
                        type="button"
                        tabIndex="0"
                        onClick={submitForm.bind(this)}
                        alt="Submit Website">
                        <div style={{ display: "flex", justifyContent: "space-around", alignItems: "center" }}>
                            <div style={{ flexBasis: 3, flexGrow: 4 }}>
                                {/* send user to Thanks page after feedback submission. */}
                                <Link tabIndex="-1"
                                aria-labelledby= "submitButton"
                                onClick={handleLink}
                                id="submitButton"
                                to={
                                    {
                                    pathname: "Thanks For Submitting",
                                    state: {
                                        from: "Submit New Website",
                                        isAddSite: true,
                                        isFeedback: false,
                                    }
                                    }
                                }
                                alt="Thanks For Submitting"
                                ref={thankYouRef}>
                                    {(formStatus === 'submitting') ? "Submitting" : "Submit"}
                                </Link>
                            </div>
                        </div>
                    </button>
                </div>
    }
}

export default Refactor;
