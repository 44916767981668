import React from "react";
import "../styles.css";

class Resources extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
        this.focusRef = React.createRef();
    }

    //componentDidMount will place the focus of the user (for screen reader purposes) on the first element on the page.
    async componentDidMount() {
      if (this.focusRef.current) {
        this.focusRef.current.focus();
      }
    }

    //render displays the information that would help the user to utilize and easily access the internet.
    //including links to send the user to the correct websites/tools.
    render() {
        return (
            <div>
                <div className="flex-width-paragraph" style={{ paddingTop: "20px" }}>
                    <h2 autoFocus={true} ref={this.focusRef} tabIndex="0">Here are some avenues that are good starting points for those who are looking to utilize
                        508 compliant tools in their internet experience.</h2><br />
                    <h3>What tools exist to make browsing the internet easier?</h3>
                    <p>Building a toolkit that works for everyone is impossible, but certain tools exist that
                    greatly enhance the experience. Plugins for browsers, screen reader software, and compatible
                        operating systems are all crucial tools in providing an easy-to-use ecosystem.</p>
                    <br />
                    <h3>What operating system should I use?</h3>
                    <p>Most users choose Windows 10 due to its accessibility features, screen reader support, and general
                        ease of use. Screen readers like <a href="https://www.freedomscientific.com/products/software/jaws/"
                           alt="Freedom Scientific JAWS link" target="_blank" rel="noopener noreferrer" className="link">JAWS</a>{" "}
                        and <a href="https://www.nvaccess.org/" alt="NV Access NVDA link" target="_blank" rel="noopener noreferrer" className="link">NVDA</a>
                        {" "}only work on Windows.</p>
                    <br />
                    <h3>What browser should I use?</h3>
                    <p>Consider using Mozilla Firefox or Google Chrome. Both are excellent browsers due to
                    their speed and plugin support. Other browsers like Safari, Edge, and Internet Explorer do not
                        support all accessibility features and have poor compatibility.</p>
                    <br />
                    <h3>What plugins are available?</h3>
                    <ul>
                        <li><a href="https://privacybadger.org/" alt="Privacy Badger link" target="_blank" rel="noopener noreferrer"
                            className="link">Privacy Badger</a> removes trackers that persist across web pages.</li>
                        <li>uBlock Origin blocks ads, pop-ups, and trackers.</li>
                        <li><a href="https://www.eff.org/https-everywhere" alt="HTTPS Everywhere link" target="_blank" rel="noopener noreferrer"
                            className="link">HTTPS Everywhere</a> enhances user security by forcing all requests to use HTTP Secure.</li>
                        <li>If you do not know how to install a plugin, click <a href="https://www.computerhope.com/issues/ch001888.htm" alt="How to add extensions link"
                            target="_blank" rel="noopener noreferrer"
                            className="link">How to Install Plugin</a>.</li>
                    </ul>
                    <br />
                </div>
                <br />
            </div>
        );
    }
}

export default Resources;
