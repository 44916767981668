import React from "react";
import "./comment-box.css";
import "../styles.css";
import { Link } from "react-router-dom";

class ReviewChart extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    //showChart will either display the chart of User Reviews from 1 - 5 stars or encourage user to be first to review.
    //showChart will show a bar graph of how many users reviewed this website from 1 - 5 stars.
    //showChart will also show the most recent review if the website has reviews.
    /*istanbul ignore next*/
    showChart() {
        //check if website has any user reviews.
        if (this.props.numRatings() !== 0) {
            return <div className="centered-text wrapper">
                    <h3 /*role = "heading"*/>User Reviews:</h3>
                    <p>{this.props.averageStars()?.toFixed(2)} star average based on&nbsp;
                        {this.props.numRatings()} review{this.props.numRatings() === 1 ? null:'s'}.
                    </p>
                    <div role="grid"  aria-readonly = "true">
                        <div className="wrapper" role = "row">
                            <div className="side" role = "presentation">
                                <div role="gridcell">5 star</div>
                            </div>
                            <div className="middle" role = "presentation">
                                <div className="bar-container" role = "presentation">
                                    <div role="gridcell" alt={`${this.props.fiveStarReviewPercent} percent 5 star reviews`}
                                    className="bar-5" style={{ width: `${this.props.fiveStarReviewPercent}%`}} tabIndex = "0">{`${this.props.fiveStarReviewPercent.toFixed(2)}%`}
                                    </div>
                                </div>
                            </div>
                            <div className="side right" role = "presentation">
                                <div role="gridcell" alt={this.props.numStarValue(5) + ' 5 star reviews'} tabIndex = "0">
                                    {this.props.numStarValue(5)}
                                </div>
                            </div>
                        </div>
                        <div className="wrapper" role = "row">
                            <div className="side" role = "presentation">
                                <div role="gridcell">4 star</div>
                            </div>
                            <div className="middle" role = "presentation">
                                <div className="bar-container" role = "presentation">
                                    <div role="gridcell" alt={`${this.props.fourStarReviewPercent} percent 4 star reviews`}
                                    className="bar-4" style={{ width: `${this.props.fourStarReviewPercent}%`}} tabIndex = "0">{`${this.props.fourStarReviewPercent.toFixed(2)}%`}
                                    </div>
                                </div>
                            </div>
                            <div className="side right" role = "presentation">
                                <div role="gridcell" alt={this.props.numStarValue(4) + ' 4 star reviews'} tabIndex = "0">
                                    {this.props.numStarValue(4)}
                                </div>
                            </div>
                        </div>
                        <div className="wrapper" role = "row">
                            <div className="side" role = "presentation">
                                <div role="gridcell">3 star</div>
                            </div>
                            <div className="middle" role = "presentation">
                                <div className="bar-container" role = "presentation">
                                    <div role="gridcell" alt={`${this.props.threeStarReviewPercent} percent 3 star reviews`}
                                    className="bar-3" style={{ width: `${this.props.threeStarReviewPercent}%`}} tabIndex = "0">{`${this.props.threeStarReviewPercent.toFixed(2)}%`}
                                    </div>
                                </div>
                            </div>
                            <div className="side right" role = "presentation">
                                <div role="gridcell" alt={this.props.numStarValue(3) + ' 3 star reviews'} tabIndex = "0">
                                    {this.props.numStarValue(3)}
                                </div>
                            </div>
                        </div>
                        <div className="wrapper" role = "row">
                            <div className="side" role = "presentation">
                                <div role="gridcell">2 star</div>
                            </div>
                            <div className="middle" role = "presentation">
                                <div className="bar-container" role = "presentation">
                                    <div role="gridcell" alt={`${this.props.twoStarReviewPercent} percent 2 star reviews`}
                                    className="bar-2" style={{ width: `${this.props.twoStarReviewPercent}%`}} tabIndex = "0">{`${this.props.twoStarReviewPercent.toFixed(2)}%`}
                                    </div>
                                </div>
                            </div>
                            <div className="side right" role = "presentation">
                                <div role="gridcell" alt= {this.props.numStarValue(2) + ' 2 star reviews'} tabIndex = "0">
                                    {this.props.numStarValue(2)}
                                </div>
                            </div>
                        </div>
                        <div className="wrapper" role = "row">
                            <div className="side" role = "presentation">
                                <div role="gridcell">1 star</div>
                            </div>
                            <div className="middle" role = "presentation">
                                <div className="bar-container" role = "presentation">
                                    <div role="gridcell" alt={`${this.props.oneStarReviewPercent} percent 1 star reviews`}
                                    className="bar-1" style={{ width: `${this.props.oneStarReviewPercent}%`}} tabIndex = "0">{`${this.props.oneStarReviewPercent.toFixed(2)}%`}
                                    </div>
                                </div>
                            </div>
                            <div className="side right" role = "presentation">
                                <div role="gridcell" alt={this.props.numStarValue(1) + ' 1 star reviews'} tabIndex = "0">
                                    {this.props.numStarValue(1)}
                                </div>
                            </div>
                        </div>
                    </div>
                    <br/><br/><br/><br/><br/><br/><br/><br/>
                    {this.props.mostRecent}
                    <br/><br/>
                    {/* presentCommentBox shows most recent review for this website. */}
                    {this.props.presentCommentBox()}
                    <p className="centered-text">
                        Want to view all user reviews for this website? <br />
                        Check out our{" "}
                        <Link to="/Reviews" className="link" alt="User Reviews Page Link" onClick={() => this.props.UserReviewLink()}>
                            User Reviews
                        </Link> Page!
                        <br /><br />
                    </p>
                </div>
        }
        //then website has no user reviews.
        else {
            return <div className="centered-text wrapper">
                        <h2>{this.props.mostRecent}</h2>
                        <br/>
                        <p>Be the first to write a review for {this.props.company}!</p>
                    </div>
        }
    }

    //render method for setting up the page and displaying the given information.
    //displays a chart of the user reviews found for this specific website.
    render() {
        return(
            <div className= "footer-item">
                {this.showChart()}
            </div>
        );
    }



}

export default ReviewChart;
