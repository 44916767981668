import React from "react";
import { Link } from "react-router-dom";
import "../styles.css";

class TutorialPage extends React.Component{
  constructor(props) {
    super(props);
    this.state = {};
    this.focusRef = React.createRef();
    this.handleLink = this.handleLink.bind(this);
  }

  //componentDidMount will place the focus of the user (for screen reader purposes) on the first element on the page.
  async componentDidMount() {
    if (this.focusRef.current) {
      this.focusRef.current.focus();
    }
  }

  //handleLink sets the current values in state to values in localStorage to be accessed by other pages.
  handleLink() {
    localStorage.setItem('isAdvancedSearch', false);
    localStorage.setItem('isFullTable', false);
    localStorage.setItem('isTutorialPage', true);
  }

  //render the following content on the page (buttons).
  //render will show the tutorial videos for all pages in order to get user more familiar with our website.
  render() {
      return (
        <div className="flex-width-paragraph" style={{paddingTop:"20px"}}>
            <h2 className="centered-VersionText" autoFocus={true} ref={this.focusRef} tabIndex="0" id="focus">User Tutorial Page</h2>
            <h3 className="bold-text">This page is here to help you get familiar with our 508 portal. The buttons below will redirect
                                      the user to the proper page for the tutorial they wish to view.</h3>
            {/*div element to hold all the buttons */}
            <div className="centered-VersionText">
              <Link
                onClick={() => this.handleLink()}
                target="_blank"
                rel="noopener noreferrer"
                id="basicButton"
                tabIndex="0"
                to={{
                  pathname: "/Basic Search", prevpage: " ",
                }}
              >
                <button alt="Basic Search Tutorial Link" tabIndex="-1">
                  Basic Search
                </button>
              </Link>
              <Link
                onClick={() => this.handleLink()}
                target="_blank"
                rel="noopener noreferrer"
                id="advancedButton"
                tabIndex="0"
                to={{
                  pathname: "/Advanced Search Tutorial", prevpage: " ",
                }}
              >
                <button alt="Advanced Search Tutorial Link" tabIndex="-1">
                  Advanced Search
                </button>
              </Link>
              <Link
                onClick={() => this.handleLink()}
                target="_blank"
                rel="noopener noreferrer"
                id="websiteButton"
                tabIndex="0"
                to={{
                  pathname: "/Submit Websites", prevpage: " ",
                }}
              >
                <button alt="Submit New Website Tutorial Link" tabIndex="-1">
                  Submit Websites
                </button>
              </Link>
              <Link
                onClick={() => this.handleLink()}
                target="_blank"
                rel="noopener noreferrer"
                id="feedbackButton"
                tabIndex="0"
                to={{
                  pathname: "/Submit Feedback Tutorial", prevpage: " ",
                }}
              >
                <button alt="Submit Feedback Tutorial Link" tabIndex="-1">
                  Submit Feedback
                </button>
              </Link>
              <Link
                onClick={() => this.handleLink()}
                target="_blank"
                rel="noopener noreferrer"
                id="wcagButton"
                tabIndex="0"
                to={{
                  pathname: "/WCAG Reasons", prevpage: " ",
                }}
              >
                <button alt="WCAG Reasons Tutorial Link" tabIndex="-1">
                  WCAG Reasons
                </button>
              </Link>
              <Link
                onClick={() => this.handleLink()}
                target="_blank"
                rel="noopener noreferrer"
                id="userReviewButton"
                tabIndex="0"
                to={{
                  pathname: "/Leave a Review", prevpage: " ",
                }}
              >
                <button alt="Leave a Review Tutorial Link" tabIndex="-1">
                  Leave a Review
                </button>
              </Link>
              <Link
                onClick={() => this.handleLink()}
                target="_blank"
                rel="noopener noreferrer"
                id="pwaButton"
                tabIndex="0"
                to={{
                  pathname: "/PWA Reasons", prevpage: " ",
                }}
              >
                <button alt="PWA Reasonss Tutorial Link" tabIndex="-1">
                  PWA Reasons
                </button>
              </Link>
          </div>
        </div>
      )
  }
}

export default TutorialPage;
