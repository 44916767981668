import React from "react";
import "../styles.css";

class TutorialReview extends React.Component{
  constructor(props) {
    super(props);
    this.state = {};
    this.focusRef = React.createRef();
  }

  //componentDidMount will place the focus of the user (for screen reader purposes) on the first element on the page.
  async componentDidMount() {
    if (this.focusRef.current) {
      this.focusRef.current.focus();
    }
  }

  //render the following content on the page (buttons, videos, and text guides).
  //render will show the tutorial video for the Leave a Review along with helpful text describing that page's elements.
  render() {
      return (
        <div className="flex-width-paragraph" style={{paddingTop:"20px"}}>
            <h2 className="centered-VersionText" autoFocus={true} ref={this.focusRef} tabIndex="0" id="focus">Leave a Review Tutorial Page</h2>
            <h3 className="bold-text-video" tabIndex="0">Leave a Review Video Tutorial</h3>
            <video controls
              src="https://isenpai-act-portal-tutorials.s3.amazonaws.com/review.mp4"
              width="765"
              height="515">
              Sorry, your browser doesn't support embedded videos.
            </video>
            <p className="bold-text">Leave A Review Text Tutorial</p>
            <ul className="list-text">
              <li>The user must navigate to a results page which can be done by clicking the search buttong on the homepage.</li>
              <li>Once on the results page, the user can click on the link in the accessibility grade column for any entry.</li>
              <li>After being redirected, there is a leave a review button in the top-right that the user can click.</li>
              <li>On the left:</li>
                <ul>
                  <li>User rating (1 star through 5 stars)</li>
                  <li>Comments field that can be filled out</li>
                  <li>Captcha (to verify the user is not a bot)</li>
                  <li>The user can submit after and a pop-up will appear, (closing) will refresh the page</li>
                </ul>
              <li>On the right:</li>
                <ul>
                  <li>Bar graph of ratings from other users for the specific website (if there are any)</li>
                  <li>The most recent review with rating and comment for the website (if there is one)</li>
                  <li>A link to the user reviews page for that website displaying all of the reviews for it</li>
                </ul>
            </ul>
        </div>
      )
  }
}

export default TutorialReview;
