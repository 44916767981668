import React from "react";
import '../styles.css';
import { Link } from "react-router-dom";

class BreadCrumbTrail extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
        this.printBreadCrumbTrail = this.printBreadCrumbTrail.bind(this);
        this.printBreadCrumbTrail2 = this.printBreadCrumbTrail2.bind(this);
    }

    //prints the appropriate bread crumb trail to the header depending on where the user is and where they have come from.
    //this.props.location holds the value of where the user currently is.
    printBreadCrumbTrail() {
        const arrow = "›";
        //if user is currently in the Home page.
        if (this.props.location === "Home") {
            return (
                <p className="bread-crumb">ACT Home Page</p>
            )
        }
        //if user is currently in the Results page and came from Advanced Search page.
        else if ((this.props.location === "Results")&&(this.props.AdvancedSearchChecker === "true")) {
            return (
                <p className="green" style={{ fontSize: "22px" }}>
                    <Link to="/Home" className="bread-crumb" alt="Home Page Link" style={{margin: "5px"}}>Home {arrow} </Link>
                    <Link className="bread-crumb" alt="Advanced Search Link" to="/Advanced Search" style={{margin: "5px"}}>Advanced Search {arrow} </Link>
                    {this.props.printLocation}
                </p>
            )
        }
        //if user is currently in WCAG Reason page or PWA Reason page. They have the same bread crumb trail.
        else if ((this.props.location === "WCAG Reason")||(this.props.location === "PWA Reason")) {
            //if user is currently in WCAG or PWA Reason page and came through Results page from Advanced Search page.
            if (this.props.AdvancedSearchChecker === "true") {
                return (
                    <p className="green" style={{ fontSize: "22px" }}>
                        <Link to="/Home" className="bread-crumb" alt="Home Page Link" style={{margin: "5px"}}>Home {arrow} </Link>
                        <Link className="bread-crumb" alt="Advanced Search Link" to="/Advanced Search" style={{margin: "5px"}}>Advanced Search {arrow} </Link>
                        <Link className="bread-crumb" alt="Results Link" to="/Results" style={{margin: "5px"}}>Results {arrow} </Link>
                        {this.props.printLocation}
                    </p>
                )
            }
            //if user is currently in WCAG or PWA Reason page and came from Full Table of Results page.
            else if ((this.props.prevpage === "Full Table of Results")||(this.props.FullTableChecker === "true")) {
                return (
                    <p className="green" style={{ fontSize: "22px" }}>
                        <Link to="/Home" className="bread-crumb" alt="Home Page Link" style={{margin: "5px"}}>Home {arrow} </Link>
                        <Link className="bread-crumb" alt="Full Table Link" to="/Full Table of Results" style={{margin: "5px"}}>Full Table of Results {arrow} </Link>
                        {this.props.printLocation}
                    </p>
                )
            }
            //then user is currently in WCAG or PWA Reason page and came through Results from Home page (Basic Search).
            else {
                return (
                    <p className="green" style={{ fontSize: "22px" }}>
                        <Link to="/Home" className="bread-crumb" alt="Home Page Link" style={{margin: "5px"}}>Home {arrow} </Link>
                        <Link to="/Results" className="bread-crumb" alt="Results Link" style={{margin: "5px"}}>Results {arrow} </Link>
                        {this.props.printLocation}
                    </p>
                )
            }
        }
        //if user is currently in Thanks For Submitting page.
        else if (this.props.location === "Thanks For Submitting") {
            //if user came from Submit New Website page.
            if (this.props.isAddSite === "true") {
                return (
                    <p className="green" style={{ fontSize: "22px" }}>
                        <Link to="/Home" className="bread-crumb" alt="Home Page Link" style={{margin: "5px"}}>Home {arrow} </Link>
                        <Link className="bread-crumb" alt="Submit New Website Link" to="/Submit New Website" style={{margin: "5px"}}>Submit New Website {arrow} </Link>
                        {this.props.printLocation}
                    </p>
                )
            }
            //then user came from Submit Feedback page.
            else {
                return (
                    <p className="green" style={{ fontSize: "22px" }}>
                        <Link to="/Home" className="bread-crumb" alt="Home Page Link" style={{margin: "5px"}}>Home {arrow} </Link>
                        <Link className="bread-crumb" alt="Community Feedback Link" to="/Submit Feedback" style={{margin: "5px"}}>Submit Feedback {arrow} </Link>
                        {this.props.printLocation}
                    </p>
                )
            }
        }
        else {
            return this.printBreadCrumbTrail2();
        }
    }

    //prints the appropriate bread crumb trail to the header depending on where the user is and where they have come from.
    //this.props.location holds the value of where the user currently is.
    //does the same thing as printBreadCrumbTrail but it needed to be split due to cognitive complexity.
    printBreadCrumbTrail2() {
        const arrow = "›";
        //if user is currently in Reviews page.
        if (this.props.location === "Reviews") {
            //if user came through Results page from Advanced Search page.
            if (this.props.AdvancedSearchChecker === "true") {
                return (
                    <p className="green" style={{ fontSize: "22px" }}>
                        <Link to="/Home" className="bread-crumb" alt="Home Page Link" style={{margin: "5px"}}>Home {arrow} </Link>
                        <Link className="bread-crumb" alt="Advanced Search Link" to="/Advanced Search" style={{margin: "5px"}}>Advanced Search {arrow} </Link>
                        <Link className="bread-crumb" alt="Results Link" to="/Results" style={{margin: "5px"}}>Results {arrow} </Link>
                        <Link className="bread-crumb" alt="WCAG Reason Page Link" to="/WCAG Reason" style={{margin: "5px"}}>WCAG Reason {arrow} </Link>
                        {this.props.printLocation}
                    </p>
                )
            }
            //then user came through Results page from Home page (Basic Search).
            else {
                return (
                    <p className="green" style={{ fontSize: "22px" }}>
                        <Link to="/Home" className="bread-crumb" alt="Home Page Link" style={{margin: "5px"}}>Home {arrow} </Link>
                        <Link to="/Results" className="bread-crumb" alt="Results Link" style={{margin: "5px"}}>Results {arrow} </Link>
                        <Link to="/WCAG Reason" className="bread-crumb" alt="WCAG Reason Page Link" style={{margin: "5px"}}>WCAG Reason {arrow} </Link>
                        {this.props.printLocation}
                    </p>
                )
            }
        }
        //if user is currently in location that is one step from Home page.
        else if ((this.props.location === "Site Map")||(this.props.location === "Full Table of Results")||
                (this.props.location === "About Web Accessibility")||(this.props.location === "Advanced Search")||
                (this.props.location === "Submit New Website")||(this.props.location === "Browse Our References")||
                (this.props.location === "Check Available Resources")||(this.props.location === "Submit Feedback")||
                (this.props.location === "User Tutorial")||(this.props.location === "Release Notes")
                ||(this.props.location === "Results")||(this.props.location === "Full Table")) {
            return (
                <p className="green" style={{ fontSize: "22px" }}>
                    <Link to="/Home" className="bread-crumb" alt="Home Page Link" style={{margin: "5px", paddingTop: "5px"}}>Home {arrow} </Link>
                    {this.props.printLocation}
                </p>
            )
        }
        //if user is currently on any of the specific tutorial pages. They have the same bread crumb trail.
        else if ((this.props.location === "Basic Search")
                ||(this.props.location === "Advanced Search Tutorial")
                ||(this.props.location === "Submit Websites")
                ||(this.props.location === "Submit Feedback Tutorial")
                ||(this.props.location === "WCAG Reasons")
                ||(this.props.location === "Leave a Review")
                ||(this.props.location === "PWA Reasons")) {
            if (this.props.TutorialPageChecker === "true") {
                return (
                <p className="green" style={{ fontSize: "22px" }}>
                    <Link to="/Home" className="bread-crumb" alt="Home Page Link" style={{margin: "5px"}}>Home {arrow} </Link>
                    <Link to="/User Tutorial" className="bread-crumb" alt="User Tutorial Page Link" style={{margin: "5px"}}>User Tutorial {arrow} </Link>
                    {this.props.location}
                </p>
                )
            }
        }
        //then user is currently in location that does not exist on our website.
        return (
            <p className="green" style={{ fontSize: "22px" }}>
                <Link to="/Home" className="bread-crumb" alt="Home Page Link" style={{margin: "5px"}}>Home {arrow} </Link>
                404 - Page Not Found
            </p>
        )
    }

    //render method for setting up the page and displaying the given information.
    //displays current bread crumb trail depending on where the user is currently located in the website.
    render() {
        return (
            this.printBreadCrumbTrail()
        )
    }
}

export default BreadCrumbTrail;

