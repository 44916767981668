import React from "react";
import "../styles.css";
import { Link } from "react-router-dom";
import CommentBox from "../components/CommentBox";

class Reviews extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            reviews: [],
            length: 0,
            company: ""
        }
        this.listReviews = this.listReviews.bind(this);
        this.focusRef = React.createRef();
    }

    //componentDidMount will automatically runs when page loads.
    //grabs reviews and company from localStorage to display the user reviews of that company website.
    async componentDidMount() {
        const reviews = JSON.parse(localStorage.getItem('user_reviews'));
        const company = localStorage.getItem('company');
        const length = reviews.length;
        this.setState({
            reviews: reviews,
            length: length,
            company: company
        })
        if (this.focusRef.current) {
            this.focusRef.current.focus();
        }
    }

    //listReviews will determine if there are user reviews for given website and
    //either display those reviews using CommentBox or ask user to be the first one to leave a review.
    //will take user back to WCAG Reason page if the user wants to leave a review.
    listReviews() {
        //if there are user reviews for this given website, display them.
        if (this.state.length > 0) {
            return <div>
                <h2 tabIndex="0" style={{textAlign: "center", padding: "15px"}}>
                    {this.state.length} total reviews for {this.state.company}
                </h2>
                <p style={{textAlign: "center", paddingBottom: "15px"}}>
                    Want to write a review for {this.state.company}? Check out our{" "}
                    <Link to="/WCAG Reason" className="link" alt="Web Content Accessibility Guideline Reason link">
                        WCAG Reason
                    </Link> Page for {this.state.company}.
                </p>
                {/* CommentBox displays the reviews in an organized manner. */}
                {
                    this.state.reviews.map((row) => (
                        <CommentBox
                            feedback={row.feedback}
                            rating={row.rating}
                            date={row.date_of_submission}
                            key={row.key}
                        />
                    ))
                }
                <br/><br/>
                {/* ask if user wants to write a review, in which case, take user to WCAG Reason page. */}
                <p tabIndex="0" style={{textAlign: "center", paddingBottom: "15px"}}>
                    Want to write a review for {this.state.company}? Check out our{" "}
                    <Link to="/WCAG Reason" className="link" alt="Web Content Accessibility Guideline Reason link">
                        WCAG Reason
                    </Link> Page for {this.state.company}.
                </p>
            </div>
        }
        //since there are no reivews for website, ask user to be the first to review and take them back to WCAG Reason page.
        else {
            return <div>
                <h2 style={{textAlign: "center", padding: "15px"}}>
                    There are currently no reviews for {this.state.company}
                </h2>
                <p style={{textAlign: "center", paddingBottom: "15px"}}>
                    Want to write a review for {this.state.company}? Check out our{" "}
                    <Link to="/WCAG Reason" className="link" alt="Web Content Accessibility Guideline Reason link">
                        WCAG Reason
                    </Link> Page for {this.state.company}.
                </p>
            </div>
        }
    }

    //render method that calls listReviews to either show reviews or ask user to be the first to review.
    //easier to place this code in a helper function.
    render() {
        return (
            <div autoFocus={true} tabIndex="0" ref={this.focusRef} id="focus">
                {this.listReviews()}
                <br />
            </div>
        );
    }
}

export default Reviews;
