import React from "react"
import '../styles.css';
import {Link} from "react-router-dom";

//grabs the root folder 'src' for the absolute path to images
const siteName = window.location.origin;
//Footer creates a footer on every webpage in the website.
//holds link to iSenpai's various social media pages, Sitemap, and iSenpai's website.
class Footer extends React.Component {

    render() {
        const media = window.matchMedia( "(max-width: 700px)" );
        var version = null;
        // if on mobile, show version in footer. otherwise, version will be in header.
        if (media.matches) {
            version = <Link to="/Release Notes">
                            <p className="heading-items" alt="Version link">Version '1.0.0'</p>
                        </Link>
        }
        return (
            <footer>
                {/* links to iSenpai's various social media pages in left side of footer. */}
                <div className="footer-item">
                    <p>Check us out on Social Media!</p>
                    <ul className = "horizontal-ul">
                    <li className = "item"><a href="https://www.facebook.com/iSenpaiTECH/" target="_blank" rel="noopener noreferrer">
                        <img src={`${siteName}/images/facebook.png`} width="35" height="35" alt="Facebook page"/>
                    </a></li>
                    <li className = "item"><a href="https://www.instagram.com/the_isenpai/" target="_blank" rel="noopener noreferrer">
                        <img src={`${siteName}/images/instagram.png`} width="35" height="35" alt="Instagram page"/>
                    </a></li>
                    <li className = "item"><a href="https://www.linkedin.com/company/isenpai/" target="_blank" rel="noopener noreferrer">
                        <img src={`${siteName}/images/linkedin.png`} width="35" height="35" alt="Linkedin page"/>
                    </a></li>
                    <li className = "item"><a href="https://twitter.com/the_isenpai" target="_blank" rel="noopener noreferrer">
                        <img src={`${siteName}/images/twitter.png`} width="35" height="35" alt="Twitter page"/>
                    </a></li>
                    {/* https://www.youtube.com/channel/UCTU5ut3kgOn4p1AmxJfLVjQ?view_as=subscriber */}
                    {/* <li className = "item"><a href="https://www.youtube.com" target="_blank" rel="noopener noreferrer">
                        <img src={`${siteName}/images/youtube.png`} width="35" height="35" alt="YouTube page"/>
                    </a></li> */}
                    </ul>
                </div>
                {/* link to Sitemap in center of footer. */}
                <div className="footer-item">
                    <Link to="/Site Map">
                        <p style={{ fontSize: "24px"}} className="green" alt="Site Map link">Site Map</p>
                    </Link>
                    <br/><br/>
                    {/* show version in footer if on mobile */}
                    {version}
                </div>
                {/* link to iSenpai's website in right side of footer. */}
                <div className="footer-item">
                    <a href="https://isenpai.com">
                        <p style={{ fontSize: "24px"}} className="green" alt="iSenpai company link">© 2021 iSenpai</p>
                    </a>
                </div>
            </footer>
        );
    }
}

export default Footer;
