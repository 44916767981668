import React from "react";
import "../styles.css";

class TutorialAdvanced extends React.Component{
  constructor(props) {
    super(props);
    this.state = {};
    this.focusRef = React.createRef();
  }

  //componentDidMount will place the focus of the user (for screen reader purposes) on the first element on the page.
  async componentDidMount() {
    if (this.focusRef.current) {
      this.focusRef.current.focus();
    }
  }

  //render the following content on the page (buttons, videos, and text guides).
  //render will show the tutorial video for the Advanced Search along with helpful text describing that page's elements.
  render() {
      return (
        <div className="flex-width-paragraph" style={{paddingTop:"20px"}}>
            <h2 className="centered-VersionText" autoFocus={true} ref={this.focusRef} tabIndex="0" id="focus">Advanced Search Tutorial Page</h2>
            <h3 className="bold-text-video" tabIndex="0">Advanced Search Video Tutorial</h3>
            <video controls
              src="https://isenpai-act-portal-tutorials.s3.amazonaws.com/advanced.mp4"
              width="765"
              height="515">
              Sorry, your browser doesn't support embedded videos.
            </video>
            <p className="bold-text">Advanced Search Text Tutorial</p>
            <ul className="list-text">
              <li>To get to the advanced search tool, the user must go to the 508 portal homepage.</li>
              <li>The link to the advanced search tool is underneath the basic search form.</li>
              <li>There are multiple options to filter the search results before hitting the search button.</li>
              <li>The user can modify their search with these choices:</li>
                <ul>
                  <li>Search term (optional)</li>
                  <li>Category filter (healthcare, food, government, etc.) (optional)</li>
                  <li>Zip code (optional)</li>
                  <li>Minimum WCAG grade (A through F)</li>
                  <li>Date of last update (any, and 7 days through 180 days)</li>
                  <li>Sort by (relevance, website, category, URL, accessibility grade, PWA grade, and date tested)</li>
                </ul>
              <li>Once the user is done, they can click the search button and will be redirected to see the results.</li>
            </ul>
        </div>
      )
  }
}

export default TutorialAdvanced;
