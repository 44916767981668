import React from "react";
import "../styles.css";
import ReasonText from "../components/ReasonText";
import Refactor from "../components/Refactor";

class Reason extends Refactor {
  constructor(props) {
    super(props);
    this.state = {
      results: [],
      grade_letter: "",
      grade_number: 0,
      url: "",
      company: "",
      PWA_score: 0,
      date_of_test: "",
      sort_by: "title",
    };
    this.componentDidMount = this.componentDidMount.bind(this);
    this.sortByTitle = this.sortByTitle.bind(this);
  }

  //componentDidMount will automatically run when page loads.
  //grabs many fields from localStorage to use in displaying results.
  //sorts and displays results of PWA grade.
  async componentDidMount() {

    //grab fields from localStorage.
    const company = localStorage.getItem('company');
    const url = localStorage.getItem('url');
    const gradeletter = localStorage.getItem('grade_letter');
    const gradenumber = localStorage.getItem('grade_number');
    const dateoftest = localStorage.getItem('date_of_test');
    const PWAscore = localStorage.getItem('PWA_score');
    const PWAresults = JSON.parse(localStorage.getItem('PWA_results'));

    //map the val array to each result's source info (easier reference and display).
    const successCriteriaData = Object.keys(PWAresults).map((key) => (PWAresults[key]));
    //map the val array to add a key to each result.
    const values = Object.keys(PWAresults);
    for(let i = 0; i < successCriteriaData.length; i++) {
      //set the key field for each result in list to its id.
      successCriteriaData[i].key = values[i];
    }

    //set the state of page with these values for displaying the information and load page by first sorting by title.
    this.setState({
      grade_letter: gradeletter,
      grade_number: gradenumber,
      url: url,
      company: company,
      PWA_score: PWAscore,
      date_of_test: dateoftest,
      results: successCriteriaData
    }, () => this.sortByTitle());
  }

  //render method for setting up the page and displaying the given information.
  //the use of components makes this easier and more readable (ReasonText).
  render() {
    return (
      <div>
        {/* call ReasonText to display a table of criteria that given website failed.
            provides user with information regarding how the score was given.
        */}
        <ReasonText
          pwa={true}
          wcag={false}
          score={this.state.PWA_score}
          grade_letter={this.state.grade_letter}
          date_of_test={this.state.date_of_test}
          url={this.state.url}
          company={this.state.company}
          results={this.state.results}
          sortByTitle={this.sortByTitle}
        />
        <br />
      </div>
    );
  }
}

export default Reason;
