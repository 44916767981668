import React from "react";
import "../styles.css";
import ReactDOM from "react-dom";

class PopUp extends React.Component {
  constructor(props) {
    super(props);
    this.trapTabKey = this.trapTabKey.bind(this);
    this.text = React.createRef();
    this.button = React.createRef();
  }

  //componentDidMount will automatically run when page loads.
  //adds event listener that will trap the tab key into specific spaces (keep user inside pop up).
  componentDidMount() {
    this.text.current.focus();
    ReactDOM.findDOMNode(this).addEventListener('keydown', this.trapTabKey);
  }

  //trapTabKey will take in e as the key that the user has pressed.
  //must keep user inside pop up and makes the user exit the pop up from within the pop up.
  /*istanbul ignore next*/
  trapTabKey = (e) => {
    //set variables to check if user on text or button.
    var firstTabStop = this.text;
    var lastTabStop = this.button;
    //check if the Tab key was pressed.
    if (e.code === "Tab") {
      //check if the Tab key was pressed with the Shift key.
      if (e.shiftKey) {
        //check if the user is on the firstTabStop (text of the pop up).
        if (document.activeElement === firstTabStop.current) {
          //prevent default action and keep user in pop up.
          e.preventDefault();
          lastTabStop.current.focus();
        }

      //then only Tab key was pressed.
      } else {
        //check if the user is on the lastTabStop (button of the pop up).
        if (document.activeElement === lastTabStop.current) {
          //prevent default action and keep user in pop up.
          e.preventDefault();
          firstTabStop.current.focus();
        }
      }
    }

    //check if the Esc key was pressed.
    if (e.keyCode === 27) {
      //leave pop up.
      this.props.toggle();
    }
  }

  //render method for setting up the page and displaying the given information.
  //show Pop Up with message as to why pop up is showing and "close" button for closing the pop up.
  render() {
    return (
      <div className="popup">
        <div className="popup_content">
          <h3 tabIndex="0" ref={this.text}>{this.props.message}</h3>
          <button className="close" onClick={this.props.toggle} tabIndex="0" ref={this.button}>
            Close
          </button>
        </div>
      </div>
    );
  }
}

export default PopUp;
