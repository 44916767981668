//This takes in a search term and location, and returns an array of Elasticsearch ids from businesses pulled from foursquare api that match our DB.

import * as fourSquare from './fourSquare'
const axios = require('axios');


// comment out either one depending on if running locally or not
const proxyURL = 'https://508-portal-es.isenpailabs.com'    // production
// const proxyURL = 'http://127.0.0.1:5500';                 // local

// object to hold the foursquare client id and secret
// const foursquare = {
//   client_id: process.env.REACT_APP_FOURSQUARE_CLIENT_ID,
//   client_secret: process.env.REACT_APP_FOURSQUARE_CLIENT_SECRET,
// };

//Takes in zip and query and runs all methods necessary to output array of elasticsearch ID results matched with our database.
export async function fourSquareMatchedResults(geopoint, query){
  return localSearchResults(geopoint, query).then(function(response){
   var nameArray =  response;
   return fourSquare.dbSearch(nameArray);
  });
}

//Checks if user serch query appears in foursquare category list. Returns array of category IDs for any matches.
export function checkIfCategory(searchQuery){
  var cleanQuery = fourSquare.firstLettersCap(searchQuery);
  var categoryArr = fourSquare.getAllCategories();
  var categoryIdArr = [];
  for (var i = 0; i < categoryArr.length; i++) {
    if (categoryArr[i].match(cleanQuery)) {
      categoryIdArr.push(categoryArr[i+1]);
    }
  }
  return categoryIdArr;
}

//test if user entered function is Category for foursquare.
//If so return category results if not forward users query as the foursquare query.
//Return results as array of Business names.
export async function localSearchResults (geopoint , searchQuery) {
  const categoryIdArr = fourSquare.checkIfCategory(searchQuery);
  if (categoryIdArr === undefined || categoryIdArr.length === 0) {
    const searchTerm = await fourSquare.searchTermResult(geopoint, searchQuery);
    await searchTerm;
    return searchTerm;
  }
  else {
    const categoryTerm = await fourSquare.categoryResult(geopoint, categoryIdArr);
    await categoryTerm;
    return categoryTerm;
  }
}

//If category not matched call API to search user's input
export function searchTermResult(geopoint, searchQuery){

  // send request to proxy at /fs/searchTerm
  const url = proxyURL + '/fs/searchTerm';
  return axios.post(url , {
    data: {
      ll: geopoint,//searching by coordinates as it gives better results than zip
      searchQuery: searchQuery
    }
  })
  .then(function (response) {
    var resultArr =[];
    for (var venues of response.data.response.venues){
      const nameArr = [];
      nameArr.push(venues.name);
      nameArr.push(venues.id);
      resultArr.push(nameArr);
    }
    return resultArr;
  })
  .catch(function (error) {
    console.log(error.toString().replace(process.env.REACT_APP_FOURSQUARE_CLIENT_ID,'client_ID').replace(process.env.REACT_APP_FOURSQUARE_CLIENT_SECRET, 'client_Secret'));
  });
}

//If category matched call API with foursquare generate results list from category Ids in array.
export function categoryResult(geopoint, categoryId) {
  const url = proxyURL + '/fs/categoryResult';
  var convertedArray = Array.prototype.slice.call(categoryId);
  console.log(convertedArray);
  return axios.post(url, {
    data: {
      ll: geopoint,//currently set to search bounds of zip code. radius can be inserted to cast a wider net.
      radius:10000,
      categoryId: convertedArray.join(",")
    }
  })
  .then(function (response) {
    var resultArr =[];
    for (var venues of response.data.response.venues){
      const nameArr = [];
      nameArr.push(venues.name);
      nameArr.push(venues.id);
      resultArr.push(nameArr);
    }
    console.log(resultArr);
    return resultArr;
  })
  .catch(function (error) {
    console.log(error.toString().replace(process.env.REACT_APP_FOURSQUARE_CLIENT_ID,'client_ID').replace(process.env.REACT_APP_FOURSQUARE_CLIENT_SECRET, 'client_Secret'));
  });
}

//pushCategory is a helper function for getAllCategories.
//pushCategory will be called and given a categories array to iterate through, a nameArr to push specific values to, and a
//counter to make sure pushCategory doesn't go too deep into the nested loops.
export function pushCategory(categories,nameArr,counter) {
  // as long as categories isn't undefined.
  if (categories !== undefined) {
    //using for-of loop, iterate through categories.
    for (var category of categories) {
      nameArr.push(category.name);
      nameArr.push(category.id);
      /*istanbul ignore next*/
      //if element in categories includes a level down (another dimension to the array), iterate through that level as well.
      if(category.hasOwnProperty('categories') && counter <= 3) {
        //using recursion, find and push the names and ids of the next level down.
        nameArr = pushCategory(categories.categories,nameArr,counter+1);
      }
    }
  }
  return nameArr;
}

//Call API to retrieve all foursquare categories and return an array of categories and categoryIds
export async function getAllCategories(){
  const url = proxyURL + '/fs/getAllCategories';
  var nameArr = [];
  const response = await axios.get(url);
  console.log(response);
  return pushCategory(response.data.response.categories,nameArr,1);
}

//All words in category results from foursquare start with a capital letter. This takes in a string, and sets the firs letter of each work to upper case.
export function firstLettersCap(str) {
  var splitStr = str.toLowerCase().split(' ');
  for (var i = 0; i < splitStr.length; i++) {
      // Assign it back to the array
      splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
  }
  // Directly return the joined string
  return splitStr.join(' ');
}

//Takes in Array containing business names and finds them in our database. Outputs log of companies not matched, and returns array of matched results elastic search IDs.
export async function dbSearch(nameArr) {
  if (typeof nameArr === 'undefined'){
    return [];
  }
  var foundInDb = [];
  var notInDb = [];
  for (var name of nameArr) {
    let value = name[0].replace(/\//g, '-');
    value = value.replace(/[{()}]/g, '');
    value = value.replace(/[[\]']+/g,'');
    const data = {
      term: value,
    };
    const response = await axios.post(`${proxyURL}/localAPIMatches`, {
      data: data,
    });
    //Build array for items found in DB and Items to output to log of unfound sites.
    if (response.data.length === 0) {
      notInDb.push(name);
    }
    else {
      if (foundInDb.indexOf(response.data[0]._id) === -1) {
        foundInDb.push(response.data[0]._id);
      }
    }
  }
  fourSquare.unmatchedToDB(notInDb);
  return foundInDb;
}



//Write data to umatched_local_sites if data for entity not already present.
export async function unmatchedToDB(unmatchedResultArr){
  var today = new Date();
  today = `${today.getFullYear()}-${(today.getMonth()+1)}-${today.getDate()}`;
  const limitReached = await fourSquare.DailyLimitReached(today);
  if (!limitReached){
  for (var unmatched of unmatchedResultArr){
    let value = unmatched[0].replace(/\//g, '-');
    value = value.replace(/[{()}]/g, '');
    value = value.replace(/[[\]']+/g,'');
    const data = {
      term: value,
    };
    const response = await axios.post(`${proxyURL}/localUnmatchedSearch`, {
      data: data,
    });
    if (response.data.length === 0){
      const url = await fourSquare.getUrl(unmatched[1]);
      //set audited to true if no URL is returned.
      let audited = false;
      if (url === "" || url === null){
        audited = true;
      }
      const body = {
        "name": unmatched[0],
        "url": url,
        "audited": audited,
        "date": today
      }
      await axios.post(`${proxyURL}/sendUnmatchedLocal`, {
        data: body
      });
    }
  }
}
}

//calls to foursquare premium API to return URL
export function getUrl(foursquareID){
const url = proxyURL + '/fs/getUrl';
  return axios.post(url, {
    data: {
      fs_id: foursquareID
    }
  })
  .then(function (response) {
      return response.data.response.venue.url;
  })
  .catch(function (error) {
    console.log(error.toString().replace(process.env.REACT_APP_FOURSQUARE_CLIENT_ID,'client_ID').replace(process.env.REACT_APP_FOURSQUARE_CLIENT_SECRET, 'client_Secret'));
  });
}

export async function DailyLimitReached(today){
  const data = {date: today};
  const response = await axios.post(`${proxyURL}/unmatchedByDate`, {
    data: data,
  }).catch(function(error) {
    console.log(error.toString().replace(process.env.REACT_APP_FOURSQUARE_CLIENT_ID,'client_ID').replace(process.env.REACT_APP_FOURSQUARE_CLIENT_SECRET, 'client_Secret'));
  });
  if (response.data.body.hits.total.value > 450){
    return true;
  }
  if (response.data.body.hits.total.value <= 450){
    return false;
  }
  return false;
}

