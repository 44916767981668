//this information was scraped from WCAG's website using a file called wcagscrape.py,
//which is located in the scripts directory of the repository
const WCAGData = {
  "1.1.1": {
    number: "1.1.1",
    name: "Non-text Content",
    id_html: "x1-1-1-non-text-content",
    key: "8b4ae529-aaca-44a5-9fe7-61fd98827fe6",
  },
  "1.2.1": {
    number: "1.2.1",
    name: "Audio-only and Video-only (Prerecorded)",
    id_html: "x1-2-1-audio-only-and-video-only-prerecorded",
    key: "40031170-2358-4338-85eb-ba3f564d2cad",
  },
  "1.2.2": {
    number: "1.2.2",
    name: "Captions (Prerecorded)",
    id_html: "x1-2-2-captions-prerecorded",
    key: "d8490036-7207-4fa3-bc08-34017dab4c6e",
  },
  "1.2.3": {
    number: "1.2.3",
    name: "Audio Description or Media Alternative (Prerecorded)",
    id_html: "x1-2-3-audio-description-or-media-alternative-prerecorded",
    key: "095203b3-afc4-45c5-9813-0041d5f44392",
  },
  "1.2.4": {
    number: "1.2.4",
    name: "Captions (Live)",
    id_html: "x1-2-4-captions-live",
    key: "04a704be-7744-4838-a5fd-b3b12461fd6c",
  },
  "1.2.5": {
    number: "1.2.5",
    name: "Audio Description (Prerecorded)",
    id_html: "x1-2-5-audio-description-prerecorded",
    key: "4ed8c8f0-f1af-4060-aa48-20f242735636",
  },
  "1.2.6": {
    number: "1.2.6",
    name: "Sign Language (Prerecorded)",
    id_html: "x1-2-6-sign-language-prerecorded",
    key: "6cc73315-85bd-465d-832a-7f9bf278738b",
  },
  "1.2.7": {
    number: "1.2.7",
    name: "Extended Audio Description (Prerecorded)",
    id_html: "x1-2-7-extended-audio-description-prerecorded",
    key: "1be9358e-3f7f-4a9e-907e-11117676c8de",
  },
  "1.2.8": {
    number: "1.2.8",
    name: "Media Alternative (Prerecorded)",
    id_html: "x1-2-8-media-alternative-prerecorded",
    key: "a23bc3be-6cfe-466c-a61c-82a0055042b9",
  },
  "1.2.9": {
    number: "1.2.9",
    name: "Audio-only (Live)",
    id_html: "x1-2-9-audio-only-live",
    key: "a6585aa3-b24a-469d-a46f-ecb30ae6b670",
  },
  "1.3.1": {
    number: "1.3.1",
    name: "Info and Relationships",
    id_html: "x1-3-1-info-and-relationships",
    key: "17e675cb-80cd-4944-bf1a-cfd729286b5b",
  },
  "1.3.2": {
    number: "1.3.2",
    name: "Meaningful Sequence",
    id_html: "x1-3-2-meaningful-sequence",
    key: "90ebed0d-31ce-48fa-bd65-f3b7b675206f",
  },
  "1.3.3": {
    number: "1.3.3",
    name: "Sensory Characteristics",
    id_html: "x1-3-3-sensory-characteristics",
    key: "133e2f44-a6b0-4251-9c3d-b4fbe0b41c81",
  },
  "1.3.4": {
    number: "1.3.4",
    name: "Orientation",
    id_html: "x1-3-4-orientation",
    key: "eaf8ebf0-fd62-45f5-b574-e665ba3fc1f3",
  },
  "1.3.5": {
    number: "1.3.5",
    name: "Identify Input Purpose",
    id_html: "x1-3-5-identify-input-purpose",
    key: "e77998c8-f643-4d52-90d9-83fd67b37651",
  },
  "1.3.6": {
    number: "1.3.6",
    name: "Identify Purpose",
    id_html: "x1-3-6-identify-purpose",
    key: "f87dd51b-a28f-41d0-9e59-9246c869645c",
  },
  "1.4.1": {
    number: "1.4.1",
    name: "Use of Color",
    id_html: "x1-4-1-use-of-color",
    key: "4a95f49d-562c-403a-968b-0ae23082c91b",
  },
  "1.4.2": {
    number: "1.4.2",
    name: "Audio Control",
    id_html: "x1-4-2-audio-control",
    key: "d94c5fff-f32d-4d34-b3d4-05a0e40a7806",
  },
  "1.4.3": {
    number: "1.4.3",
    name: "Contrast (Minimum)",
    id_html: "x1-4-3-contrast-minimum",
    key: "22322c16-2ffa-4032-8b95-6bef6c529316",
  },
  "1.4.4": {
    number: "1.4.4",
    name: "Resize text",
    id_html: "x1-4-4-resize-text",
    key: "ac0ae20a-ef57-4692-a6bb-7c55efd2fc33",
  },
  "1.4.5": {
    number: "1.4.5",
    name: "Images of Text",
    id_html: "x1-4-5-images-of-text",
    key: "13871d69-de6c-4bad-b629-74b11b5ae6a2",
  },
  "1.4.6": {
    number: "1.4.6",
    name: "Contrast (Enhanced)",
    id_html: "x1-4-6-contrast-enhanced",
    key: "268ef7cb-7be4-4802-a86b-9f0d78baa570",
  },
  "1.4.7": {
    number: "1.4.7",
    name: "Low or No Background Audio",
    id_html: "x1-4-7-low-or-no-background-audio",
    key: "196b48f0-7674-4ee2-b9f6-108d5fa395d5",
  },
  "1.4.8": {
    number: "1.4.8",
    name: "Visual Presentation",
    id_html: "x1-4-8-visual-presentation",
    key: "525c53fb-7216-40b5-a0b1-c3158d8b10bd",
  },
  "1.4.9": {
    number: "1.4.9",
    name: "Images of Text (No Exception)",
    id_html: "x1-4-9-images-of-text-no-exception",
    key: "095b4703-9a3f-4289-bb3e-50d8f8e925e0",
  },
  "1.4.10": {
    number: "1.4.10",
    name: "Reflow",
    id_html: "x1-4-10-reflow",
    key: "b08c64ca-c482-4849-9ba9-d482b1f41ba0",
  },
  "1.4.11": {
    number: "1.4.11",
    name: "Non-text Contrast",
    id_html: "x1-4-11-non-text-contrast",
    key: "e121aaf6-eea7-401f-912e-4dd202851302",
  },
  "1.4.12": {
    number: "1.4.12",
    name: "Text Spacing",
    id_html: "x1-4-12-text-spacing",
    key: "f237809e-1ef8-4e00-b73d-823b80f6e445",
  },
  "1.4.13": {
    number: "1.4.13",
    name: "Content on Hover or Focus",
    id_html: "x1-4-13-content-on-hover-or-focus",
    key: "3bdf64be-cb6e-4f3a-b463-1dbc2d7851b2",
  },
  "2.1.1": {
    number: "2.1.1",
    name: "Keyboard",
    id_html: "x2-1-1-keyboard",
    key: "3fb4000e-e6e2-48aa-a02c-8f40bc623b58",
  },
  "2.1.2": {
    number: "2.1.2",
    name: "No Keyboard Trap",
    id_html: "x2-1-2-no-keyboard-trap",
    key: "0fdfc344-e181-45e2-b7b7-8d42e31a7a92",
  },
  "2.1.3": {
    number: "2.1.3",
    name: "Keyboard (No Exception)",
    id_html: "x2-1-3-keyboard-no-exception",
    key: "4b2dbe53-ad06-4ba5-ba9b-f406d099315f",
  },
  "2.1.4": {
    number: "2.1.4",
    name: "Character Key Shortcuts",
    id_html: "x2-1-4-character-key-shortcuts",
    key: "f4858431-e288-4302-ab99-34d4c8469da1",
  },
  "2.2.1": {
    number: "2.2.1",
    name: "Timing Adjustable",
    id_html: "x2-2-1-timing-adjustable",
    key: "1a8ba9bd-e511-441f-a166-e63ca0756951",
  },
  "2.2.2": {
    number: "2.2.2",
    name: "Pause, Stop, Hide",
    id_html: "x2-2-2-pause-stop-hide",
    key: "0030c5dd-d90d-40fb-9d41-482f7eda6ce8",
  },
  "2.2.3": {
    number: "2.2.3",
    name: "No Timing",
    id_html: "x2-2-3-no-timing",
    key: "e9bc7751-60a6-459e-bf27-27afc8f86ec3",
  },
  "2.2.4": {
    number: "2.2.4",
    name: "Interruptions",
    id_html: "x2-2-4-interruptions",
    key: "13e3dc23-d58e-4689-8679-ec96d2ae2429",
  },
  "2.2.5": {
    number: "2.2.5",
    name: "Re-authenticating",
    id_html: "x2-2-5-re-authenticating",
    key: "73f69e56-e28a-44e9-83cb-92623713d5b8",
  },
  "2.2.6": {
    number: "2.2.6",
    name: "Timeouts",
    id_html: "x2-2-6-timeouts",
    key: "60e110ec-f877-48cb-bac5-ce371d336fe5",
  },
  "2.3.1": {
    number: "2.3.1",
    name: "Three Flashes or Below Threshold",
    id_html: "x2-3-1-three-flashes-or-below-threshold",
    key: "a2e9d44b-4704-47c0-bbd3-456cbedff940",
  },
  "2.3.2": {
    number: "2.3.2",
    name: "Three Flashes",
    id_html: "x2-3-2-three-flashes",
    key: "37644055-d2d4-4d15-bfe5-f14f18c6aaf4",
  },
  "2.3.3": {
    number: "2.3.3",
    name: "Animation from Interactions",
    id_html: "x2-3-3-animation-from-interactions",
    key: "968cb317-f178-4feb-9681-84215b750917",
  },
  "2.4.1": {
    number: "2.4.1",
    name: "Bypass Blocks",
    id_html: "x2-4-1-bypass-blocks",
    key: "5fd65dcc-28e1-4acd-b0c7-d1150bc20c42",
  },
  "2.4.2": {
    number: "2.4.2",
    name: "Page Titled",
    id_html: "x2-4-2-page-titled",
    key: "f05d11f9-174f-4534-874b-3445ac9a39f5",
  },
  "2.4.3": {
    number: "2.4.3",
    name: "Focus Order",
    id_html: "x2-4-3-focus-order",
    key: "39876aa6-cf62-4c29-8210-275fff06c9d3",
  },
  "2.4.4": {
    number: "2.4.4",
    name: "Link Purpose (In Context)",
    id_html: "x2-4-4-link-purpose-in-context",
    key: "ca5df95f-e13e-4e0d-b4ad-c92296b1ebd7",
  },
  "2.4.5": {
    number: "2.4.5",
    name: "Multiple Ways",
    id_html: "x2-4-5-multiple-ways",
    key: "4b67c784-23d5-4b6c-ad3f-5ff091ee9016",
  },
  "2.4.6": {
    number: "2.4.6",
    name: "Headings and Labels",
    id_html: "x2-4-6-headings-and-labels",
    key: "9c60f5ec-2e21-47d9-ad62-b45c8fe6dae0",
  },
  "2.4.7": {
    number: "2.4.7",
    name: "Focus Visible",
    id_html: "x2-4-7-focus-visible",
    key: "9df6b1e3-adf3-49db-817b-e8bbea244490",
  },
  "2.4.8": {
    number: "2.4.8",
    name: "Location",
    id_html: "x2-4-8-location",
    key: "85b1d541-378b-4bc0-8b17-d8af5aa8136c",
  },
  "2.4.9": {
    number: "2.4.9",
    name: "Link Purpose (Link Only)",
    id_html: "x2-4-9-link-purpose-link-only",
    key: "c4163338-3244-4c90-b7da-0fb15d3f7201",
  },
  "2.4.10": {
    number: "2.4.10",
    name: "Section Headings",
    id_html: "x2-4-10-section-headings",
    key: "d938719c-d197-4ea6-b6c4-4906da8e9e7f",
  },
  "2.5.1": {
    number: "2.5.1",
    name: "Pointer Gestures",
    id_html: "x2-5-1-pointer-gestures",
    key: "bc1cfe98-e60e-4b6b-b1f4-2ae517203902",
  },
  "2.5.2": {
    number: "2.5.2",
    name: "Pointer Cancellation",
    id_html: "x2-5-2-pointer-cancellation",
    key: "b4350eca-5875-4e8b-a29c-4ae0cc009c2c",
  },
  "2.5.3": {
    number: "2.5.3",
    name: "Label in Name",
    id_html: "x2-5-3-label-in-name",
    key: "98f093c3-1f43-4ea0-9001-8bd12eff3224",
  },
  "2.5.4": {
    number: "2.5.4",
    name: "Motion Actuation",
    id_html: "x2-5-4-motion-actuation",
    key: "aa361608-a98e-4260-ba53-38b34f53a833",
  },
  "2.5.5": {
    number: "2.5.5",
    name: "Target Size",
    id_html: "x2-5-5-target-size",
    key: "b8f55db0-40f1-4858-a265-c053d975ed62",
  },
  "2.5.6": {
    number: "2.5.6",
    name: "Concurrent Input Mechanisms",
    id_html: "x2-5-6-concurrent-input-mechanisms",
    key: "c4c88c67-f09b-4f79-8d71-240b1e2895cf",
  },
  "3.1.1": {
    number: "3.1.1",
    name: "Language of Page",
    id_html: "x3-1-1-language-of-page",
    key: "f0abde7d-fd42-4695-8827-c399117e8b6d",
  },
  "3.1.2": {
    number: "3.1.2",
    name: "Language of Parts",
    id_html: "x3-1-2-language-of-parts",
    key: "ca3a2750-64a7-42cd-93f4-597581eab056",
  },
  "3.1.3": {
    number: "3.1.3",
    name: "Unusual Words",
    id_html: "x3-1-3-unusual-words",
    key: "8cf57c5a-12df-419d-97d2-413cff9f07e5",
  },
  "3.1.4": {
    number: "3.1.4",
    name: "Abbreviations",
    id_html: "x3-1-4-abbreviations",
    key: "ec6a15a8-39c8-408b-85ae-70888a56aa42",
  },
  "3.1.5": {
    number: "3.1.5",
    name: "Reading Level",
    id_html: "x3-1-5-reading-level",
    key: "a5efba8e-899c-47be-96a0-c0c55cbd9241",
  },
  "3.1.6": {
    number: "3.1.6",
    name: "Pronunciation",
    id_html: "x3-1-6-pronunciation",
    key: "f695e5fa-6084-497e-b551-e9dc4077cb5a",
  },
  "3.2.1": {
    number: "3.2.1",
    name: "On Focus",
    id_html: "x3-2-1-on-focus",
    key: "c4cb3160-f904-4802-b3e6-28efa550d3d8",
  },
  "3.2.2": {
    number: "3.2.2",
    name: "On Input",
    id_html: "x3-2-2-on-input",
    key: "0294cf9a-490f-43a1-a3ec-d4dc142e096f",
  },
  "3.2.3": {
    number: "3.2.3",
    name: "Consistent Navigation",
    id_html: "x3-2-3-consistent-navigation",
    key: "9b3fb4f6-0b4b-4edf-bf4e-2a1b65e43b17",
  },
  "3.2.4": {
    number: "3.2.4",
    name: "Consistent Identification",
    id_html: "x3-2-4-consistent-identification",
    key: "1efc71b7-cb95-4bd1-97ef-8be625365dd0",
  },
  "3.2.5": {
    number: "3.2.5",
    name: "Change on Request",
    id_html: "x3-2-5-change-on-request",
    key: "4440713d-742e-407d-8d28-88a9d9f5ec51",
  },
  "3.3.1": {
    number: "3.3.1",
    name: "Error Identification",
    id_html: "x3-3-1-error-identification",
    key: "562549c5-f3e7-4f70-aec1-f8d5792a8705",
  },
  "3.3.2": {
    number: "3.3.2",
    name: "Labels or Instructions",
    id_html: "x3-3-2-labels-or-instructions",
    key: "a720fb16-7630-45ea-b02b-fc841e029ad8",
  },
  "3.3.3": {
    number: "3.3.3",
    name: "Error Suggestion",
    id_html: "x3-3-3-error-suggestion",
    key: "15f204e0-ae38-476d-acbb-267e6f042fd1",
  },
  "3.3.4": {
    number: "3.3.4",
    name: "Error Prevention (Legal, Financial, Data)",
    id_html: "x3-3-4-error-prevention-legal-financial-data",
    key: "4ade6ca1-0c69-4eb3-882a-c1e49ce98874",
  },
  "3.3.5": {
    number: "3.3.5",
    name: "Help",
    id_html: "x3-3-5-help",
    key: "1e4429e5-9779-4263-bcc6-f1034c83e5f1",
  },
  "3.3.6": {
    number: "3.3.6",
    name: "Error Prevention (All)",
    id_html: "x3-3-6-error-prevention-all",
    key: "9c35a01e-d2dd-4b52-9357-66e98c1a2f5a",
  },
  "4.1.1": {
    number: "4.1.1",
    name: "Parsing",
    id_html: "x4-1-1-parsing",
    key: "8c770b69-c50e-41cd-ab06-8fbe665d4d97",
  },
  "4.1.2": {
    number: "4.1.2",
    name: "Name, Role, Value",
    id_html: "x4-1-2-name-role-value",
    key: "63ce26c2-2f1f-443b-b622-03d7a0a2fad9",
  },
  "4.1.3": {
    number: "4.1.3",
    name: "Status Messages",
    id_html: "x4-1-3-status-messages",
    key: "dc5f31a5-9ebc-4a93-9c13-30e6eb87e17c",
  },
};

export default WCAGData;
