import React from 'react';
import { Link } from 'react-router-dom';
import "../../src/styles.css";


class NotFound extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  //render method for setting up the page and displaying the given information.
  //show "404 - Page Not Found" error and link back to Home page.
  render() {
    //based on screen width (i.e. mobile vs desktop), determine when to autofocus.
    const shouldAutoFocus = window.innerWidth<600?false:true;
    return (
      <div>
        <div className = "centered-text">
          <h1 style={{paddingTop: "5.5em"}} autoFocus={shouldAutoFocus} tabIndex="1">404 - Page Not Found!</h1>
          <Link to="/" style={{paddingBottom: "5.5em"}} tabIndex="1">
            Click here to return to Home Page
          </Link>
        </div>
      </div>
    );
  }
}

export default NotFound;
