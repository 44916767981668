import React from "react";
import "./comment-box.css";

class CommentBox extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  //render method for setting up the page and displaying the given information.
  //displays current reviews and star ratings given by user for the viewed website.
  render () {
    //obtain Date object.
    var fullDate = new Date(this.props.date);
    //find month, day, and year.
    const month = fullDate.getMonth() + 1;
    const day = fullDate.getDate();
    const year = fullDate.getFullYear();
    //make our own date string.
    const date = `${month}/${day}/${year}`;

    var srcImg = [];
    var altText = [];

    //check each star rating against the user's current set star rating and display either full star or empty star.
    for (let i = 1; i <= 5; i++) {
      if (this.props.rating >= i) {
        srcImg[i] = '/images/full-star.png';
        altText[i] = `${i} Full Star`;
      } else {
        srcImg[i] = `/images/empty-star.png`;
        altText[i] = `${i} Empty Star`;
      }
    }

    return(
      <div className="comment-box">
        <div className="comment">
          {/* display each star rating to the user. */}
          <div className="user-rating" aria-label={`${this.props.rating} star rating`} alt={`${this.props.rating} star rating`}>
            <img src={srcImg[1]} className="Rating--Star" alt={altText[1]} tabIndex="-1" aria-hidden={true} hidden={true}></img>
            <img src={srcImg[2]} className="Rating--Star" alt={altText[2]} tabIndex="-1" aria-hidden={true} hidden={true}></img>
            <img src={srcImg[3]} className="Rating--Star" alt={altText[3]} tabIndex="-1" aria-hidden={true} hidden={true}></img>
            <img src={srcImg[4]} className="Rating--Star" alt={altText[4]} tabIndex="-1" aria-hidden={true} hidden={true}></img>
            <img src={srcImg[5]} className="Rating--Star" alt={altText[5]} tabIndex="-1" aria-hidden={true} hidden={true}></img>
          </div>
          {/* display date and feedback. */}
          <p className="comment-header">{date}</p>
          <p className="comment-body">"{this.props.feedback}"</p>
        </div>
      </div>
    );
  }
}

export default CommentBox;
