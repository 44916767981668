import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";

import Header from "./components/Header";
import Footer from "./components/Footer";
import Home from "./pages/Home";
import ScrollToTop from "./components/autoScroll"
import Sitemap from "./pages/Sitemap";
import Results from "./pages/Results";
import Fulltable from "./pages/Fulltable";
import About from "./pages/About";
import Advancedsearch from "./pages/Advancedsearch";
import Addsite from "./pages/Addsite";
import WCAGReason from "./pages/WCAGReason";
import PWAReason from "./pages/PWAReason";
import References from "./pages/References";
import Resources from "./pages/Resources";
import Communityfeedback from "./pages/Communityfeedback";
import Thanks from "./pages/Thanks";
import Releasenotes from "./pages/Releasenotes";
import Reviews from "./pages/Reviews";
import TutorialPage from "./pages/TutorialPage";
import NotFound from "./pages/NotFound";
import TutorialBasic from "./pages/TutorialBasic";
import TutorialAdvanced from "./pages/TutorialAdvanced";
import TutorialFeedback from "./pages/TutorialFeedback";
import TutorialWebsite from "./pages/TutorialWebsite";
import TutorialWCAG from "./pages/TutorialWCAG";
import TutorialReview from "./pages/TutorialReview";
import TutorialPWA from "./pages/TutorialPWA";

class App extends React.Component {
  render() {
    return (
      <div className="body">
        <Router>
        <ScrollToTop />
          <Header />
          <main>
            {/* All Pages to be Rendered go in the switch */}
            <Switch>
              <Route exact path="/">
                <Redirect to="/Home" />
              </Route>
              <Route path="/Home" component={Home} />
              <Route path="/Site Map" component={Sitemap} />
              <Route path="/Results" component={Results} />
              <Route path="/Full Table of Results" component={Fulltable} />
              <Route path="/About Web Accessibility" component={About} />
              <Route path="/Advanced Search" component={Advancedsearch} />
              <Route path="/Submit New Website" component={Addsite} />
              <Route path="/WCAG Reason" component={WCAGReason} />
              <Route path="/PWA Reason" component={PWAReason} />
              <Route path="/Browse Our References" component={References} />
              <Route path="/Check Available Resources" component={Resources} />
              <Route path="/Submit Feedback" component={Communityfeedback} />
              <Route path="/Thanks For Submitting" component={Thanks} />
              <Route path="/Release Notes" component={Releasenotes} />
              <Route path="/Reviews" component={Reviews} />
              <Route path="/User Tutorial" component={TutorialPage} />
              <Route path="/Basic Search" component={TutorialBasic} />
              <Route path="/Advanced Search Tutorial" component={TutorialAdvanced} />
              <Route path="/Submit Websites" component={TutorialWebsite} />
              <Route path="/Submit Feedback Tutorial" component={TutorialFeedback} />
              <Route path="/WCAG Reasons" component={TutorialWCAG} />
              <Route path="/Leave a Review" component={TutorialReview} />
              <Route path="/PWA Reasons" component={TutorialPWA} />
              <Route component={NotFound} />
            </Switch>
          </main>
          <Footer />
        </Router>
      </div>
    );
  }
}

export default App;
