import React from "react";
import "../styles.css";

class Releasenotes extends React.Component{
    constructor(props) {
        super(props);
        this.state = {};
        this.focusRef = React.createRef();
    }

    //componentDidMount will place the focus of the user (for screen reader purposes) on the first element on the page.
    async componentDidMount() {
      if (this.focusRef.current) {
        this.focusRef.current.focus();
      }
    }

    //render displays the information of what is new on the site, different bug fixes, and current version.
    //including links to send the user to the correct websites/tools.
    render() {
        return (
            <div className="flex-width-paragraph" style={{paddingTop:"20px"}}>
                <h2 className="centered-VersionText" autoFocus={true} ref={this.focusRef}
                tabIndex="0">What's New in Version '1.0.0'</h2><h3 className="centered-VersionText">Last Updated: December 17th, 2020</h3>
                <div>
                    <h4><br></br>What's New:</h4>
                    <ul>
                        <li>The Accessibility Compliance Tool automates the evaluation of websites using the Standard Letter Grade System and the&nbsp;
                            <a className="link" href="https://www.w3.org/TR/WCAG21/" alt="Web Content Accessibility Guidelines link" target="_blank" rel="noopener noreferrer">
                                Web Content Accessibility Guidelines (WCAG)
                            </a>.
                        </li>
                        <li>Established an initial database utilizing 17 categories for the classifications of audited websites.</li>
                        <li>Implemented basic and advanced query searches to retrieve data from the database.</li>
                        <li>Added multiple options for user feedback.</li>
                        <li>Thorough accessibility support for screen reader users.</li>
                    </ul>
                </div>
           </div>
        )
    }
}
export default Releasenotes;
