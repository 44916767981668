import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceworker from './service-worker';

((item1, item2) => {
    const local = localStorage;
    const session = sessionStorage;
    const tabId = session.getItem(item2)
      || ((newId) => {
        session.setItem(item2, newId);
        return newId;
      })((Math.random() * 1e8).toFixed());
    const update = (setTabValue) => {
        let value = JSON.parse(local.getItem(item1) || '{}');
        const arr = Object.values(value);
        if (setTabValue && typeof value[tabId] === 'undefined' && !arr.reduce((sum, current) => {
                return sum + current;
            }, 0)) {
                local.clear();
                value = {};
        }
        value[tabId] = setTabValue;
        local.setItem(item1, JSON.stringify(value));
    };
    update(1);
    window.onbeforeunload = () => {
        update(0);
    };
})('tabs', 'tabid');

ReactDOM.render(<App />,document.getElementById('root'));

serviceworker.register();
