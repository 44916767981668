import React from "react";
import { Link } from "react-router-dom";
import "../styles.css";
import FullTableResult from "../components/FullTableResult";
import TopScroll from "../components/TopScroll";
import { basicSearch } from "../api";
import DownloadResults from "../components/DownloadResults";
import Refactor from "../components/Refactor";

class Fulltable extends Refactor {
  constructor(props) {
    super(props);
    this.state = {
      resultItem: [],
      sort_by: "website",
      accessibility_grade: "accessibility grade",
      date_tested: "date tested",
      direction: {
        website: "ascend",
        url: "ascend",
        grade: "ascend",
        date_of_test: "ascend",
        PWA_score: "ascend",
        category: "ascend",
      },
    };
    this.focusRef = React.createRef();
    this.sortByWebsite = this.sortByWebsite.bind(this);
    this.sortByCategory = this.sortByCategory.bind(this);
    this.sortByURL = this.sortByURL.bind(this);
    this.sortByAccessibilityGrade = this.sortByAccessibilityGrade.bind(this);
    this.sortByDate = this.sortByDate.bind(this);
    this.sortByPWAGrade = this.sortByPWAGrade.bind(this);
    this.scoreToLetter = this.scoreToLetter.bind(this);
    this.sortHandler = this.sortHandler.bind(this);
    this.showPage = this.showPage.bind(this);
  }

  //componentDidMount will automatically run when page loads.
  //makes an API call to proxy for a basic search of a huge size (just to make sure we grab every result in our ES database).
  //immediately alphabetically sorts the results by website name.
  async componentDidMount() {
    //call basicSearch query from proxy and save the returned results in val.
    //this will return all results in our ES database.
    const val = await basicSearch("*", 10000);
    //map the val array to each result's source info (easier reference and display).
    const resultItem = val.map(item => (item._source));
    //map the val array to add a key to each result.
    const resultKeys = val.map(item => (item._id));
    for(let i = 0; i < resultItem.length; i++) {
      //set the key field for each result in resultItem to its id.
      resultItem[i].key = resultKeys[i];
    }

    //display page after timer runs out, shows loading spinner until then.
    setTimeout(this.showPage, 1000);

    //set state so results get saved in resultItem and sort the results by website name before the page loads.
    this.setState({ resultItem: resultItem }, () => this.sortByWebsite());
  }

  //showPage removes the loading spinner and displays the page results/information.
  showPage() {
    document.getElementById("loader").style.display = "none";
    document.getElementById("loader_message").style.display = "none";
    document.getElementById("results").style.display = "block";
    //make sure page focuses on the correct element as soon as the page fully loads.
    if (this.focusRef.current) {
      this.focusRef.current.focus();
    }
  }

  //sortByWebsite sorts the results in state by the "website" key.
  //Changes website direction to descending and all other directions to ascending.
  sortByWebsite() {
    //check if sorting direction for "website" is ascending.
    if (this.state.direction["website"] === "ascend") {
      this.state.resultItem.sort(function (a, b) {
        /*localeCompare is a method that sorts by alphabetical order
        'Express'.localeCompare('Soundcloud') will yield a neg value...
        'Soundcloud'.localeCompare('Express') will yield a pos value...*/
        return a.company.toLowerCase().localeCompare(b.company.toLowerCase());
      });
      this.setState({
        resultItem: this.state.resultItem,
        //set direction of sorting for all fields.
        direction: {
          website: "descend",
          category: "ascend",
          url: "ascend",
          grade: "ascend",
          PWA_score: "ascend",
          date_of_test: "ascend",
        },
        sort_by: "website"
      });
    }
    //then sorting direction for "website" is descending.
    else {
      this.state.resultItem.sort(function(a, b){
        /*localeCompare is a method that sorts by alphabetical order
        'Express'.localeCompare('Soundcloud') will yield a neg value...
        'Soundcloud'.localeCompare('Express') will yield a pos value...*/
        return b.company.toLowerCase().localeCompare(a.company.toLowerCase());
      });
      this.setState({
        resultItem: this.state.resultItem,
        //set direction of sorting for all fields.
        direction: {
          website: "ascend",
          category: "ascend",
          url: "ascend",
          grade: "ascend",
          PWA_score: "ascend",
          date_of_test: "ascend",
        },
      });
    }
  }

  //sortByURL sorts the results by the url using the "url" key in state.
  //changes url direction to descending and all other directions to ascending once the results are sorted.
  sortByURL() {
    //check if sorting direction for "url" is ascending.
    if (this.state.direction["url"] === "ascend") {
      this.state.resultItem.sort(function(a, b){
        /*localeCompare is a method that sorts by alphabetical order
        'express.com'.localeCompare('soundcloud.com') will yield a neg value...
        'soundcloud.com'.localeCompare('express.com') will yield a pos value...*/
        return a.url.toLowerCase().localeCompare(b.url.toLowerCase());
      });
      this.setState({
        resultItem: this.state.resultItem,
        //set direction of sorting for all fields.
        direction: {
          website: "ascend",
          category: "ascend",
          url: "descend",
          grade: "ascend",
          PWA_score: "ascend",
          date_of_test: "ascend",
        },
        sort_by: "URL"
      });
    }
    //then sorting direction for "url" is descending.
    else {
      this.state.resultItem.sort(function(a, b){
        /*localeCompare is a method that sorts by alphabetical order
        'express.com'.localeCompare('soundcloud.com') will yield a neg value...
        'soundcloud.com'.localeCompare('express.com') will yield a pos value...*/
        return b.url.toLowerCase().localeCompare(a.url.toLowerCase());
      });
      this.setState({
        resultItem: this.state.resultItem,
        //set direction of sorting for all fields.
        direction: {
          website: "ascend",
          category: "ascend",
          url: "ascend",
          grade: "ascend",
          PWA_score: "ascend",
          date_of_test: "ascend",
        },
      });
    }
  }

  //sortByAccessibilityGrade sorts the results by the accessibility grade using the "grade" key in state.
  //changes grade direction to descending and all other directions to ascending once the results are sorted.
  sortByAccessibilityGrade() {
    //check if sorting direction for "grade" is ascending.
    if (this.state.direction["grade"] === "ascend") {
      this.state.resultItem.sort(function(a, b){
        /*Comparison example for 0.9 (A) and 0.8 (B)
        0.8 - 0.9 will yield a neg value
        0.9 - 0.8 will yield a pos value*/
        return b.grade_number - a.grade_number;
      });
      this.setState({
        resultItem: this.state.resultItem,
        //set direction of sorting for all fields.
        direction: {
          website: "ascend",
          category: "ascend",
          url: "ascend",
          grade: "descend",
          PWA_score: "ascend",
          date_of_test: "ascend",
        },
        sort_by: this.state.accessibility_grade
      });
    }
    //then sorting direction for "grade" is descending.
    else {
      this.state.resultItem.sort(function(a, b){
        /*Comparison example for 0.9 (A) and 0.8 (B)
        0.8 - 0.9 will yield a neg value
        0.9 - 0.8 will yield a pos value*/
        return a.grade_number - b.grade_number;
      });
      this.setState({
        resultItem: this.state.resultItem,
        //set direction of sorting for all fields.
        direction: {
          website: "ascend",
          category: "ascend",
          url: "ascend",
          grade: "ascend",
          PWA_score: "ascend",
          date_of_test: "ascend",
        },
      });
    }
  }

  //sortByPWAGrade sorts the results by the PWA grade using the "PWA_score" key in state.
  //changes PWA_score direction to descending and all other directions to ascending once the results are sorted.
  sortByPWAGrade() {
    //check if sorting direction for "PWA_score" is ascending.
    if (this.state.direction["PWA_score"] === "ascend") {
      this.state.resultItem.sort(function(a, b){
        /*Comparison example for 0.7 (C) and 0.9 (A);
        0.7 - 0.9 will yield a neg value
        0.9 - 0.7 will yield a pos value*/
        return b.PWA_score - a.PWA_score;
      });
      this.setState({
        resultItem: this.state.resultItem,
        //set direction of sorting for all fields.
        direction: {
          website: "ascend",
          category: "ascend",
          url: "ascend",
          grade: "ascend",
          PWA_score: "descend",
          date_of_test: "ascend",
        },
        sort_by: "PWA grade"
      });
    }
    //then sorting direction for "PWA_score" is descending.
    else {
      this.state.resultItem.sort(function(a, b){
        /*Comparison example for 0.7 (C) and 0.9 (A);
        0.7 - 0.9 will yield a neg value
        0.9 - 0.7 will yield a pos value*/
        return a.PWA_score - b.PWA_score;
      });
      this.setState({
        resultItem: this.state.resultItem,
        //set direction of sorting for all fields.
        direction: {
          website: "ascend",
          category: "ascend",
          url: "ascend",
          grade: "ascend",
          PWA_score: "ascend",
          date_of_test: "ascend",
        },
      });
    }
  }

  //sortByDate sorts the results by the date tested using the "date_of_test" key in state.
  //changes date_of_test direction to descending and all other directions to ascending once the results are sorted.
  sortByDate() {
    //check if sorting direction for "date_of_test" is ascending.
    if (this.state.direction["date_of_test"] === "ascend") {
      this.state.resultItem.sort(function(a, b){
        /*set both values by parsing date_of_test into a number (milliseconds) to compare.
          Example:  2/2/2020 -> parse('2/2/2020') -> 1580619600000 milliseconds
                    6/2/2020 -> parse('6/2/2020') -> 1591070400000 of milliseconds
                    parse('2/2/2020') - parse('6/2/2020') will yield a neg value
                    parse('6/2/2020') - parse('2/2/2020') will yield a pos value */
        var x = Date.parse(a.date_of_test);
        var y = Date.parse(b.date_of_test);

        return y - x;
      });
      this.setState({
        resultItem: this.state.resultItem,
        //set direction of sorting for all fields.
        direction: {
          website: "ascend",
          category: "ascend",
          url: "ascend",
          grade: "ascend",
          PWA_score: "ascend",
          date_of_test: "descend",
        },
        sort_by: this.state.date_tested
      });
    }
    //then sorting direction for "date_of_test" is descending.
    else {
      this.state.resultItem.sort(function(a, b){
        /*set both values by parsing date_of_test into a number (milliseconds) to compare.
          Example:  2/2/2020 -> parse('2/2/2020') -> 1580619600000 milliseconds
                    6/2/2020 -> parse('6/2/2020') -> 1591070400000 of milliseconds
                    parse('2/2/2020') - parse('6/2/2020') will yield a neg value
                    parse('6/2/2020') - parse('2/2/2020') will yield a pos value */
        var x = Date.parse(a.date_of_test);
        var y = Date.parse(b.date_of_test);

        return x - y;
      });
      this.setState({
        resultItem: this.state.resultItem,
        //set direction of sorting for all fields.
        direction: {
          website: "ascend",
          category: "ascend",
          url: "ascend",
          grade: "ascend",
          PWA_score: "ascend",
          date_of_test: "ascend",
        },
      });
    }
  }

  //sortByCategory sorts the results by the category using the "category" key in state.
  //changes category direction to descending and all other directions to ascending once the results are sorted.
  sortByCategory() {
    //check if sorting direction for "category" is ascending.
    if (this.state.direction["category"] === "ascend") {
      this.state.resultItem.sort( (a, b) => {
        return this.categorySortAscend(a,b);
      });
      this.setState({
        resultItem: this.state.resultItem,
        //set direction of sorting for all fields.
        direction: {
          website: "ascend",
          category: "descend",
          url: "ascend",
          grade: "ascend",
          PWA_score: "ascend",
          date_of_test: "ascend",
        },
        sort_by: "category"
      });
    }
    //then sorting direction for "category" is descending.
    else {
      this.state.resultItem.sort( (a, b) => {
        return this.categorySortDescend(a,b);
      });
      this.setState({
        resultItem: this.state.resultItem,
        //set direction of sorting for all fields.
        direction: {
          website: "ascend",
          category: "ascend",
          url: "ascend",
          grade: "ascend",
          PWA_score: "ascend",
          date_of_test: "ascend",
        },
      });
    }
  }

  //scoreToLetter takes in a number between 0 and 100 and returns a letter grade associated with that number.
  //we follow the standard number grade to letter grade conversion - 10 point letters down from 100.
  scoreToLetter(score) {
    //if given score is a 100, letter grade is an A+.
    if (score === 100) {
      return "A+";
    }
    //if given score is greater than or equal to a 90, letter grade is an A.
    else if (score >= 90) {
      return "A";
    }
    //if given score is greater than or equal to a 80, letter grade is an B.
    else if (score >= 80) {
      return "B";
    }
    //if given score is greater than or equal to a 70, letter grade is an C.
    else if (score >= 70) {
      return "C";
    }
    //if given score is greater than or equal to a 60, letter grade is an D.
    else if (score >= 60) {
      return "D";
    }
    //else given score is less than a 60, letter grade is an F.
    else {
      return "F";
    }
  }

  //render method for setting up the page and displaying the given information.
  //the use of components makes this easier and more readable (TopScroll, DownloadResults, and FullTableResult).
  render() {
    const checkOut = "Check out our ";

    //auto focus the user to specific place in page.
    const shouldAutoFocus = window.innerWidth<600?false:true;

    return (
      <div>
        {/* start with results/information not showing - loading spinner will run during this time. */}
        <div className="centered-text" id="results" data-testid="results" style={{display: "none"}}>
          {/* TopScroll allows user to immediately scroll to the top of the page from anywhere. */}
          <TopScroll scrollStepInPx="50" delayInMs="30"/>
          {/* DownloadResults allows the user to download the current results into a CSV file. */}
          <h2 autoFocus={shouldAutoFocus} ref={this.focusRef} id="focus">{this.state.resultItem.length} websites tested,
           sorting by {this.state.sort_by}.</h2>
          <DownloadResults
            resultItem={this.state.resultItem}
            scoreToLetter={this.scoreToLetter}
            from={"Full"}
          />
          <p>Click on either grade to view reasons behind score</p> <br />
          {/* Here is the sorting dropdowns. One for which field to sort by and one for which direction. */}
          <div className="search-row" >
            <div className="result-row">
              <label htmlFor="sort_by" style={{ paddingRight: "5px", paddingTop: "4px" }}>Sort By: </label>
              <select name="sort_by" id="sort_by" value={this.state.sort_by} onChange={this.sortHandler} alt="Sort By Dropdown" tabIndex="0">
                <option value="website">Website</option>
                <option value="category">Category</option>
                <option value="URL">URL</option>
                <option value={this.state.accessibility_grade}>Accessibility Grade</option>
                <option value="PWA grade">PWA Grade</option>
                <option value={this.state.date_tested}>Date Tested</option>
              </select>
              <div style={{ paddingRight: "8px" }} />
              <select name="sort_direction" id="sort_direction" value={this.state.sort_direction} onChange={this.sortDirectionHandler} alt="Sort By Direction" tabIndex="0">
                <option value="ascend">Ascending</option>
                <option value="descend">Descending</option>
              </select>
            </div>
          </div>
          <br />
          {/* this asks user if they would like to suggest a company or website for scanning if they don't see it. */}
          <p tabIndex="0">
            Have a company or website you would like to add that you don't see? <br />
            {checkOut}
            <Link to="/Submit New Website" className="link" alt="Submit New Website Link" tabIndex="0">
              Submit a New Website
            </Link> Page!
          </p>
          <br />
          {/* call FullTableResult to display the current results to the page. */}
          <FullTableResult
            resultItem={this.state.resultItem}
            sortByWebsite={this.sortByWebsite}
            sortByCategory={this.sortByCategory}
            sortByURL={this.sortByURL}
            sortByGrade={this.sortByGrade}
            sortByDate={this.sortByDate}
            sortByPWAGrade={this.sortByPWAGrade}
            scoreToLetter={this.scoreToLetter}
          />
        </div>
        {/* display a loading spinner while results are being fetched, processed, and displayed. */}
        <div className="loader" id="loader" data-testid="loader"></div>
        <label className="loader_message" id="loader_message" data-testid="loader_message">Loading...</label>
        <br/>
      </div>
    );
  }
}

export default Fulltable;
