import React from 'react';
import '../styles.css';

class DownloadResults extends React.Component {
    constructor(props) {
        super(props);
        this.print = this.print.bind(this);
    }

    //print creates a CSV file with all the results the user is currently looking at.
    print() {
        //create variable to hold information with all results' fields (labeled with each header to start).
        var csv = 'Website Name,Category,URL,Accessibility Grade,PWA Grade,Date Tested\n';
        //map data of results to csv variable separating each field with a comma.
        this.props.resultItem.map((row) => {
            var cat = JSON.stringify(row.category);
            //make sure to replace any comma in text with a space so it doesn't show as two fields.
            cat = cat.replace(/,/g, " ")
            csv += JSON.stringify(row.company);
            csv += ",";
            csv += cat;
            csv += ",";
            csv += row.url;
            csv += ",";
            csv += row.grade_letter;
            csv += ",";
            csv += this.props.scoreToLetter(row.PWA_score*100);
            csv += ",";
            csv += row.date_of_test;
            csv += ",";
            csv += "\n";
            return true;
        });

        //create element that takes in csv information into a csv file
        var hiddenElement = document.createElement('a');
        hiddenElement.href = `data:text/csv;charset=utf-8, ${encodeURI(csv)}`;
        hiddenElement.target = '_blank';
        //check if user is downloading current Full Table of Results.
        if (this.props.from === "Full") {
            //name csv file 'full_table_results.csv'.
            hiddenElement.download = 'full_table_results.csv';
        }
        //check if user is downloading current Advanced Search results.
        else if (this.props.from === "Advanced") {
            //name csv file 'advanced_search_results.csv'.
            hiddenElement.download = 'advanced_search_results.csv';
        }
        //then user is downloading current Basic Search results.
        else {
            //name csv file 'basic_search_results.csv'.
            hiddenElement.download = 'basic_search_results.csv';
        }
        //send csv file into given implementation for downloading files.
        hiddenElement.click();
    }

    //render method for setting up the page and displaying the given information.
    //is displayed to the screen as a button so when user clicks button, current results will be downloaded.
    render() {
        return (
            <div className="printpage">
                <button alt="Download Results" onClick={() => this.print()} style={{width: "110px"}}>
                    Download Results
                </button>
            </div>
        )
    }
}

export default DownloadResults;
