import React from "react";
import "../styles.css";
import ReasonText from "../components/ReasonText";
import ReasonsFeedback from "../components/ReasonsFeedback";
import Refactor from "../components/Refactor";

class Reason extends Refactor {
  constructor(props) {
    super(props);
    this.state = {
      results: [],
      grade_letter: "",
      grade_number: 0,
      url: "",
      company: "",
      PWA_score: 0,
      date_of_test: "",
      sort_by: "title",
      review: false,
    };
    this.componentDidMount = this.componentDidMount.bind(this);
    this.sortByCriteria = this.sortByCriteria.bind(this);
    this.sortByLevel = this.sortByLevel.bind(this);
    this.sortByTitle = this.sortByTitle.bind(this);
    this.sortByAll = this.sortByAll.bind(this);
    this.sortBySeverity = this.sortBySeverity.bind(this);
    this.leaveReview = this.leaveReview.bind(this);
    this.stopRefocus = this.stopRefocus.bind(this);
  }

  //componentDidMount will grab items from local storage and set them to state.
  //maps given WCAG results and sorts it.
  async componentDidMount() {
        //grab items from local storage.
        const company = localStorage.getItem('company');
        const url = localStorage.getItem('url');
        const gradeletter = localStorage.getItem('grade_letter');
        const gradenumber = localStorage.getItem('grade_number');
        const dateoftest = localStorage.getItem('date_of_test');
        const resultsofgrade = JSON.parse(localStorage.getItem('results_of_grade'));
        const PWAscore = localStorage.getItem('PWA_score');

        //map WCAG results into array and give each entry a key.
        const successCriteriaData = Object.keys(resultsofgrade).map((key) => (resultsofgrade[key]));
        const values = Object.keys(resultsofgrade);
        for(let i = 0; i < successCriteriaData.length; i++) {
          successCriteriaData[i].key = values[i];
        }
        //set state with given variables and then call sort function to sort the array of data.
        this.setState({
            grade_letter: gradeletter,
            grade_number: gradenumber,
            url: url,
            company: company,
            PWA_score: PWAscore,
            date_of_test: dateoftest,
            results: successCriteriaData,
            review: false,
        }, () => this.sortByAll());
  }

  //sortByCriteria takes results from state and sorts it by ascending criteria (number - ex. "1.1.1").
  sortByCriteria() {
    //set state with criteria sorted version of WCAG results.
    this.state.results.sort((a, b) => {
      console.log("Results:");
      console.log(this.state.results);
      console.log("A:");
      console.log(a);
      console.log("B:");
      console.log(b);
      //grabs the number for the criteria and splits it by the decimal
      var x = a.wcag_success_criteria.split(' ')[0].split('.');
      var y = b.wcag_success_criteria.split(' ')[0].split('.');

      //compares the first and second decimal value and sorts them in ascending order (accounts for trailing #'s and zeroes)
      //(e.g. 1.1.10 and 1.1.1 -> first comparison returns 0, second returns 0, third returns positive, so 1.1.10 is bigger than 1.1.1)
      return (
          x.shift() - y.shift()
      ) || (
          x.shift() - y.shift()
      ) || (
          x.shift() - y.shift()
      );
    });

    this.setState({
      results: this.state.results,
      sort_by: "criteria",
    });
  }

  //sortByLevel takes results from state and sorts it by ascending level (letter - ex. "A").
  sortByLevel() {
    //set state with the level sorted version of WCAG results.
    this.state.results.sort((a, b) => {
      //localeCompare is a function that sorts by alphabetical order
      return a.wcag_level.toLowerCase().localeCompare(b.wcag_level.toLowerCase());
    });
    this.setState({
      results: this.state.results,
      sort_by: "level",
    });
  }

  //sortBySeverity takes results from state and sorts it by ascending severity (seriousness - ex. "Critical").
  sortBySeverity() {
    //set state with the severity sorted version of WCAG results.
    this.state.results.sort(function(a,b) {
      var x = a.wcag_severity;
      var y = b.wcag_severity;

      //created custom logic to deal with each scenario - not alphabetical.
      /*istanbul ignore next*/
      if ((x === "Critical")&&((y === "Serious")||(y[0] === "M"))) {
        return -1;
      }
      /*istanbul ignore next*/
      if ((x === "Serious")&&(y[0] === "M")) {
        return -1;
      }
      if ((x === "Moderate")&&(y === "Minor")) {
        return -1;
      }
      if ((y === "Critical")&&((x === "Serious")||(x[0] === "M"))) {
        return 1;
      }
      //coverage ignored due to not being able to access this branch with test data
      /*istanbul ignore next*/
      if ((y === "Serious")&&(x[0] === "M")) {
        return 1;
      }
      if ((y === "Moderate")&&(x === "Minor")) {
        return 1;
      }
      return 0;
    });
    this.setState({
      results: this.state.results,
      sort_by: "severity",
    });
  }

  //sortByAll takes results from state and sorts by criteria, then sorts by level. Pre-sort that happens when user opens page.
  //We want to show the user a list of criteria from level "A" to "AAA" and ascending criteria number within those.
  sortByAll() {
    //sort by criteria and then sort by level within the criteria sorted results.
    this.sortByCriteria();
    this.sortByLevel();
  }

  //leaveReview will take user to bottom of WCAG Reason page so they can leave a review for that website.
  leaveReview() {
    this.setState({
      review: true,
    })
    window.scrollTo({left: 0, top: document.body.scrollHeight, behavior: "smooth"})
  }

  //after star rating is focused once, we want to relieve review to false
  // so that it doesn't keep refocusing
  stopRefocus() {
    this.setState({
      review: false,
    })
  }

  //render method for setting up the page and displaying the given information.
  //the use of components makes this easier and more readable (ReasonText and ReasonsFeedback).
  render() {
    return (
      <div>
        {/* call ReasonText to display a table of criteria that given website failed.
            provides user with information regarding how the score was given.
        */}
        <ReasonText
          sort_by={this.state.sort_by}
          wcag={true}
          pwa={false}
          grade_letter={this.state.grade_letter}
          grade_number={this.state.grade_number}
          date_of_test={this.state.date_of_test}
          url={this.state.url}
          company={this.state.company}
          data={this.state.results}
          sortByLevel={this.sortByLevel}
          sortByCriteria={this.sortByCriteria}
          sortByTitle={this.sortByTitle}
          sortBySeverity={this.sortBySeverity}
          leaveReview={this.leaveReview}
          review={this.state.review}
        />
        {/* call ReasonsFeedback to display a form for user to be able to give feedback on given website.
            user can input a star rating (1-5 stars).
            displays a chart of other ratings given by other users
            or encourages user to submit their rating if none have been received yet.
        */}
        <ReasonsFeedback
          grade_letter={localStorage.getItem('grade_letter')}
          grade_number={localStorage.getItem('grade_number')}
          date_of_test={localStorage.getItem('date_of_test')}
          url={localStorage.getItem('url')}
          company={localStorage.getItem('company')}
          review={this.state.review}
          stopRefocus={this.stopRefocus}
        />
        <br />
      </div>
    );
  }
}

export default Reason;
