//results.js
import React from "react"
import { Link } from "react-router-dom";
import '../styles.css';
import TopScroll from "../components/TopScroll";
import { basicSearch, advancedSearch, localAdvancedSearch, getGeopoint, categorySearch, localCategoryAdvancedSearch } from "../api";
import { fourSquareMatchedResults } from "../api/fourSquare";
import SearchResultTable from "../components/SearchResultTable";
import DownloadResults from "../components/DownloadResults";
import Refactor from "../components/Refactor";
import categories from "../categories";

class Results extends Refactor {
    constructor(props) {
        super(props)
        this.state = {
            paint: [],
            resultItem: [],
            count: 0,
            sort_by: "relevance",
            sort_direction: "ascend",
            accessibility_grade: "accessibility grade",
            date_tested: "date tested",
            isAdvancedSearch: false,
            relevantResults: [],
            name: "",
            min_grade: 'F',
            date: 'Any',
            direction: {
                accessibility_grade: "ascend",
                relevance: "ascend",
                website: "ascend",
                pwa_grade: "ascend",
                date: "ascend",
                url: "ascend",
                category: "ascend",
            },
        }
        this.focusRef = React.createRef();
        this.loadingRef = React.createRef();
        //Bind component methods
        this.condensedResults = this.condensedResults.bind(this);
        this.sortByWebsite = this.sortByWebsite.bind(this);
        this.sortByAccessibilityGrade = this.sortByAccessibilityGrade.bind(this);
        this.sortByRelevance = this.sortByRelevance.bind(this);
        this.sortByPWAGrade = this.sortByPWAGrade.bind(this);
        this.sortByURL = this.sortByURL.bind(this);
        this.sortByDate = this.sortByDate.bind(this);
        this.sortByCategory = this.sortByCategory.bind(this);
        this.sortHandler = this.sortHandler.bind(this);
        this.sortDirectionHandler = this.sortDirectionHandler.bind(this);
        this.scoreToLetter = this.scoreToLetter.bind(this);
        this.beginningSort = this.beginningSort.bind(this);
        this.checkIfRelevant = this.checkIfRelevant.bind(this);
        this.displayResults = this.displayResults.bind(this);
        this.showPage = this.showPage.bind(this);
        this.mountNext = this.mountNext.bind(this);
        this.mountLast = this.mountLast.bind(this);
        this.ifTermIsCategory = this.ifTermIsCategory.bind(this);
        this.checkString = this.checkString.bind(this);
        this.basicSearchChoice = this.basicSearchChoice.bind(this);
        this.categorySortAscend = this.categorySortAscend.bind(this);
        this.categorySortDescend = this.categorySortDescend.bind(this);
        this.checkDefinedCategory = this.checkDefinedCategory.bind(this);
        this.mountFindCategory = this.mountFindCategory.bind(this);
    }

    //componentDidMount will automatically run when page loads.
    //grabs many fields from localStorage to use in displaying results.
    //makes an API call to proxy depending on which fields have been filled out by the user.
    /*istanbul ignore next */
    async componentDidMount() {
        //grab fields from localStorage.
        const searchTerm = localStorage.getItem('searchTerm');
        let lastUpdated = localStorage.getItem('lastUpdated');
        const zip = localStorage.getItem('zip');
        const coordinates = localStorage.getItem('coordinates');
        let isAdvancedSearch = localStorage.getItem('isAdvancedSearch');

        //change string field to boolean for checking.
        isAdvancedSearch = this.checkString(isAdvancedSearch);

        const termIsCategory = this.ifTermIsCategory(searchTerm);

        let val = [];
        //check if user came from a basic search (Home page).
        if (!isAdvancedSearch) {
            //basicSearchChoice will check if anything search term was entered and find results accordingly.
            val = await this.basicSearchChoice(val,searchTerm,termIsCategory);
        }
        //then user must have come from an advanced search (Advanced Search page).
        else {
            //generate current date in YYYY-MM-DD form to match backend.
            //this is used for determining which results to show based on when they were last updated.
            if (lastUpdated !== 'Any' && isAdvancedSearch) {
                const today = new Date();
                today.setDate(today.getDate() - parseInt(lastUpdated, 10));
                lastUpdated = today;
            }
            else {
                lastUpdated = new Date(0);
            }

            //create idArray
            let idArray = [];

            // check if coordinates is not blank ("" means blank).
            if (coordinates !== "") {
                //call fourSquareMatchedResults query from proxy and save the returned results in idArray.
                //this will return any results that are in FourSquare that have given searchTerm
                //and are found in given coordinates.
                idArray = await fourSquareMatchedResults(coordinates,searchTerm);
            }
            //check if zip is not blank ("" means blank).
            else if (zip !== "") {
                //call zipToGeopoint to change given zip code to coordinates.
                try {
                    const geopoint = await zipToGeopoint(zip);
                    //call fourSquareMatchedResults query from proxy and save the returned results in idArray.
                    //this will return any results that are in FourSquare that have given searchTerm
                    //and are found in given geopoint (coordinates).
                    idArray = await fourSquareMatchedResults(geopoint,searchTerm);
                } catch (error) {
                    console.error(error);
                    idArray = [];
                }
            }
            val = await this.mountNext(searchTerm,lastUpdated,zip,idArray,val,coordinates,termIsCategory);
        }
        this.mountLast(val,isAdvancedSearch,searchTerm);
    }
    /*istanbul ignore next*/
    async mountNext(searchTerm,lastUpdated,zip,idArray,val,coordinates,termIsCategory) {
        const minGrade = localStorage.getItem('minGrade');
        const category = localStorage.getItem('category');
        //generate acceptable grades.
        const grades = ['A', 'B', 'C', 'D', 'F'];
        const scores = [.9, .8, .7, .6, .0];

        //user gives minimum grade that results must have that grade or better in order to be displayed.
        //minScore will be the decimal number associated with the minimum grade required.
        const minScore = scores[grades.indexOf(minGrade)];

        //if either searchTerm is empty, set to wildcard ("*").
        //allows for more search flexibility and fuzziness.
        if (searchTerm === "") {
            searchTerm = "*";
        }

        let attrs = [];
        let result;

        //check if zip or coordinates fields are not blank ("" means blank).
        //if at least one of these fields are not blank, we will enter this "if" statement.
        if (zip !== "" || coordinates !== "") {
            //check if category field is not blank ("" means blank).
            if (category !== "") {
                //set attrs to include idArray and category.
                attrs = {
                    idArray: idArray,
                    category: category,
                    keywords: "*",
                    grades: minScore,
                    lastUpdated: lastUpdated
                }
                //call localCategoryAdvancedSearch query from proxy and save results in result.
                //this will return any results in our database that match the results returned by FourSquare (idArray)
                //and have the given category, grades, and lastUpdated.
                result = await localCategoryAdvancedSearch(attrs);
            }
            //category must be blank then.
            else {
                //set attrs to include idArray but not category.
                attrs = {
                    idArray: idArray,
                    keywords: "*",
                    grades: minScore,
                    lastUpdated: lastUpdated
                }
                //call localAdvancedSearch query from proxy and save results in result.
                //this will return any results in our database that match the results returned by FourSquare (idArray)
                //and have the given grades and lastUpdated.
                result = await localAdvancedSearch(attrs);
            }
            //save each specific result in an array called val for easier display and reference.
            val = result.body.hits.hits;
        }
        //zip and coordinates must be blank then.
        //check if category is not blank ("" means blank).
        else if (category !== "") {
            //set attrs to include searchTerm and category.
            attrs = {
                searchTerm: searchTerm,
                category: category,
                keywords: "*",
                grades: minScore,
                lastUpdated: lastUpdated
            }
            //call categorySearch query from proxy and save results in val.
            //this will return any results in our database that match the given searchTerm and category
            //as well as grades and lastUpdated.
            val = await categorySearch(attrs);
        }
        //zip, coordinates, and category must be blank.
        else {
            const old = new Date(0);
            if (minScore === 0 && searchTerm === "*" && lastUpdated.getDate() === old.getDate()) {
                val = await basicSearch("*", 10000, true);
            }
            else {
                //set attrs to include searchTerm.
                attrs = {
                    searchTerm: searchTerm,
                    keywords: "*",
                    grades: minScore,
                    lastUpdated: lastUpdated,
                    termIsCategory: termIsCategory
                }
                //call advancedSearch query from proxy and save results in val.
                //this will return any results in our database that match the given searchTerm, grades, and lastUpdated.
                val = await advancedSearch(attrs);
            }
        }
        return val;
    }
    /*istanbul ignore next*/
    mountLast(val,isAdvancedSearch,searchTerm) {
        const category = localStorage.getItem('category');
        const sortBy = localStorage.getItem('sortBy');
        //map the val array to each result's source info (easier reference and display).
        const list = val.map(item => (item._source));
        //map the val array to add a key to each result.
        const resultKeys = val.map(item => (item._id));
        for(let i = 0; i < list.length; i++) {
            //set the key field for each result in list to its id.
            list[i].key = resultKeys[i];
            var definedCategory = this.checkDefinedCategory(list[i].category);
            if (definedCategory) {
                if (list[i].category.includes(category)) {
                    list[i].category.sort( (x,y) => {
                        return this.mountFindCategory(x,y,category);
                    });
                }
            }
        }
        if (this.loadingRef.current) {
            this.loadingRef.current.focus();
        }

        //display page after timer runs out, shows loading spinner until then.
        setTimeout(this.showPage, 1000);

        //call beginningSort to determine how the results should be sorted by first (field set by user).
        this.beginningSort(sortBy,list,isAdvancedSearch,searchTerm);
    }

    mountFindCategory(x,y,category) {
        if (x === category) {
            return -1;
        }
        else {
            if (y === category) {
                return 1;
            }
            else {
                return 0;
            }
        }
    }

    async basicSearchChoice(val,searchTerm,termIsCategory) {
        if (searchTerm === null || searchTerm === "") {
            //call basicSearch query from proxy and save the returned results in val.
            //this will return all results in database because no search term was given.
            val = await basicSearch("*", 10000, true);
        }
        else {
            //call basicSearch query from proxy and save the returned results in val.
            //this will return any results that have the given searchTerm in its name, url, cateogyr, and/or html tags.
            val = await basicSearch(searchTerm, 100, termIsCategory);
        }
        return val;
    }

    //ifTermIsCategory checks if the search term is actually one of the categories, in which case,
    //we will just run a category search (search by entries of that category).
    ifTermIsCategory(searchTerm) {
        let termIsCategory = false;
        //check each category
        for (var category of categories) {
            //check if search term matches a category
            if (category.text.toLowerCase().includes(searchTerm.toLowerCase())) {
                //set termIsCategory to TRUE. proxy.js knows what to do with this.
                termIsCategory = true;
                break;
            }
        }
        return termIsCategory;
    }

    //checkString takes in string value and return boolean of the same.
    checkString(isAdvancedSearch) {
        if (isAdvancedSearch === "false" || isAdvancedSearch === 'false') {
            isAdvancedSearch = false;
        }
        else {
            isAdvancedSearch = true;
        }
        return isAdvancedSearch;
    }

    //showPage removes the loading spinner and displays the page results/information.
    /*istanbul ignore next*/
    showPage() {
        /*istanbul ignore next*/
        document.getElementById("loader").style.display = "none";
        document.getElementById("loader_message").style.display = "none";
        document.getElementById("results").style.display = "block";
        //make sure page focuses on the correct element as soon as the page fully loads.
        if (this.focusRef.current) {
            this.focusRef.current.focus();
        }
    }

    //beginningSort determines how the results should be sorted when the results are first displayed (field set by user).
    //set values in state so the results can be displayed.
    beginningSort(sortBy,list,isAdvancedSearch,searchTerm) {
        //if user wants to first sort by the name of the website (ex. "iSenpai" or "Amazon").
        if ( sortBy === "website" || searchTerm == null || searchTerm === "") {
            this.setState({ resultItem: list, count: list.length, paint: list, relevantResults: list, isAdvancedSearch: isAdvancedSearch, sort_by: sortBy},
                () => this.sortByWebsite());
        }
        //if user wants to first sort by accessibility grade (ex. "A" or "B").
        else if (sortBy === this.state.accessibility_grade) {
            this.setState({ resultItem: list, count: list.length, paint: list, relevantResults: list, isAdvancedSearch: isAdvancedSearch, sort_by: sortBy},
                () => this.sortByAccessibilityGrade());
        }
        //if user wants to first sort by pwa grade (ex. "A" or "B").
        else if (sortBy === "pwa grade") {
            this.setState({ resultItem: list, count: list.length, paint: list, relevantResults: list, isAdvancedSearch: isAdvancedSearch, sort_by: sortBy},
                () => this.sortByPWAGrade());
        }
        //if user wants to first sort by the url (ex. "isenpai.com").
        else if (sortBy === "url") {
            this.setState({ resultItem: list, count: list.length, paint: list, relevantResults: list, isAdvancedSearch: isAdvancedSearch, sort_by: sortBy},
                () => this.sortByURL());
        }
        //if user wants to first sort by the date of the website's last test.
        //more recent tests will show first.
        else if (sortBy === this.state.date_tested) {
            this.setState({ resultItem: list, count: list.length, paint: list, relevantResults: list, isAdvancedSearch: isAdvancedSearch, sort_by: sortBy},
                () => this.sortByDate());
        }
        //if user wants to first sort by category (ex. "Food" or "Shopping").
        else if (sortBy === "category") {
            this.setState({ resultItem: list, count: list.length, paint: list, relevantResults: list, isAdvancedSearch: isAdvancedSearch, sort_by: sortBy},
                () => this.sortByCategory());
        }
        //if user does not set a initial sort, then we will sort by relevance.
        else {
            this.setState({ resultItem: list, count: list.length, paint: list, relevantResults: list, isAdvancedSearch: isAdvancedSearch, sort_by: sortBy},
                () => this.sortByRelevance());
        }
    }

    //sortByWebsite sorts the results by the website name using the "website" key in state.
    //changes website direction to descending and all other directions to ascending once the results are sorted.
    sortByWebsite() {
        //check if sorting direction for "website" is ascending.
        if (this.state.direction["website"] === "ascend") {
            this.state.resultItem.sort(function (a, b) {
                /*localeCompare is a method that sorts by alphabetical order
                'Express'.localeCompare('Soundcloud') will yield a neg value...
                'Soundcloud'.localeCompare('Express') will yield a pos value...*/
                return a.company.toLowerCase().localeCompare(b.company.toLowerCase());
            });
            this.setState({
                resultItem: this.state.resultItem,
                //set direction of sorting for all fields.
                direction: {
                    relevance: "ascend",
                    website: "descend",
                    category: "ascend",
                    url: "ascend",
                    accessibility_grade: "ascend",
                    PWA_grade: "ascend",
                    date: "ascend",
                },
                sort_by: "website"
            //make sure to call condensedResults as this function will set our new sorted results in state before page re-renders.
            }, () => this.condensedResults());
        }
        //then sorting direction for "website" is descending.
        else {
            this.state.resultItem.sort(function (a, b) {
                /*localeCompare is a method that sorts by alphabetical order
                'Express'.localeCompare('Soundcloud') will yield a neg value...
                'Soundcloud'.localeCompare('Express') will yield a pos value...*/
                return b.company.toLowerCase().localeCompare(a.company.toLowerCase());
            });
            this.setState({
                resultItem: this.state.resultItem,
                //set direction of sorting for all fields.
                direction: {
                    relevance: "ascend",
                    website: "ascend",
                    category: "ascend",
                    url: "ascend",
                    accessibility_grade: "ascend",
                    PWA_grade: "ascend",
                    date: "ascend",
                },
            //make sure to call condensedResults as this function will set our new sorted results in state before page re-renders.
            }, () => this.condensedResults());
        }
    }

    //sortByURL sorts the results by the url using the "url" key in state.
    //changes url direction to descending and all other directions to ascending once the results are sorted.
    sortByURL() {
        //check if sorting direction for "url" is ascending.
        if (this.state.direction["url"] === "ascend") {
            this.state.resultItem.sort(function(a, b){
                /*localeCompare is a method that sorts by alphabetical order
                'express.com'.localeCompare('soundcloud.com') will yield a neg value...
                'soundcloud.com'.localeCompare('express.com') will yield a pos value...*/
                return a.url.toLowerCase().localeCompare(b.url.toLowerCase());
            });
            this.setState({
                resultItem: this.state.resultItem,
                //set direction of sorting for all fields.
                direction: {
                    relevance: "ascend",
                    website: "ascend",
                    category: "ascend",
                    url: "descend",
                    accessibility_grade: "ascend",
                    PWA_grade: "ascend",
                    date: "ascend",
                },
                sort_by: "URL"
            //make sure to call condensedResults as this function will set our new sorted results in state before page re-renders.
            }, () => this.condensedResults());
        }
        //then sorting direction for "url" is descending.
        else {
            this.state.resultItem.sort(function(a, b){
                /*localeCompare is a method that sorts by alphabetical order
                'express.com'.localeCompare('soundcloud.com') will yield a neg value...
                'soundcloud.com'.localeCompare('express.com') will yield a pos value...*/
                return b.url.toLowerCase().localeCompare(a.url.toLowerCase());
            });
            this.setState({
                resultItem: this.state.resultItem,
                //set direction of sorting for all fields.
                direction: {
                    relevance: "ascend",
                    website: "ascend",
                    category: "ascend",
                    url: "ascend",
                    accessibility_grade: "ascend",
                    PWA_grade: "ascend",
                    date: "ascend",
                },
            //make sure to call condensedResults as this function will set our new sorted results in state before page re-renders.
            }, () => this.condensedResults());
        }
    }

    //sortByAccessibilityGrade sorts the results by the accessibility grade using the "accessibility_grade" key in state.
    //changes accessibility_grade direction to descending and all other directions to ascending once the results are sorted.
    sortByAccessibilityGrade() {
        //check if sorting direction for "accessibility_grade" is ascending.
        if (this.state.direction["accessibility_grade"] === "ascend") {
            this.state.resultItem.sort(function(a, b){
                /*Comparison example for 0.9 (A) and 0.8 (B)
                0.8 - 0.9 will yield a neg value
                0.9 - 0.8 will yield a pos value*/
                return b.grade_number - a.grade_number;
            });
            this.setState({
                resultItem: this.state.resultItem,
                //set direction of sorting for all fields.
                direction: {
                    relevance: "ascend",
                    website: "ascend",
                    category: "ascend",
                    url: "ascend",
                    accessibility_grade: "descend",
                    PWA_grade: "ascend",
                    date: "ascend",
                },
                sort_by: this.state.accessibility_grade
            //make sure to call condensedResults as this function will set our new sorted results in state before page re-renders.
            }, () => this.condensedResults());
        }
        //then sorting direction for "accessibility_grade" is descending.
        else {
            this.state.resultItem.sort(function(a, b){
                /*Comparison example for 0.9 (A) and 0.8 (B)
                0.8 - 0.9 will yield a neg value
                0.9 - 0.8 will yield a pos value*/
                return a.grade_number - b.grade_number;
            });
            this.setState({
                resultItem: this.state.resultItem,
                //set direction of sorting for all fields.
                direction: {
                    relevance: "ascend",
                    website: "ascend",
                    category: "ascend",
                    url: "ascend",
                    accessibility_grade: "ascend",
                    PWA_grade: "ascend",
                    date: "ascend",
                },
            //make sure to call condensedResults as this function will set our new sorted results in state before page re-renders.
            }, () => this.condensedResults());
        }
    }

    //sortByPWAGrade sorts the results by the PWA grade using the "PWA_grade" key in state.
    //changes PWA_grade direction to descending and all other directions to ascending once the results are sorted.
    sortByPWAGrade() {
        //check if sorting direction for "PWA_grade" is ascending.
        if (this.state.direction["PWA_grade"] === "ascend") {
            this.state.resultItem.sort(function(a, b){
                /*Comparison example for 0.7 (C) and 0.9 (A);
                0.7 - 0.9 will yield a neg value
                0.9 - 0.7 will yield a pos value*/
                return b.PWA_score - a.PWA_score;
            });
            this.setState({
                resultItem: this.state.resultItem,
                //set direction of sorting for all fields.
                direction: {
                    relevance: "ascend",
                    website: "ascend",
                    category: "ascend",
                    url: "ascend",
                    accessibility_grade: "ascend",
                    PWA_grade: "descend",
                    date: "ascend",
                },
                sort_by: "PWA grade"
            //make sure to call condensedResults as this function will set our new sorted results in state before page re-renders.
            }, () => this.condensedResults());
        }
        //then sorting direction for "PWA_grade" is descending.
        else {
            this.state.resultItem.sort(function(a, b){
                /*Comparison example for 0.7 (C) and 0.9 (A);
                0.7 - 0.9 will yield a neg value
                0.9 - 0.7 will yield a pos value*/
                return a.PWA_score - b.PWA_score;
            });
            this.setState({
                resultItem: this.state.resultItem,
                //set direction of sorting for all fields.
                direction: {
                    relevance: "ascend",
                    website: "ascend",
                    category: "ascend",
                    url: "ascend",
                    accessibility_grade: "ascend",
                    PWA_grade: "ascend",
                    date: "ascend",
                },
            //make sure to call condensedResults as this function will set our new sorted results in state before page re-renders.
            }, () => this.condensedResults());
        }
    }

    //sortByDate sorts the results by the date tested using the "date" key in state.
    //changes date direction to descending and all other directions to ascending once the results are sorted.
    sortByDate() {
        //check if sorting direction for "date" is ascending.
        if (this.state.direction["date"] === "ascend") {
            this.state.resultItem.sort(function(a, b){
                /*set both values by parsing date_of_test into a number (milliseconds) to compare.
                    Example: 2/2/2020 -> parse('2/2/2020') -> 1580619600000 milliseconds
                            6/2/2020 -> parse('6/2/2020') -> 1591070400000 of milliseconds
                            parse('2/2/2020') - parse('6/2/2020') will yield a neg value
                            parse('6/2/2020') - parse('2/2/2020') will yield a pos value */
                var x = Date.parse(a.date_of_test);
                var y = Date.parse(b.date_of_test);

                return y - x;
            });
            this.setState({
                resultItem: this.state.resultItem,
                //set direction of sorting for all fields.
                direction: {
                    relevance: "ascend",
                    website: "ascend",
                    category: "ascend",
                    url: "ascend",
                    accessibility_grade: "ascend",
                    PWA_grade: "ascend",
                    date: "descend",
                },
                sort_by: this.state.date_tested
            //make sure to call condensedResults as this function will set our new sorted results in state before page re-renders.
            }, () => this.condensedResults());
        }
        //then sorting direction for "date" is descending.
        else {
            this.state.resultItem.sort(function(a, b){
                /*set both values by parsing date_of_test into a number (milliseconds) to compare.
                    Example: 2/2/2020 -> parse('2/2/2020') -> 1580619600000 milliseconds
                            6/2/2020 -> parse('6/2/2020') -> 1591070400000 of milliseconds
                            parse('2/2/2020') - parse('6/2/2020') will yield a neg value
                            parse('6/2/2020') - parse('2/2/2020') will yield a pos value */
                var x = Date.parse(a.date_of_test);
                var y = Date.parse(b.date_of_test);

                return x - y;
            });
            this.setState({
                resultItem: this.state.resultItem,
                //set direction of sorting for all fields.
                direction: {
                    relevance: "ascend",
                    website: "ascend",
                    category: "ascend",
                    url: "ascend",
                    accessibility_grade: "ascend",
                    PWA_grade: "ascend",
                    date: "ascend",
                },
            //make sure to call condensedResults as this function will set our new sorted results in state before page re-renders.
            }, () => this.condensedResults());
        }
    }

    //sortByCategory sorts the results by the category using the "category" key in state.
    //changes category direction to descending and all other directions to ascending once the results are sorted.
    sortByCategory() {
        //check if sorting direction for "category" is ascending.
        if (this.state.direction["category"] === "ascend") {
            this.state.resultItem.sort( (a, b) => {
                return this.categorySortAscend(a,b);
            });
            this.setState({
                resultItem: this.state.resultItem,
                //set direction of sorting for all fields.
                direction: {
                    relevance: "ascend",
                    website: "ascend",
                    category: "descend",
                    url: "ascend",
                    accessibility_grade: "ascend",
                    PWA_grade: "ascend",
                    date: "ascend",
                },
                sort_by: "category"
            //make sure to call condensedResults as this function will set our new sorted results in state before page re-renders.
            }, () => this.condensedResults());
        }
        //then sorting direction for "category" is descending.
        else {
            //set resultItem in state to new sorted value.
            this.state.resultItem.sort( (a, b) => {
                return this.categorySortDescend(a,b);
            });
            this.setState({
                resultItem: this.state.resultItem,
                //set direction of sorting for all fields.
                direction: {
                    relevance: "ascend",
                    website: "ascend",
                    category: "ascend",
                    url: "ascend",
                    accessibility_grade: "ascend",
                    PWA_grade: "ascend",
                    date: "ascend",
                },
            //make sure to call condensedResults as this function will set our new sorted results in state before page re-renders.
            }, () => this.condensedResults());
        }
    }

    //sortByRelevance sorts the data in state by the "relevance" key.
    //changes relevance direction to descending and all other directions to ascending.
    sortByRelevance() {
        //set the results to the preset relevant results (stored in "relevantResults" in state).
        var results = [];
        for (const value of this.state.relevantResults) {
            results.push(value);
        }
        this.setState({
            resultItem: results,
            //set direction of sorting for all fields.
            direction: {
                relevance: this.state.direction["relevance"] === "ascend" ? "descend" : "ascend",
                website: "ascend",
                category: "ascend",
                url: "ascend",
                accessibility_grade: "ascend",
                PWA_grade: "ascend",
                date: "ascend",
            },
            sort_by: "relevance"
        //make sure to call condensedResults as this function will set our new sorted results in state before page re-renders.
        }, () => this.condensedResults());
    }

    //condensedResults takes in the new sorted results and sets them to the "paint" value in state before the page re-renders.
    //the "paint" value in state only holds the current version of results and never gets tweaked or changed in minor ways.
    condensedResults() {
        var results = [];
        for (const value of this.state.resultItem) {
            results.push(value);
        }
        this.setState({ paint: results });
    }

    //scoreToLetter takes in a number between 0 and 100 and returns a letter grade associated with that number.
    //we follow the standard number grade to letter grade conversion - 10 point letters down from 100.
    scoreToLetter(score) {
        //if given score is a 100, letter grade is an A+.
        if (score === 100) {
            return "A+";
        }
        //if given score is greater than or equal to a 90, letter grade is an A.
        else if (score >= 90) {
            return "A";
        }
        //if given score is greater than or equal to a 80, letter grade is an B.
        else if (score >= 80) {
            return "B";
        }
        //if given score is greater than or equal to a 70, letter grade is an C.
        else if (score >= 70) {
            return "C";
        }
        //if given score is greater than or equal to a 60, letter grade is an D.
        else if (score >= 60) {
            return "D";
        }
        //else given score is less than a 60, letter grade is an F.
        else {
            return "F";
        }
    }

    //checkIfRelevant checks if the current results are being sorted by relevance. If so, we don't need ascending
    //or descending (least relevant is not helpful). For any other ways of sorting, it may be helpful to have the option
    //of ascending or descending (ascending is the default) so we will then display a dropdown for selecting those options.
    checkIfRelevant() {
        //check if sorting by relevance.
        if (this.state.sort_by === "relevance") {
            return null;
        }
        //otherwise, display dropdown or "ascending" or "descending" (again, ascending is the default).
        else {
            return <div style={{ paddingRight: "8px" }} >
            <select name="sort_direction" id="sort_direction" value={this.state.sort_direction} onChange={this.sortDirectionHandler} alt="Sort By Direction">
                <option value="ascend">Ascending</option>
                <option value="descend">Descending</option>
            </select>
            </div>
        }
    }

    //displayResults shows the results by calling SearchResultTable when there are results to show (not 0).
    //otherwise, do nothing.
    displayResults() {
        //check if there are results to show.
        if (this.state.count !== 0) {
            //display results in SearchResultTable
            return <SearchResultTable
                        paint = {this.state.paint}
                        isAdvancedSearch = {this.state.isAdvancedSearch}
                        scoreToLetter = {this.scoreToLetter}
                    />
        }
        //otherwise, do nothing.
        return null;
    }

    //displaySortDropdown will display the dropdown for different sorting options only when there are results to sort.\
    //otherwise, do nothing.
    displaySortDropdown() {
        /*istanbul ignore next*/
        let sortBy;
        //set sortBy to be dynamically updated with given information.
         /*istanbul ignore next*/
        if (!this.state.sort_by) {
            sortBy = ""
        } else {
            sortBy = this.state.sort_by
        }
        //check if there are results to sort.
        if (this.state.count !== 0) {
            //display dropdown with sorting options.
            return <div className="result-row">
                        <label htmlFor="sort_by" style={{ paddingRight: "5px", paddingTop: "4px" }}>Sort By: </label>
                        <select name="sort_by" id="sort_by" value={sortBy} onChange={this.sortHandler} alt="Sort By Dropdown" tabIndex="0">
                            <option value="relevance">Relevance</option>
                            <option value="website">Website</option>
                            <option value="category">Category</option>
                            <option value="URL">URL</option>
                            <option value={this.state.accessibility_grade}>Accessibility Grade</option>
                            <option value="PWA grade">PWA Grade</option>
                            <option value={this.state.date_tested}>Date Tested</option>
                        </select>
                        {/* check if we are sorting by relevance, in which case, no "ascending" or "descending" option. */}
                        {this.checkIfRelevant()}
                    </div>
        }
        return null;
    }

    //render method for setting up the page and displaying the given information.
    //the use of components makes this easier and more readable (TopScroll, DownloadResults, and SearchResultTable).
    /* istanbul ignore next */
    render() {
        //grab items from local storage.
        const searchTerm = localStorage.getItem('searchTerm');
        const category = localStorage.getItem('category');
        const minGrade = localStorage.getItem('minGrade');
        const lastUpdated = localStorage.getItem('lastUpdated');
        const zip = localStorage.getItem('zip');
        const coordinates = localStorage.getItem('coordinates');
        let isAdvancedSearch = localStorage.getItem('isAdvancedSearch');

        //change string field to boolean for checking.
        if (isAdvancedSearch === "false" || isAdvancedSearch === 'false') {
            isAdvancedSearch = false;
        }
        else {
            isAdvancedSearch = true;
        }
        //create string constant to be used in display - setting a constant reduces string redundancy.
        const searchAgain = "Search Again";

        var additionalString = '';
        var from = "";
        var userLocation = '';

        //if user came from Advanced Search page.
        if (isAdvancedSearch) {
            //if user did not enter a category filter.
            if (category === "") {
                //set string to be dynamically updated with given information.
                additionalString = ` in all categories, with a minimum grade of "${minGrade}"
                    over the last "${lastUpdated}" days`;
            }
            //category filter was entered.
            else {
                //set string to be dynamically updated with given information.
                additionalString = ` in the "${category}" category, with a minimum grade of "${minGrade}"
                    over the last "${lastUpdated}" days`;
            }
            from = "Advanced";
            //if coordinates were entered.
            if (coordinates !== "") {
                //set string for user location to show below string.
                userLocation = ` closest to user's current location,`
            }
            //if zip code was entered.
            else if (zip !== "") {
                //set string to be dynamically updated with given information.
                userLocation = ` within zip code ${zip},`;
            }
        }

        //auto focus the user to specific place in page.
        const shouldAutoFocus = window.innerWidth<600?false:true;

        return  (
            <div>
                {/* display a loading spinner while results are being fetched, processed, and displayed. */}
                <div className="loader" id="loader" alt="Loading" ></div>
                <label className="loader_message" id="loader_message" ref={this.loadingRef} tabIndex="0" alt="Loading">Loading...</label>
                {/* start with results/information not showing - loading spinner will run during this time. */}
                <div className="centered-text" id="results" style={{display: "none"}}>
                    {/* TopScroll allows user to immediately scroll to the top of the page from anywhere. */}
                    <TopScroll scrollStepInPx="50" delayInMs="30"/>
                    {/* DownloadResults allows the user to download the current results into a CSV file. */}
                    <DownloadResults
                        resultItem={this.state.paint}
                        scoreToLetter={this.scoreToLetter}
                        from={from}
                    />
                    {/*This text  dynamically updates as more search terms are added.*/}
                    <h3 className="flex-width-paragraph" autoFocus={shouldAutoFocus} tabIndex="0" ref={this.focusRef} id="focus">
                        Showing results for "{searchTerm}"{additionalString},
                    {userLocation} sorting by {this.state.sort_by}, {this.state.count} results</h3>
                    <p tabIndex="0">Click on either grade to view reasons behind score</p>
                    {/* take user back to the search page they came from if they would like to make a new search. */}
                    {
                        (isAdvancedSearch)
                            ? <Link to="/Advanced Search" className="link" alt="Advanced Search Link" tabIndex="0">{searchAgain}</Link>
                            : <Link to="/Home" className="link" alt="Home Page Link" tabIndex="0">{searchAgain}</Link>
                    }
                    <br /><br />
                    {/* Here is the sorting dropdowns. One for which field to sort by and one for which direction. */}
                    <div className="search-row">
                        {this.displaySortDropdown()}
                    </div>
                    <br />
                    {/* call SearchResultTable to display the current results to the page.
                        use displayResults to check if there are results, if not, do not show table. */}
                    {this.displayResults()}
                    {/* this only displys if there are no results for given search query.
                        asks user if they would like to suggest their search term for scanning.
                    */}
                    {
                        (this.state.resultItem.length === 0)
                            ? <div className="centered-text">This query is not in the results database.
                                Would you like to <Link className="link" alt="Submit New Website Link" to={{
                                    pathname: "/Submit New Website",
                                    state: { company: searchTerm }
                                }} >suggest it for scanning</Link>?</div>
                            : null
                    }
                    <br /><br />
                </div>
            </div >
        );
    }
}

//zipToGeopoint takes in a zip code.
//makes a call to the ES database and receives geopoint coordinates that it then formats and returns.
export async function zipToGeopoint(zip){
    const data  = await getGeopoint(zip);
    const long = data.longitude;
    const lat = data.latitude;
    return `${lat} , ${long}`;
}

export default Results;
