import React from "react";
import "../styles.css";
import WCAGData from '../WCAGData.js';
import Refactor from "./Refactor";

class ReasonText extends Refactor {
  constructor(props) {
    super(props);
    this.state = {};
    this.getDescriptionURL = this.getDescriptionURL.bind(this);
    this.getWCAGURL = this.getWCAGURL.bind(this);
    this.getCriteriaName = this.getCriteriaName.bind(this);
    this.circleBorder = this.circleBorder.bind(this);
    this.scoreToLetter = this.scoreToLetter.bind(this);
    this.checkPerfectScoreWCAG = this.checkPerfectScoreWCAG.bind(this);
    this.checkPerfectScorePWA = this.checkPerfectScorePWA.bind(this);
    this.letterA = this.letterA.bind(this);
    this.sortHandler = this.sortHandler.bind(this);
    this.focusRef = React.createRef();
  }

  /*istanbul ignore next*/
  //due to TypeError, cannot test with Jest
  //Cannot read property 'map' of undefined
  async componentDidMount() {
    if (this.focusRef.current) {
      this.focusRef.current.focus();
      //Scrolls the user to the top on refresh. (Does not work on Chrome)
      window.scrollTo(0,0);
    }
  }

  //sortHandler updates when the user changes the "Sort by" dropdown value.
  sortHandler = (event) => {
    //if the user wants to view the results by title first.
    if (event.target.value === "title") {
      this.props.sortByTitle();
    }
    //if the user wants to view the results by criteria first.
    else if (event.target.value === "criteria") {
      this.props.sortByCriteria();
    }
    //if the user wants to view the results by level first.
    else if (event.target.value === "level") {
      this.props.sortByLevel();
    }
    //if the user wants to view the results by severity first.
    else {
      this.props.sortBySeverity();
    }
  }

  //getDescriptionURL takes in the given description and slices it, returning modified (shortened) description.
  getDescriptionURL(description) {
    const httpIndex = description.indexOf("http");
    const descLength = description.length;
    return description.slice(httpIndex, descLength - 2);
  }

  //getWCAGURL takes in number and description and returns the url for the "id".
  getWCAGURL(number, description) {
    let id = ''
    if (WCAGData[number]) {
      id = WCAGData[number].id_html;
      return `https://www.w3.org/TR/WCAG21/#${id}`;
    }
    return this.getDescriptionURL(description);
  }

  //getCriteriaName takes in a number and returns the name of the WCAG data at the index of that number.
  getCriteriaName(number) {
    if (WCAGData[number]) {
      return WCAGData[number].name;
    }
    return 'N/A';
  }

  //circleBorder creates a circle with a border defined by input.
  //the circle's border will encompassed based on the input number. Corollates to the conversion table (letter to number).
  circleBorder(input) {
    const num = Math.round(input * 100);
    //set possible border colors and background colors.
    const borderGreen = "10px solid #8ec249";
    const backgroundGreen = "#dcffac";
    const borderYellow = "10px solid #FFD400";
    const backgroundYellow = "#FFFFC0";
    const backgroundRed = "#FF7373";
    //if given number is greater than or equal to 90, means score is "A", circle will be encompassed 100% by green border.
    if (num >= 90) {
      return (
        <div id="circle" style={{
          borderRight: borderGreen,
          borderTop: borderGreen,
          borderLeft: borderGreen,
          borderBottom: borderGreen,
          background: backgroundGreen
        }}>{num}
        </div>
      )
    }
    //if given number is greater than or equal to 80, means score is "B", circle will be encompassed 75% by green border.6
    else if (num >= 80) {
      return (
        <div id="circle" style={{
          borderRight: borderGreen,
          borderTop: borderGreen,
          borderLeft: borderGreen,
          background: backgroundGreen
        }}>{num}
        </div>
      )
    }
    //if given number is greater than or equal to 70, means score is "C", circle will be encompassed 50% by yellow border.
    else if (num >= 70) {
      return (
        <div id="circle" style={{
          borderLeft: borderYellow,
          borderTop: borderYellow,
          background: backgroundYellow
        }}>{num}
        </div>
      )
    }
    //if given number is greater than or equal to 60, means score is "D", circle will be encompassed 25% by yellow border.
    else if (num >= 60) {
      return (
        <div id="circle" style={{
          borderLeft: borderYellow,
          background: backgroundYellow
        }}>{num}
        </div>
      )
    }
    //if given number is less than 60, means score is "F", circle will be filled with red and has no border.
    else {
      return (
        <div id="circle" style={{
          background: backgroundRed
        }}>{num}
        </div>
      )
    }
  }

  //scoreToLetter takes in a number between 0 and 100 and returns a letter grade associated with that number.
  //we follow the standard number grade to letter grade conversion - 10 point letters down from 100.
  scoreToLetter(score) {
    //if given score is a 100, letter grade is an A+.
    if (score === 100) {
      return "A+";
    }
    //if given score is greater than or equal to a 90, letter grade is an A.
    else if (score >= 90) {
      return "A";
    }
    //if given score is greater than or equal to a 80, letter grade is an B.
    else if (score >= 80) {
      return "B";
    }
    //if given score is greater than or equal to a 70, letter grade is an C.
    else if (score >= 70) {
      return "C";
    }
    //if given score is greater than or equal to a 60, letter grade is an D.
    else if (score >= 60) {
      return "D";
    }
    //else given score is less than a 60, letter grade is an F.
    else {
      return "F";
    }
  }

  //checkPerfectScoreWCAG will check if given website has a WCAG perfect score or not.
  //if not a WCAG perfect score, display information in a table listing which elements were missing from website.
  checkPerfectScoreWCAG() {
    //check if grade_number is 1, indicating WCAG perfect score.
    if (this.props.grade_number === "1") {
      return <div className="centered-text">
        <p>Perfect Score!</p>
        <p>{this.props.company} has passed all Accessibility audits.</p>
      </div>
    }
    //then not a WCAG perfect score thus display information as to why no perfect score.
    else {
      return <div className="flex-width-table">
        <div className="search-row" >
          {/* Here is the sorting dropdown. One for which field to sort by. */}
          <div className="result-row">
            <label htmlFor="sort_by" style={{ paddingRight: "5px", paddingTop: "4px" }}>Sort by: </label>
            <select className="list" name="sort_by" id="sort_by" value={this.props.sort_by} onChange={this.sortHandler} alt="Sort By Dropdown">
              <option value="title">Title</option>
              <option value="criteria">Criteria</option>
              <option value="level">Level</option>
              <option value="severity">Severity</option>
            </select>
          </div>
        </div>
        <table className="no-border">
          {/* set up column headers for each field. */}
          <thead>
            <tr>
              <th alt="Criteria Title Column">
                Elements Missing From Website
              </th>
              <th alt="WCAG Criteria Column">
                WCAG Success Criteria
              </th>
              <th alt="WCAG Level Column">
                WCAG Level
              </th>
              <th alt="WCAG Severity Column">
                Severity
              </th>
            </tr>
          </thead>
          {/* set up body entries for each field. */}
          <tbody>
            {this.props.data.map((row) => (
              <tr key={row.key}>
                <td className="left no-border" style={{ padding: "0px" }}>
                  {/* sends user to WCAG title page if they wish to see more information about this element. */}
                  <a
                    href={this.getDescriptionURL(row.description)}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <h3>{row.title}:</h3>
                    <p>{row.description.substring(0, row.description.indexOf("["))}</p>
                  </a>
                </td>
                <td className="left no-border">
                  {/* sends user to WCAG criteria page if they wish to see more information about this criteria. */}
                  <a
                    href={this.getWCAGURL(row.wcag_success_criteria, row.description)}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <p>
                      {row.wcag_success_criteria}
                    </p>
                  </a>
                </td>
                <td className="no-border">
                  <p>{row.wcag_level}</p>
                </td>
                <td className="no-border">
                  <p>{row.wcag_severity}</p>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    }
  }

  //checkPerfectScorePWA will check if given website has a PWA perfect score or not.
  //if not a PWA perfect score, display information in a table listing which elements were missing from website.
  checkPerfectScorePWA() {
    //check if grade_number is 1, indicating PWA perfect score.
    if (this.props.score === 1) {
      return <div className="centered-text">
        <p>Perfect Score!</p>
        <p>{this.props.company} has passed all PWA audits.</p>
      </div>
    }
    //then not a PWA perfect score thus display information as to why no perfect score.
    else {
      return <div className="flex-width-table">
        <table className="no-border">
          {/* set up column headers for this field. */}
          <thead>
            <tr>
              <th alt="Standard Title Column">
                Elements Missing From Website
              </th>
            </tr>
          </thead>
          {/* set up body entries for this field. */}
          <tbody>
            {this.props.results.map((row) => (
              <tr key={row.key}>
                <td className="left no-border" style={{ padding: "0px" }}>
                  {/* sends user to PWA title page if they wish to see more information about this element. */}
                  <a
                    href={this.getDescriptionURL(row.description)}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <h3>{row.title}:</h3>
                    <p>{row.description.substring(0, row.description.indexOf("["))}</p>
                  </a>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    }
  }

  //letterA sets a string to "an" or "a" depending on the letter grade of website for UI purposes.
  letterA(letter) {
    //if letter grade is "A".
    if (letter === "A") {
      //return "an".
      return "an";
    }
    //then letter grade is not "A".
    else {
      //return "a".
      return "a";
    }
  }

  //render method for setting up the page and displaying the given information.
  //displays a table for each criteria that the given website failed in for either WCAG or PWA.
  /*istanbul ignore next*/
  render() {
    const letter = this.props.grade_letter;
    return (
      <div>
        {/* check if we want to display WCAG information or PWA information. */}
        {
          (this.props.wcag)
            //display WCAG information.
            ? <div>
              {/* Leave a Review button will take user straight to Reasons Feedback form. */}
              <div className="printpage">
                <button alt="Click here if you want to leave a review" onClick={() => this.props.leaveReview()}>
                  Leave a Review
                </button>
              </div>
              <h2 className="centered-text">
                <a aria-label="company website link" autoFocus={true} ref={this.focusRef} href={`https://${this.props.url}`} target="_blank"
                  rel="noopener noreferrer" id="focus">{this.props.company}</a>
                  &nbsp;earned {this.letterA(letter)} {letter} based on Accessibility audits
                </h2>
              <p className="centered-text">{this.props.company} was last tested {this.props.date_of_test}</p>
              {/* call circleBorder to display grade circle based on the website's WCAG score. */}
              {this.circleBorder(Number(this.props.grade_number))}
              <br />
              {/* call checkPerfectScoreWCAG to display informaton as to why the given website received this WCAG score. */}
              {this.checkPerfectScoreWCAG()}
            </div>
            //display PWA information.
            : <div style={{ padding: "20px" }}>
              <h2 className="centered-text">
                <a aria-label="company website link" autoFocus={true} ref={this.focusRef} href={`https://${this.props.url}`} target="_blank"
                rel="noopener noreferrer">{this.props.company}</a>
                  &nbsp;earned {this.letterA(letter)} {this.scoreToLetter(this.props.score * 100)} based on PWA (Progressive Web App) features
                </h2>
              <p className="centered-text">{this.props.company} was last tested {this.props.date_of_test}</p>
              {/* call circleBorder to display grade circle based on the website's PWA score. */}
              {this.circleBorder(Number(this.props.score))}
              {/* call checkPerfectScorePWA to display informaton as to why the given website received this PWA score. */}
              {this.checkPerfectScorePWA()}
            </div>
        }
        {/* display information on how we obtain the scores and how websites are assigned grades. */}
        <div className="flex-width-paragraph">
          <p className="centered-text">
            We are using Google Lighthouse to perform audits for 508 Compliance, Accessibility, and PWA standards.
            Google Lighthouse scans the first page of the website, the landing page.
            The Lighthouse score is a weighted average of all the audits.<br /><br />
            Each audit is either pass or fail. No partial points are given.
            Since each audit has its own weight,
            the more heavily weighted audits have a bigger impact on this website's score.
          </p>
          <p className="centered-text">Websites are assigned a grade (from the Lighthouse score) based on the table below:</p>
          <table className="mobile-table">
            {(this.gradeTable())}
          </table>
        </div>
        <br />
      </div>
    );
  }
}

export default ReasonText;
