const axios = require("axios");

// here is the original proxy locahost call: let proxyEndpoint = 'http://127.0.0.1:5500';
const proxyEndpoint = 'https://508-portal-es.isenpailabs.com';

export {proxyEndpoint};

/**
 * Submits community feedback to the backend.
 *
 * @param {Object} feedback the feedback
 * @param {string} feedback.company the name of the company
 * @param {string} feedback.search the search query
 * @param {?string} feedback.email the email address of the submitter
 * @returns {Promise}
 */
export async function submitCommunityFeedback(value) {
  const data = {
    date_of_submission: new Date().toISOString(),
    feedback: value.feedback,
    target: "community_feedback",
  };

  axios.post(`${proxyEndpoint}/sendES`, { data: data })
    .then((res) => {
      // console.log(res.data);
    })
    .catch((err) => {
      console.log(`ERROR: ${err}`);
    });
}

/**
 * Submits community feedback to the backend.
 *
 * @param {Object} feedback the feedback
 * @param {string} feedback.company the name of the company
 * @param {string} feedback.search the search query
 * @param {?string} feedback.email the email address of the submitter
 * @returns {Promise}
 */
export async function submitAddsite(value) {
  const data = {
    date_of_submission: new Date().toISOString(),
    company: value.company,
    search: value.search,
    email: value.email,
    target: "requested_companies",
  };

  axios.post(`${proxyEndpoint}/sendES`, { data: data })
    .then((res) => {
      // console.log(res.data);
    })
    .catch((err) => {
      console.log(`ERROR: ${err}`);
    });
}
export async function submitReasonsFeedback(value) {
  const data = {
    date_of_submission: new Date().toISOString(),
    email: value.email,
    rating: value.rating,
    feedback: value.feedback,
    url: value.url,
    target: "test_ratings",
  };

  axios.post(`${proxyEndpoint}/sendES`, { data: data })
    .then((res) => {
      // console.log(res.data);
    })
    .catch((err) => {
      console.log(`ERROR: ${err}`);
    });
}

// Fetch user submitted response of a certain company's url
export async function starsfetch(value){
  const data = {
      url: value,
  };
  const response = await axios.post(`${proxyEndpoint}/starsFetch`, { data: data });
  return response.data;
}

export async function basicSearch(value, size, termIsCategory) {
  const data = {
    term: value,
    num: size,
    termIsCategory: termIsCategory,
  };

  const response = await axios.post(`${proxyEndpoint}/basicES`, {
    data: data,
  });
  return response.data;
}

export async function reasonFetch(value){
  const data = {
    result_id:value.id
  }
  const response = await axios.post(`${proxyEndpoint}/reasonFetch`, { data: data })
  return response.data
}

export async function advancedSearch(value) {
  const data = {
    term: value.searchTerm,
    keywords: value.keywords,
    lastUpdated: value.lastUpdated,
    grades: value.grades,
  };
  const response = await axios.post(`${proxyEndpoint}/advancedES`, {
    data: data,
  });
  return response.data;
}

export async function categorySearch(value) {
  const data = {
    term: value.searchTerm,
    category: value.category,
    keywords: value.keywords,
    lastUpdated: value.lastUpdated,
    grades: value.grades,
  };
  const response = await axios.post(`${proxyEndpoint}/categoryES`, {
    data: data,
  });
  return response.data;
}

//Takes in array of ES database IDs returned from foursquare api module.
export async function localAdvancedSearch(value) {
  const data = {
    idArray: value.idArray,
    lastUpdated: value.lastUpdated,
    grades: value.grades,
  };
  const response = await axios.post(`${proxyEndpoint}/localAdvancedES`, {
    data: data,
  });
  return response.data;
}

export async function localCategoryAdvancedSearch(value) {
  const data = {
    idArray: value.idArray,
    category: value.category,
    lastUpdated: value.lastUpdated,
    grades: value.grades,
  };
  const response = await axios.post(`${proxyEndpoint}/localCategoryAdvancedES`, {
    data: data,
  });
  return response.data;
}

/**
 * Community feedback validation results.
 * @typedef {Object} CommunityFeedbackValidation
 * @property {boolean} is_valid
 * @property {?Object} problems
 */

/**
 * Submits community feedback to the backend.
 *
 * @param {Object} feedback the feedback
 * @param {string} feedback.company the name of the company
 * @param {string} feedback.search the search query
 * @param {boolean} feedback.notARobot boolean indicating if captcha is complete
 * @param {?string} feedback.email the email address of the submitter
 *
 * @returns {CommunityFeedbackValidation}
 */
export function validateAddsite(feedback) {
  const problems = {};

  if (feedback.company === "") {
    problems["company"] = "Please enter a company name";
  }

  if (feedback.search === "") {
    problems["search"] = "Please enter a URL";
  }

  if (feedback.notARobot === false) {
    problems["captcha"] = "Captcha is incomplete";
  }

  return {
    is_valid: Object.keys(problems).length === 0,
    problems,
  };
}

/**
 * Submits community feedback to the backend.
 *
 * @param {Object} feedback the feedback
 * @param {string} feedback.company the name of the company
 * @param {string} feedback.search the search query
 * @param {boolean} feedback.notARobot boolean indicating if captcha is complete
 * @param {?string} feedback.email the email address of the submitter
 *
 * @returns {CommunityFeedbackValidation}
 */
export function validateCommunityFeedback(feedback) {
  const problems = {};

  if (feedback.feedback === "") {
    problems["feedback"] = "Please enter a message";
  }

  if (feedback.notARobot === false) {
    problems["captcha"] = "Captcha is incomplete";
  }

  return {
    is_valid: Object.keys(problems).length === 0,
    problems,
  };
}

//recieves zip and returns ES database geopoint information
export async function getGeopoint(value) {
  const data = {
    zip: value,
  };

  const response = await axios.post(`${proxyEndpoint}/zipFetch`, {
    data: data,
  });
  return response.data.body.hits.hits[0]._source;
}
