import React from "react";
import "../styles.css";

class Thanks extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  //render method for setting up the page and displaying the given information.
  //display header to thank user from submitting their feedback or new website.
  render() {
    return (
      <div>
        <div className="centered-text submission-message">
            <h1>Thanks for your feedback!</h1>
            <h3>Your input will help us improve the ACT and make the internet more accessible for everyone.</h3>
        </div>
      </div>
    );
  }
}

export default Thanks;
